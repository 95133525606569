import { processMappings } from "./Mapping";
import { invoiceMappings } from "./MappingDefinition";

const arraysToCsv = (data: [][]) => {
  return data
    .map(
      (row) =>
        row
          .map(String) // convert every value to String
          .map((v) => v.replace(/"{1}/gi, '""')) // escape double quotes
          .map((v) => `"${v}"`) // quote it
          .join(","), // comma-separated
    )
    .join("\r\n"); // rows starting on new lines
};

const downloadBlob = (
  content: string,
  filename: string,
  contentType: string,
) => {
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);
  const shadowLink = document.createElement("a");
  shadowLink.href = url;
  shadowLink.setAttribute("download", filename);
  shadowLink.click();
};

export function exportQuickbooksInvoiceCSV({ data }: { data: object }) {
  const mappedData = processMappings({ data, mappings: invoiceMappings });
  const mappedDataCSV = arraysToCsv(mappedData);
  return downloadBlob(mappedDataCSV, "export.csv", "text/csv;charset=utf-8;");
}
