import React from "react";
import PageOverview from "../components/PageOverview/PageOverview.tsx";
import Page from "../components/Page/Page.tsx";
import { Text } from "@chakra-ui/react";

function EulaPage(): React.ReactElement {
  return (
    <>
      <PageOverview heading={"Terms of Service"} />
      <Page isLoading={false} isError={false}>
        <Text>End User License Agreement</Text>
        <Text>
          This End User License Agreement ("Agreement") is a legal agreement
          between you (either an individual or a single entity, referred to
          herein as "User") and Stocky AI governing your use of the Stocky AI
          Platform ("Software"). By installing, copying, or otherwise using this
          Software, you agree to be bound by the terms of this Agreement.
          <br />
          <br />
          <strong>1. License Grant</strong>
          <br />
          Stocky AI grants the User a non-exclusive, non-transferable license to
          use the Software for personal or internal business purposes, subject
          to the terms and conditions of this Agreement.
          <br />
          <br />
          <strong>2. Restrictions</strong>
          <br />
          The User shall not:
          <ul>
            <li>
              • Modify, reverse engineer, decompile, or disassemble the
              Software.
            </li>
            <li>
              • Distribute, rent, lease, or sublicense the Software to any third
              party.
            </li>
            <li>
              • Use the Software in any unlawful manner or for any unlawful
              purpose.
            </li>
          </ul>
          <br />
          <strong>3. Ownership</strong>
          <br />
          All intellectual property rights in and to the Software remain the
          exclusive property of Stocky AI. This Agreement does not grant the
          User any ownership rights to the Software.
          <br />
          <br />
          <strong>4. Termination</strong>
          <br />
          This Agreement is effective until terminated by either party. Stocky
          AI may terminate this Agreement if the User fails to comply with its
          terms. Upon termination, the User must destroy all copies of the
          Software in their possession.
          <br />
          <br />
          <strong>5. Disclaimer of Warranty</strong>
          <br />
          The Software is provided "AS IS" without warranty of any kind, either
          express or implied, including but not limited to the implied
          warranties of merchantability, fitness for a particular purpose, and
          non-infringement.
          <br />
          <br />
          <strong>6. Limitation of Liability</strong>
          <br />
          In no event shall Stocky AI be liable for any damages (including,
          without limitation, incidental, consequential, or punitive damages)
          arising out of the use or inability to use the Software.
          <br />
          <br />
          <strong>7. Governing Law</strong>
          <br />
          This Agreement shall be governed by and construed in accordance with
          the laws of British Columbia, Canada.
          <br />
          <br />
          By using the Software, you acknowledge that you have read this
          Agreement, understand it, and agree to be bound by its terms.
        </Text>
      </Page>
    </>
  );
}

export default EulaPage;
