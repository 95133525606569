import {
  InvoiceItem,
  Invoice,
  QuickbooksCustomerMappingBase,
} from "../../types";
import React, { useCallback, useEffect, useState } from "react";
import InvoiceSummary from "./InvoiceSummary";
import {
  Heading,
  HStack,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerFooter,
  Button,
  Spacer,
  useToast,
  MenuButton,
  MenuList,
  MenuItem,
  Menu,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import {
  getAddressArray,
  getLocationAndOrgName,
} from "../../utils/invoiceUtils";
import {
  useGetCurrentUserQuery,
  usePostInvoicePdfEmailMutation,
  usePostQuickbooksCustomerMutation,
  usePostQuickbooksInvoiceMutation,
  usePutInvoiceMutation,
} from "../../redux/apiSlice";
import { userSlice } from "../../redux/userSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import * as Sentry from "@sentry/react";
import InvoicePdf from "../PdfPrinter/InvoicePdf";

import { usePDF } from "@react-pdf/renderer";
import { ChevronDownIcon } from "@chakra-ui/icons";
import EmailDetailsModal from "../../components/EmailDetailsModal/EmailDetailsModal";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";
import { exportQuickbooksInvoiceCSV } from "../InvoiceExporter/Export";
import { handleException } from "../../utils/errorUtils.ts";

interface InvoiceDrawerProps {
  invoice?: Invoice;
  isOpen: boolean;
  onClose: () => void;
  isSupplierView?: boolean;
}

export default function InvoiceDrawer({
  invoice,
  isOpen,
  onClose,
  isSupplierView = false,
}: InvoiceDrawerProps): React.ReactElement {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const {
    isOpen: isEmailModalOpen,
    onOpen: onEmailModalOpen,
    onClose: onEmailModalClose,
  } = useDisclosure();

  const klippersLogo = new URL(
    "../../assets/images/klippers.png",
    import.meta.url,
  ).href;
  const stockyCart = new URL(
    "../../assets/images/stockyCart.png",
    import.meta.url,
  ).href;

  const {
    getCurrentOrganisation,
    getCurrentLocation,
    getUserId,
    getConnectedToQuickbooks,
  } = userSlice.selectors;
  const userId = useAppSelector(getUserId);
  const currentOrganisation = useAppSelector(getCurrentOrganisation);
  const currentLocation = useAppSelector(getCurrentLocation);

  const connectedToQuickbooks = useAppSelector(getConnectedToQuickbooks);
  const setConnectedToQuickbooks = userSlice.actions.setConnectedToQuickbooks;

  const [supplierOrCustomerName, setSupplierOrCustomerName] = useState<string>(
    getLocationAndOrgName(
      isSupplierView ? invoice?.location : invoice?.supplier_location,
    ),
  );
  const [supplierOrgId, setSupplierOrgId] = useState<number>();
  const [supplierLocationId, setSupplierLocationId] = useState<number>();
  const [buyerOrgId, setBuyerOrgId] = useState<number>();
  const [buyerLocationId, setBuyerLocationId] = useState<number>();

  const [invoiceItems, setInvoiceItems] = useState<InvoiceItem[]>(
    invoice ? invoice.invoice_items : [],
  );
  const [invoiceTotal, setInvoiceTotal] = useState<number>(0);
  const [totalCredit, setTotalCredit] = useState<number>(0);
  const [deliveryDate, setDeliveryDate] = useState<string>(
    new Intl.DateTimeFormat("fr-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(Date.now()),
  );
  const [invoiceDate, setInvoiceDate] = useState<string>(
    new Intl.DateTimeFormat("fr-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(Date.now()),
  );
  const [buyerPO, setBuyerPO] = useState<string | undefined>(undefined);
  const [deliveryAddressId, setDeliveryAddressId] = useState<
    number | undefined
  >();
  const [deliveryNote, setDeliveryNote] = useState<string>("");

  const [isPickup, setIsPickup] = useState<boolean>(false);

  const { data: currentUser } = useGetCurrentUserQuery("");

  const [putInvoice, { isLoading: isInvoiceUpdating }] =
    usePutInvoiceMutation();

  const [postInvoiceEmail, { isLoading: isInvoiceEmailLoading }] =
    usePostInvoicePdfEmailMutation();

  const [postQuickbooksInvoice, { isLoading: isQuickbooksInvoicePostLoading }] =
    usePostQuickbooksInvoiceMutation();

  const [postQuickbooksCustomer, { isLoading: isPostingQuickbooksCustomer }] =
    usePostQuickbooksCustomerMutation();

  const canEditInvoice = !invoice?.id || invoice?.status === "PENDING";

  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  const {
    isOpen: isAddCustomerDialogOpen,
    onOpen: onAddCustomerDialogOpen,
    onClose: onAddCustomerDialogClose,
  } = useDisclosure();

  const handleSaveInvoice = async () => {
    if (!invoice?.id) {
      console.error(
        "Invoice drawer should only be accessible for existing invoices",
      );
      Sentry.captureException(
        "Invoice drawer should only be accessible for existing invoices",
      );
      toast({
        title: "Invoice error.",
        description:
          "Please try again later. Contact support if issues persists.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const invoicePayload: Invoice = {
      ...invoice,
      user_id: currentUser?.id || Number(userId),
      delivery_date: deliveryDate,
      invoice_items: invoiceItems,
      total: invoiceTotal,
      credit: totalCredit,
      invoice_date: deliveryDate, // TODO: implement invoice date?
      buyer_po_number: buyerPO,
      is_pickup: isPickup,
      delivery_address_id: deliveryAddressId,
      delivery_note: deliveryNote,
    };
    putInvoice(invoicePayload)
      .unwrap()
      .then(() => {
        toast({
          title: "Invoice updated.",
          description: "",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        try {
          Sentry.captureException(JSON.stringify(error));
        } catch {
          Sentry.captureException(error);
        }
        toast({
          title: "Invoice failed to update.",
          description: "Please try again later.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const handleSendQuickbooksInvoice = useCallback(() => {
    if (!invoice) {
      handleException("No Invoice found when sending to Quickbooks");
      return;
    }

    const loading_toast = toast({
      title: "Creating Quickbooks Invoice.",
      description: "",
      status: "loading",
      duration: 9000,
      isClosable: true,
    });

    postQuickbooksInvoice({ invoice })
      .unwrap()
      .then((response) => {
        const { Id: txnId } = response;
        const quickbooks_url =
          process.env.NODE_ENV !== "production"
            ? `https://sandbox.qbo.intuit.com/app/invoice?txnId=${txnId}`
            : `https://quickbooks.intuit.com/app/invoice?txnId=${txnId}`;
        // ? `https://sandbox.qbo.intuit.com/app/invoices`
        // : `https://quickbooks.intuit.com/app/invoices`;

        setTimeout(() => toast.close(loading_toast), 1000);

        toast({
          title: "Quickbooks Invoice sent.",
          description: "",
          status: "success",
          duration: 6000,
          isClosable: true,
        });

        toast({
          duration: 6000,
          render: ({ onClose }) => (
            <Button
              colorScheme="teal"
              onClick={() => {
                window.open(quickbooks_url);
                onClose();
              }}
            >
              View Invoice on Quickbooks
            </Button>
          ),
        });
      })

      .catch((error) => {
        setTimeout(() => toast.close(loading_toast), 1000);

        let error_title = "Quickbooks Invoice failed to send";
        let error_message = "Please contact support";

        const {
          data: { detail },
        } = error;

        if (detail.includes("Customer not found")) {
          toast({
            title: "Customer not found",
            description:
              "Please add the customer to Quickbooks before sending the invoice",
            status: "error",
            duration: 6000,
            isClosable: true,
          });
          onAddCustomerDialogOpen();
          return;
        }

        if (detail.includes("Authentication Error")) {
          error_title = "Authentication Error";
          error_message =
            "Please re-authenticate with Quickbooks from the Account Settings page";
          dispatch(setConnectedToQuickbooks(false));
        }
        if (detail.includes("Duplicate Document Number Error")) {
          error_title = "Duplicate Document Number";
          error_message = "Invoice Already Sent to Quickbooks";
        }

        handleException(error);
        toast({
          title: error_title,
          description: error_message,
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      });
  }, [invoice]);

  const handleAddQuickbooksCustomerAndSendInvoice = () => {
    if (!invoice) return;
    postQuickbooksCustomer({
      customer_location_id: invoice.location_id,
      customer_organisation_id: invoice.organisation_id,
      location_id: invoice.supplier_location_id,
      organisation_id: invoice.supplier_organisation_id,
    } as QuickbooksCustomerMappingBase)
      .unwrap()
      .then(() => {
        toast({
          title: "Quickbooks Customer added",
          description: "Creating invoice...",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        handleSendQuickbooksInvoice();
      })
      .catch((error) => {
        handleException(error);
        toast({
          title: "Quickbooks Customer failed to add",
          description: "Please try again later.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        onAddCustomerDialogClose();
      });
  };

  useEffect(() => {
    if (invoice) {
      setSupplierOrCustomerName(
        getLocationAndOrgName(
          isSupplierView ? invoice.location : invoice.supplier_location,
        ),
      );
      setSupplierOrgId(invoice.supplier_organisation_id);
      setSupplierLocationId(invoice.supplier_location_id);
      setBuyerOrgId(invoice.organisation_id);
      setBuyerLocationId(invoice.location_id);
      setInvoiceItems(invoice.invoice_items);
      setInvoiceTotal(Number(invoice.total));
      setTotalCredit(Number(invoice.credit));
      setDeliveryDate(invoice.delivery_date.slice(0, 10));
      setInvoiceDate(invoice.invoice_date.slice(0, 10));
      setBuyerPO(invoice.buyer_po_number);
      setDeliveryAddressId(invoice.delivery_address_id);
      setDeliveryNote(invoice.delivery_note ?? "");
      setIsPickup(invoice.is_pickup);
    }
  }, [invoice, isSupplierView]);

  const invoicePdf = invoice ? (
    <InvoicePdf
      logo={
        currentOrganisation.name.includes("Klippers")
          ? klippersLogo
          : stockyCart
      }
      title={currentOrganisation.name}
      customerName={
        invoice.organisation_id === invoice.supplier_organisation_id
          ? invoice.location?.name || ""
          : getLocationAndOrgName(invoice.location)
      }
      invoiceNo={invoice?.invoice_number || invoice?.id?.toString() || ""}
      address={getAddressArray(invoice.supplier_location?.address)}
      deliveryAddress={"\n".concat(
        getAddressArray(
          invoice.delivery_address ?? invoice.location?.address,
        ).join("\n"),
      )}
      invoiceDate={invoice.invoice_date}
      invoice={invoice}
      organisation={currentOrganisation}
      supplierPhone={currentLocation.phone}
      supplierEmail={currentLocation.email}
      buyerPONumber={invoice.buyer_po_number}
      isPickup={invoice.is_pickup}
      deliveryNote={invoice.delivery_note}
    />
  ) : undefined;

  const [instance, updateInstance] = usePDF({ document: invoicePdf });

  useEffect(() => {
    const newInvoicePdf = invoice ? (
      <InvoicePdf
        logo={
          currentOrganisation.name.includes("Klippers")
            ? klippersLogo
            : stockyCart
        }
        title={currentOrganisation.name}
        customerName={
          invoice.organisation_id === invoice.supplier_organisation_id
            ? invoice.location?.name || ""
            : getLocationAndOrgName(invoice.location)
        }
        invoiceNo={invoice?.invoice_number || invoice?.id?.toString() || ""}
        address={getAddressArray(invoice.supplier_location?.address)}
        deliveryAddress={"\n".concat(
          getAddressArray(
            invoice.delivery_address ?? invoice.location?.address,
          ).join("\n"),
        )}
        invoiceDate={invoice.invoice_date}
        invoice={invoice}
        organisation={currentOrganisation}
        supplierPhone={currentLocation.phone}
        supplierEmail={currentLocation.email}
        buyerPONumber={invoice.buyer_po_number}
        isPickup={invoice.is_pickup}
        deliveryNote={invoice.delivery_note}
      />
    ) : undefined;
    if (newInvoicePdf) {
      updateInstance(newInvoicePdf);
    }
  }, [invoice]);

  const handleEmailInvoice = async ({
    toEmails,
    ccEmails,
    bccEmails,
  }: {
    toEmails: string[];
    ccEmails: string[];
    bccEmails: string[];
  }) => {
    if (!invoice) {
      Sentry.captureException("Invoice not found");
      toast({
        title: "Email failed to send.",
        description: "Please try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      onEmailModalClose();
      return;
    }
    if (instance.blob) {
      const reader = new FileReader();
      reader.readAsDataURL(instance.blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        postInvoiceEmail({
          invoiceId: invoice.id,
          blob: base64data,
          toEmails,
          ccEmails,
          bccEmails,
        })
          .unwrap()
          .then(() => {
            toast({
              title: "Email sent.",
              description: "",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            onEmailModalClose();
          })
          .catch((error) => {
            try {
              Sentry.captureException(JSON.stringify(error));
            } catch {
              Sentry.captureException(error);
            }
            toast({
              title: "Email failed to send.",
              description: "Please try again later.",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          });
      };
    } else {
      Sentry.captureException("PDF blob not found");
    }
  };

  return (
    <Drawer
      size={isFullscreen ? "full" : "xl"}
      isOpen={isOpen}
      onClose={onClose}
    >
      {isEmailModalOpen && (
        <EmailDetailsModal
          isOpen={isEmailModalOpen}
          onClose={onEmailModalClose}
          sendEmail={handleEmailInvoice}
          isSending={isInvoiceEmailLoading}
          defaultToEmails={
            invoice?.location?.email ? [invoice.location.email] : []
          }
          defaultCcEmails={
            invoice?.supplier_location?.email
              ? [invoice.supplier_location.email]
              : []
          }
        />
      )}
      <DrawerOverlay />
      <DrawerContent p={4}>
        {isAddCustomerDialogOpen && (
          <AlertDialog
            isOpen={isAddCustomerDialogOpen}
            onClose={onAddCustomerDialogClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Customer not found in Quickbooks
                </AlertDialogHeader>

                <AlertDialogBody>
                  Do you want to create a new customer in Quickbooks?
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button onClick={onAddCustomerDialogClose}>Cancel</Button>
                  <Button
                    colorScheme="teal"
                    onClick={() => handleAddQuickbooksCustomerAndSendInvoice()}
                    ml={3}
                  >
                    Create Customer
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        )}
        <DrawerHeader>
          <HStack>
            <Heading>Invoice</Heading>
            <Spacer />
            <Button
              onClick={() => setIsFullscreen(!isFullscreen)}
              size="md"
              variant="outline"
              mr={4}
            >
              {isFullscreen ? (
                <AiOutlineFullscreenExit />
              ) : (
                <AiOutlineFullscreen />
              )}
            </Button>
            {invoice && isSupplierView && (
              <Menu>
                <MenuButton ml={2} as={Button} rightIcon={<ChevronDownIcon />}>
                  Actions
                </MenuButton>
                <MenuList textColor={"black"}>
                  <MenuItem onClick={(e) => e.stopPropagation()}>
                    <a
                      href={instance.url || undefined}
                      download={`invoice-${
                        invoice?.invoice_number ||
                        invoice?.id?.toString() ||
                        "print"
                      }.pdf`}
                    >
                      Download PDF
                    </a>
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      onEmailModalOpen();
                    }}
                  >
                    Email PDF
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      exportQuickbooksInvoiceCSV({ data: invoice });
                    }}
                  >
                    Export Quickbooks
                  </MenuItem>
                  <MenuItem
                    isDisabled={!connectedToQuickbooks}
                    onClick={handleSendQuickbooksInvoice}
                  >
                    Send via Quickbooks API
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </HStack>
        </DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody>
          {supplierOrgId &&
            supplierLocationId &&
            buyerOrgId &&
            buyerLocationId && (
              <InvoiceSummary
                supplierOrgId={supplierOrgId}
                supplierLocationId={supplierLocationId}
                buyerOrgId={buyerOrgId}
                buyerLocationId={buyerLocationId}
                invoiceItems={invoiceItems}
                invoiceDate={invoiceDate}
                deliveryDate={deliveryDate}
                invoiceTotal={invoiceTotal}
                totalCredit={totalCredit}
                buyerPONumber={buyerPO}
                deliveryAddressId={deliveryAddressId}
                isPickup={isPickup}
                deliveryNote={deliveryNote}
                setInvoiceItems={setInvoiceItems}
                setInvoiceDate={setInvoiceDate}
                setDeliveryDate={setDeliveryDate}
                setInvoiceTotal={setInvoiceTotal}
                setTotalCredit={setTotalCredit}
                setBuyerPONumber={setBuyerPO}
                setDeliveryAddressId={setDeliveryAddressId}
                setIsPickup={setIsPickup}
                setDeliveryNote={setDeliveryNote}
                isSupplierView={isSupplierView}
                supplierOrCustomerName={supplierOrCustomerName}
                isEditable={canEditInvoice}
              />
            )}
        </DrawerBody>
        <DrawerFooter>
          {canEditInvoice && (
            <Button
              colorScheme="teal"
              mr={3}
              onClick={handleSaveInvoice}
              isLoading={isInvoiceUpdating}
            >
              Save
            </Button>
          )}
          <Spacer />
          <Button colorScheme="red" variant={"ghost"} mr={3} onClick={onClose}>
            Close
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
