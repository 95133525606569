import * as Sentry from "@sentry/react";

export const handleException = (error: Error | string) => {
  if (typeof error === "string") {
    Sentry.captureMessage(error);
    return;
  }
  try {
    Sentry.captureException(JSON.stringify(error));
  } catch {
    Sentry.captureException(error);
  }
};
