import { Payment } from "../../types";
import { Td, useDisclosure } from "@chakra-ui/react";
import * as React from "react";
import InteractiveRow from "../../components/InteractiveRow/InteractiveRow";
import {
  formatCurrency,
  getLocationAndOrgName,
} from "../../utils/invoiceUtils";
import { paymentTypeLabels } from "../../utils/paymentUtils";
import AvatarEntry from "../../components/AvatarEntry/AvatarEntry";
import PaymentDetailDrawer from "./PaymentDetailDrawer.tsx";

interface PaymentViewEntryProps {
  index: number;
  payment: Payment;
}
export default function PaymentViewEntry({
  index,
  payment,
}: PaymentViewEntryProps): React.ReactElement {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const amountAssigned = payment.amount_assigned || 0;
  const amountRemaining = payment.amount - amountAssigned;

  return (
    <InteractiveRow key={index} onClick={onOpen}>
      {isOpen && (
        <PaymentDetailDrawer
          isOpen={isOpen}
          onClose={onClose}
          payment={payment}
        />
      )}
      <Td fontSize="sm">{payment.payment_date}</Td>
      <Td fontSize="sm">
        <AvatarEntry name={getLocationAndOrgName(payment.customer_location)} />
      </Td>
      <Td fontSize="sm">${formatCurrency(Number(payment.amount))}</Td>
      <Td fontSize="sm">${formatCurrency(Number(amountAssigned))}</Td>
      <Td fontSize="sm">${formatCurrency(Number(amountRemaining))}</Td>
      <Td fontSize="sm">{paymentTypeLabels[payment.payment_type]}</Td>
    </InteractiveRow>
  );
}
