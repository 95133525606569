import * as React from "react";
import {
  useDeleteCrmNoteMutation,
  useGetCrmNotesQuery,
  usePostCrmNoteMutation,
  usePutCrmNoteMutation,
} from "../../redux/apiSlice.ts";
import { userSlice } from "../../redux/userSlice.ts";
import { useAppSelector } from "../../redux/hooks.ts";
import { useEffect, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  Editable,
  EditablePreview,
  EditableTextarea,
  Flex,
  Heading,
  HStack,
  IconButton,
  Skeleton,
  Spacer,
  useDisclosure,
  useEditableControls,
  useToast,
} from "@chakra-ui/react";
import { CheckIcon, CloseIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { CrmNote } from "../../types.ts";
import { handleException } from "../../utils/errorUtils.ts";

interface CrmNotesInterface {
  customerOrganisationId: number;
  customerLocationId: number;
}

export default function CrmNotes({
  customerOrganisationId,
  customerLocationId,
}: CrmNotesInterface): React.ReactElement {
  const toast = useToast();
  const { getCurrentOrganisationId, getCurrentLocationId } =
    userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const locationId = useAppSelector(getCurrentLocationId);

  const {
    isOpen: isDeleteDialogOpen,
    onOpen: onDeleteDialogOpen,
    onClose: onDeleteDialogClose,
  } = useDisclosure();

  const {
    data: crmNotes,
    error: crmNotesError,
    isLoading: crmNotesLoading,
  } = useGetCrmNotesQuery(
    {
      organisationId: organisationId ?? 0,
      locationId: locationId ?? 0,
      customerLocationId: customerLocationId,
    },
    {
      skip: !organisationId || !locationId,
    },
  );

  const [postCrmNote, { isLoading: postingNote }] = usePostCrmNoteMutation();
  const [putCrmNote, { isLoading: puttingNote }] = usePutCrmNoteMutation();
  const [deleteCrmNote, { isLoading: deletingNote }] =
    useDeleteCrmNoteMutation();

  const [crmNoteData, setCrmNoteData] = useState<CrmNote[]>([]);

  const [isAddingNote, setIsAddingNote] = useState<boolean>(false);
  const [newNote, setNewNote] = useState<string>("");

  const [isEditingNote, setIsEditingNote] = useState<boolean>(false);
  const [noteToDelete, setNoteToDelete] = useState<CrmNote>();

  useEffect(() => {
    setCrmNoteData(crmNotes ?? []);
  }, [crmNotes, crmNotesLoading, postingNote, puttingNote, deletingNote]);

  const handleSaveNote = () => {
    postCrmNote({
      owner_organisation_id: organisationId ?? 0,
      owner_location_id: locationId ? Number(locationId) : 0,
      organisation_id: customerOrganisationId,
      location_id: customerLocationId,
      note: newNote,
    })
      .unwrap()
      .then((r) => {
        setIsAddingNote(false);
        setCrmNoteData([r, ...crmNoteData]);
        setNewNote("");
        toast({
          title: "Note added successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        handleException(error);
        toast({
          title: "Failed to add note",
          description: "Please try again later",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const handleUpdateNote = (index: number) => {
    const crmNote = crmNoteData[index];
    putCrmNote(crmNote)
      .unwrap()
      .then((response) => {
        setIsEditingNote(false);
        setCrmNoteData(
          crmNoteData.map((note) =>
            note.id === crmNote.id ? { ...crmNote, note: response.note } : note,
          ),
        );
        toast({
          title: "Note updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        handleException(error);
        toast({
          title: "Failed to update note",
          description: "Please try again later",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const handleDeleteNote = (crmNote?: CrmNote) => {
    if (!crmNote) {
      return;
    }
    deleteCrmNote(crmNote)
      .unwrap()
      .then(() => {
        setCrmNoteData(crmNoteData.filter((note) => note.id !== crmNote.id));
        onDeleteDialogClose();
        toast({
          title: "Note deleted successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        handleException(error);
        toast({
          title: "Failed to delete note",
          description: "Please try again later",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  function EditableControls({
    isAdding = false,
    onSave = () => {},
    onCancel = () => {},
    onDelete = () => {},
  }) {
    const [showEdit, setShowEdit] = useState<boolean>(isAdding);

    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    useEffect(() => {
      if (isAdding) {
        setShowEdit(true);
        return;
      }
      setShowEdit(isEditing);
    }, [isEditing, isAdding]);

    return showEdit ? (
      <ButtonGroup justifyContent="center" size="sm">
        <IconButton
          icon={<CheckIcon />}
          {...getSubmitButtonProps()}
          onClick={(e) => {
            onSave();
            const submitProps = getSubmitButtonProps();
            if (submitProps && submitProps.onClick) {
              submitProps.onClick(e);
            }
          }}
        />
        <IconButton
          icon={<CloseIcon />}
          {...getCancelButtonProps()}
          onClick={() => onCancel()}
        />
      </ButtonGroup>
    ) : (
      <Flex justifyContent="center">
        <IconButton size="sm" icon={<EditIcon />} {...getEditButtonProps()} />
        <IconButton
          size="sm"
          icon={<DeleteIcon />}
          colorScheme={"red"}
          variant={"ghost"}
          onClick={() => onDelete()}
        />
      </Flex>
    );
  }

  return (
    <>
      <Heading size="md">Notes</Heading>
      {isDeleteDialogOpen && (
        <AlertDialog isOpen={isDeleteDialogOpen} onClose={onDeleteDialogClose}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Note
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button onClick={onDeleteDialogClose}>Cancel</Button>
                <Button
                  colorScheme="red"
                  onClick={() => handleDeleteNote(noteToDelete)}
                  ml={3}
                >
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
      {!isAddingNote ? (
        <Button colorScheme={"teal"} onClick={() => setIsAddingNote(true)}>
          Add Note
        </Button>
      ) : (
        <>
          <Editable
            p={4}
            textAlign="left"
            bg="#f7f7f7"
            rounded="md"
            display={"flex"}
            flexDirection={"column"}
          >
            <EditableTextarea
              h={"200px"}
              onChange={(e) => setNewNote(e.target.value)}
            />
            <EditablePreview />
            <EditableControls
              isAdding={true}
              onSave={() => handleSaveNote()}
              onCancel={() => setIsAddingNote(false)}
            />
          </Editable>
        </>
      )}
      {crmNotesLoading || postingNote || puttingNote || deletingNote ? (
        <>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </>
      ) : (
        <>
          {!crmNotesLoading &&
            crmNoteData &&
            crmNoteData.map((crmNote, index) => (
              <Editable textAlign="center" defaultValue={crmNote.note}>
                <EditablePreview />
                <EditableTextarea
                  h={"200px"}
                  onChange={(e) =>
                    setCrmNoteData(
                      crmNoteData.map((note, i) =>
                        i === index ? { ...note, note: e.target.value } : note,
                      ),
                    )
                  }
                />
                <EditableControls
                  onSave={() => handleUpdateNote(index)}
                  onDelete={() => {
                    setNoteToDelete(crmNote);
                    onDeleteDialogOpen();
                  }}
                />
              </Editable>
            ))}
        </>
      )}
    </>
  );
}
