import {
  Badge,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { format } from "date-fns";
import { useMemo, useState } from "react";
import AgingChart from "../components/AgingChart/AgingChart";
import DataTable from "../components/DataTable/DataTable";
import { AccountInfo } from "../feature/AccountsView/types";
import AddPaymentModal from "../feature/PaymentsView/AddPaymentModal";
import {
  useGetAgingAccountsReceivablesQuery,
  useGetCustomerTransactionsQuery,
  useGetDeliveryAddressesQuery,
  useGetLocationsQuery,
} from "../redux/apiSlice";
import { useAppSelector } from "../redux/hooks";
import { userSlice } from "../redux/userSlice";
import { Invoice, Payment } from "../types";
import {
  getInvoiceStatusColor,
  getInvoiceStatusLabel,
  getPaymentStatusColor,
} from "../utils/invoiceUtils";
import PaymentDetailDrawer from "../feature/PaymentsView/PaymentDetailDrawer.tsx";
import InvoiceDrawer from "../feature/OrdersView/InvoiceDrawer.tsx";
import * as React from "react";
import CrmNotes from "../feature/CrmNotes/CrmNotes.tsx";

interface AccountsDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  accountInfo: AccountInfo | null;
}

export default function AccountsDrawer({
  isOpen,
  onClose,
  accountInfo,
}: AccountsDrawerProps): React.ReactElement | null {
  const [ageBucket, setAgeBucket] = useState<string | null>(null);

  const {
    isOpen: isPaymentModalOpen,
    onOpen: onPaymentModalOpen,
    onClose: onPaymentModalClose,
  } = useDisclosure();

  const { getCurrentOrganisationId, getCurrentLocationId } =
    userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const locationId = useAppSelector(getCurrentLocationId);

  const { data: deliveryAddresses } = useGetDeliveryAddressesQuery(
    { organisationId, locationId },
    { skip: !accountInfo },
  );

  const {
    data: transactions,
    error: transactionsError,
    isLoading: transactionsLoading,
  } = useGetCustomerTransactionsQuery(
    {
      organisationId: organisationId ?? 0,
      locationId: locationId ?? 0,
      buyerOrgId: accountInfo?.organisationId ?? 0,
      buyerLocationId: accountInfo?.locationId ?? 0,
      ageBucket,
      // startDate,
      // endDate,
    },
    { skip: !organisationId || !locationId },
  );

  const {
    data: agingInvoices,
    error: agingInvoicesError,
    isLoading: agingInvoicesLoading,
  } = useGetAgingAccountsReceivablesQuery(
    {
      organisationId: organisationId ?? 0,
      locationId: locationId ?? 0,
      buyerOrgId: accountInfo?.organisationId ?? 0,
      buyerLocationId: accountInfo?.locationId ?? 0,
    },
    { skip: !organisationId || !locationId },
  );

  const getInvoiceOrPaymentBadge = (entry: Invoice | Payment) => {
    const isInvoice = entry.model_name === "Invoice";
    const transaction = isInvoice ? (entry as Invoice) : (entry as Payment);
    const color = isInvoice ? "green" : "blue";
    let description = "";
    if (isInvoice) {
      description =
        transaction.organisation_id !== organisationId ? "Sales" : "Purchase";
      description = `${description} Invoice`;
    } else {
      description = "Payment";
      description = `${description} ${
        transaction.organisation_id === organisationId ? "Received" : "Made"
      }`;
    }
    return <Badge colorScheme={color}>{description}</Badge>;
  };

  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | undefined>(
    undefined,
  );
  const {
    isOpen: isInvoiceDetailsDrawerOpen,
    onOpen: onInvoiceDetailsDrawerOpen,
    onClose: onInvoiceDetailsDrawerClose,
  } = useDisclosure();

  const [selectedPayment, setSelectedPayment] = useState<Payment | null>(null);
  const {
    isOpen: isPaymentDetailsDrawerOpen,
    onOpen: onPaymentDetailsDrawerOpen,
    onClose: onPaymentDetailsDrawerClose,
  } = useDisclosure();

  const [contactName, setContactName] = useState(
    accountInfo?.contactName || "",
  );
  const columnHelper = createColumnHelper<Invoice | Payment>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("invoice_date", {
        header: "Date",
        cell: ({ row }) => {
          return (
            <Text>
              {format(
                new Date(
                  row.original.invoice_date ?? row.original.payment_date,
                ),
                "yyyy-MM-dd",
              )}
            </Text>
          );
        },
      }),
      columnHelper.accessor("id", {
        header: "ID",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("model_name", {
        header: "Type",
        cell: (info) => {
          return getInvoiceOrPaymentBadge(info.row.original);
        },
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: ({ row }) => {
          return (
            <>
              {row.original.model_name === "Invoice" ? (
                <Badge
                  colorScheme={getInvoiceStatusColor(row.original.status, true)}
                >
                  {getInvoiceStatusLabel(row.original.status, true)}
                </Badge>
              ) : (
                <Badge colorScheme={getPaymentStatusColor(row.original.status)}>
                  {row.original.status}
                </Badge>
              )}
            </>
          );
        },
      }),
      columnHelper.accessor("amount", {
        header: "Amount",
        cell: ({ row }) => (
          <span>${row.original.amount ?? row.original.total}</span>
        ),
      }),
    ],
    [columnHelper],
  );

  const { data: locations } = useGetLocationsQuery("");

  const currentLocation = locations?.find(
    (loc) => loc.id === accountInfo?.locationId,
  );

  if (!accountInfo) {
    return null;
  }

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          {isPaymentDetailsDrawerOpen && selectedPayment && (
            <PaymentDetailDrawer
              isOpen={isPaymentDetailsDrawerOpen}
              onClose={onPaymentDetailsDrawerClose}
              payment={selectedPayment}
            />
          )}
          {isInvoiceDetailsDrawerOpen && selectedInvoice && (
            <InvoiceDrawer
              isOpen={isInvoiceDetailsDrawerOpen}
              onClose={onInvoiceDetailsDrawerClose}
              invoice={selectedInvoice}
              isSupplierView={true}
            />
          )}
          <DrawerCloseButton />
          <DrawerHeader>{accountInfo.name}</DrawerHeader>
          <DrawerBody>
            <Tabs>
              <TabList>
                <Tab>Transactions</Tab>
                <Tab>Account Info</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <VStack align="stretch" spacing={4}>
                    {agingInvoices && agingInvoices.length > 0 && (
                      <Box alignSelf="flex-end">
                        <AgingChart
                          agingInvoices={agingInvoices || []}
                          size="small"
                          onClick={(accountAge) => {
                            if (accountAge === ageBucket) {
                              setAgeBucket(null);
                              return;
                            }
                            setAgeBucket(accountAge);
                          }}
                          selectedBucket={ageBucket}
                        />
                      </Box>
                    )}
                    {/*<Button colorScheme="teal" onClick={onPaymentModalOpen}>*/}
                    {/*  Record Payment*/}
                    {/*</Button>*/}
                    <DataTable
                      interactiveRows={true}
                      data={transactions || []}
                      columns={columns as ColumnDef<Invoice | Payment>[]}
                      isLoading={transactionsLoading}
                      onRowClick={(row) => {
                        if (row.original.model_name === "Invoice") {
                          setSelectedInvoice(row.original as Invoice);
                          onInvoiceDetailsDrawerOpen();
                        } else {
                          setSelectedPayment(row.original as Payment);
                          onPaymentDetailsDrawerOpen();
                        }
                      }}
                    />
                  </VStack>
                </TabPanel>
                <TabPanel>
                  <VStack align="stretch" spacing={4}>
                    <Heading size="md">Account Details</Heading>
                    {/*<HStack>*/}
                    {/*  <Text fontWeight="bold">Contact Name:</Text>*/}
                    {/*  <Input*/}
                    {/*    value={contactName}*/}
                    {/*    onChange={(e) => setContactName(e.target.value)}*/}
                    {/*    placeholder="Enter contact name"*/}
                    {/*  />*/}
                    {/*</HStack>*/}
                    <HStack>
                      <Text fontWeight="bold">Phone:</Text>
                      <Text>
                        {currentLocation?.phone || accountInfo.phone || "N/A"}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontWeight="bold">Email:</Text>
                      <Text>
                        {currentLocation?.email || accountInfo.email || "N/A"}
                      </Text>
                    </HStack>
                    {/*<HStack>*/}
                    {/*  <Text fontWeight="bold">Location:</Text>*/}
                    {/*  <Text>*/}
                    {/*    {locations?.find(*/}
                    {/*      (loc) => loc.id === accountInfo.locationId,*/}
                    {/*    )*/}
                    {/*      ? getLocationAndOrgName(*/}
                    {/*          locations.find(*/}
                    {/*            (loc) => loc.id === accountInfo.locationId,*/}
                    {/*          )!,*/}
                    {/*        )*/}
                    {/*      : "N/A"}*/}
                    {/*  </Text>*/}
                    {/*</HStack>*/}
                    {/*<VStack align="stretch">*/}
                    {/*  <Text fontWeight="bold">Primary Address:</Text>*/}
                    {/*  <Text>{accountInfo.address?.street_1}</Text>*/}
                    {/*  {accountInfo.address?.street_2 && (*/}
                    {/*    <Text>{accountInfo.address.street_2}</Text>*/}
                    {/*  )}*/}
                    {/*  <Text>*/}
                    {/*    {accountInfo.address?.city} {accountInfo.address?.state}{" "}*/}
                    {/*    {accountInfo.address?.postal_code}*/}
                    {/*  </Text>*/}
                    {/*  <Text>{accountInfo.address?.country}</Text>*/}
                    {/*</VStack>*/}
                    {/*{deliveryAddresses && deliveryAddresses.length > 1 && (*/}
                    {/*  <VStack align="stretch">*/}
                    {/*    <Heading size="sm">*/}
                    {/*      Additional Delivery Addresses*/}
                    {/*    </Heading>*/}
                    {/*    {deliveryAddresses.map((address, index) => (*/}
                    {/*      <VStack key={index} align="stretch">*/}
                    {/*        <Text>{address.address.street_1}</Text>*/}
                    {/*        {address.address.street_2 && (*/}
                    {/*          <Text>{address.address.street_2}</Text>*/}
                    {/*        )}*/}
                    {/*        <Text>*/}
                    {/*          {address.address.city}, {address.address.state}{" "}*/}
                    {/*          {address.address.postal_code}*/}
                    {/*        </Text>*/}
                    {/*        <Text>{address.address.country}</Text>*/}
                    {/*      </VStack>*/}
                    {/*    ))}*/}
                    {/*  </VStack>*/}
                    {/*)}*/}
                    <CrmNotes
                      customerOrganisationId={accountInfo.organisationId ?? 0}
                      customerLocationId={accountInfo.locationId ?? 0}
                    />
                  </VStack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <AddPaymentModal
        isOpen={isPaymentModalOpen}
        onClose={onPaymentModalClose}
      />
    </>
  );
}
