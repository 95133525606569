import { format } from "date-fns";
import { CalendarDate } from "../components/Calendar";

export const formatISODate = (date: Date | CalendarDate): string => {
  return format(date, "yyyy-MM-dd");
};

export const getLocalDate = (dateString: any): Date => {
  const [year, month, day] = dateString.split("-").map(Number);
  // Note: JavaScript counts months from 0 to 11. January is 0, February is 1, and so on.
  return new Date(year, month - 1, day);
};

export const monthDiff = (d1: Date, d2: Date): number => {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};

export const formatDateTimeString = (datetime: string) => {
  // format datetime string to be more readable, from 2024-11-24T17:18:45.975000 to 2024-11-24 17:18
  return datetime.replace("T", " ").split(".")[0].slice(0, -3);
};

export const formatDateTimeStringToLocal = (datetime: string) => {
  // format datetime string to be more readable, from 2024-11-24T17:18:45.975000 to 2024-11-24 17:18
  // also adjust to local time
  const localTime = new Date(datetime);
  const offset = localTime.getTimezoneOffset();
  localTime.setMinutes(localTime.getMinutes() - offset);
  return format(localTime, "yyyy-MM-dd HH:mm");
};

export const addTimezoneOffset = (datetime: string) => {
  const localTime = new Date(datetime);
  const offset = localTime.getTimezoneOffset();
  localTime.setMinutes(localTime.getMinutes() + offset);
  return localTime.toISOString();
};

export const subtractTimezoneOffset = (datetime: string) => {
  const localTime = new Date(datetime);
  const offset = localTime.getTimezoneOffset();
  localTime.setMinutes(localTime.getMinutes() - offset);
  return localTime.toISOString();
};

export function calculateDaysSince(dateString: string): number {
  const date = new Date(dateString);
  const now = new Date();
  const diffTime = Math.abs(now.getTime() - date.getTime());
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

export const getTimeDeltaInMinutes = (date: string) => {
  const now = new Date();
  const then = new Date(subtractTimezoneOffset(date));

  const diffMilliseconds = now.getTime() - then.getTime();

  return Math.round(diffMilliseconds / (1000 * 60));
};

export const getTimeDeltaInWords = (date: string) => {
  const minutes = getTimeDeltaInMinutes(date);
  if (minutes < 60) {
    return `${minutes} min`;
  }
  const hours = Math.floor(minutes / 60);
  if (hours < 24) {
    return `${hours} h`;
  }
  const days = Math.floor(hours / 24);
  return `${days} d`;
};
