import { Product, ProductUnit, ProductUnitBase } from "../types";

export const badgeColors = [
  "blue",
  "green",
  "orange",
  "purple",
  "teal",
  "pink",
  "red",
  "cyan",
];

export const formatProductQuantity = (quantity: number | string) => {
  quantity = Number(quantity);
  if (quantity % 1 === 0) {
    return quantity;
  }
  const response = quantity.toFixed(2);
  if (response.slice(-1) === "0") {
    return response.slice(0, -1);
  }
  return response;
};

export const productUnitLabel = (
  productUnit: ProductUnit | ProductUnitBase,
  measureOverwrite?: string,
  isSubUnit: boolean = false,
) => {
  if (isSubUnit) {
    return `${formatProductQuantity(productUnit.unit_quantity)}${
      measureOverwrite || getUnitMeasureLabel(productUnit.unit_measure)
    }${productUnit.unit_label ? ` ${productUnit.unit_label}` : ""}`;
  }
  const unitInfo = `${
    productUnit.sub_unit_quantity
      ? formatProductQuantity(productUnit.sub_unit_quantity)
      : formatProductQuantity(productUnit.unit_quantity)
  }${measureOverwrite || getUnitMeasureLabel(productUnit.unit_measure)}`;
  return productUnit.unit_label && productUnit.unit_label.length > 0
    ? `${productUnit.unit_label}${
        unitInfo === "1unit" ? "" : " (" + unitInfo + ")"
      }`
    : unitInfo;
};

export const getUnitMeasureLabel = (unitMeasure: string) => {
  return unitMeasure === "unit" ? "ea" : unitMeasure;
};

export const getProductUnitLabel = (
  productUnit?: ProductUnit | ProductUnitBase,
) => {
  if (!productUnit) {
    return "";
  }
  const unitLabel = productUnit.unit_label || "";
  // quick fix for Klippers - bunches should be each, showing weight info could lead to complaints
  if (unitLabel.toLowerCase().includes("bunch")) {
    return "bunch";
  }
  const measure = productUnit.sub_unit
    ? ` x ${productUnitLabel(productUnit.sub_unit, undefined, true)}`
    : getUnitMeasureLabel(productUnit.unit_measure);
  return productUnitLabel(productUnit, measure);
};

export const findMatchingProductUnit = (
  product: Product,
  unitOfMeasure: string,
  unitQuantity: number,
  unitLabel?: string,
  subUnit?: ProductUnit | ProductUnitBase | null,
) => {
  // Search through product.product_units for an existing match
  const matchingProductUnits = product.product_units.filter((productUnit) => {
    if (
      (!subUnit &&
        productUnit.unit_measure === unitOfMeasure &&
        (unitLabel ? productUnit.unit_label === unitLabel : true) &&
        Number(productUnit.unit_quantity) === Number(unitQuantity)) ||
      (subUnit &&
        productUnit.unit_measure === "unit" &&
        Number(productUnit.unit_quantity) ===
          Number(subUnit?.quantity_in_parent))
    ) {
      if (subUnit) {
        if (subUnit.id) {
          return productUnit.sub_unit_id === subUnit.id;
        } else {
          return (
            productUnit.sub_unit &&
            productUnit.sub_unit.unit_measure === subUnit.unit_measure &&
            productUnit.sub_unit.unit_quantity === subUnit.unit_quantity
            // && productUnit.sub_unit.unit_label === subUnit.unit_label
          );
        }
      }
      return true;
    }
    return false;
  });
  if (matchingProductUnits.length > 0) {
    // match to product unit with closest label if provided, else first entry
    if (unitLabel && unitLabel.length > 0 && matchingProductUnits.length > 1) {
      const exactMatch = matchingProductUnits.find(
        (productUnit) =>
          productUnit.unit_label &&
          productUnit.unit_label.toLowerCase() === unitLabel.toLowerCase(),
      );
      if (exactMatch) {
        return exactMatch;
      }
      const labelIncludesCurrent = matchingProductUnits.find(
        (productUnit) =>
          productUnit.unit_label &&
          productUnit.unit_label
            .toLowerCase()
            .includes(unitLabel.toLowerCase()),
      );
      if (labelIncludesCurrent) {
        return labelIncludesCurrent;
      }
      const currentIncludesLabel = matchingProductUnits.find(
        (productUnit) =>
          productUnit.unit_label &&
          unitLabel
            .toLowerCase()
            .includes(productUnit.unit_label.toLowerCase()),
      );
      if (currentIncludesLabel) {
        return currentIncludesLabel;
      }
    }
    return matchingProductUnits[0];
  }
  return undefined;
};

export const getProductUnitKeyForNewOrExisting = (
  productUnit: ProductUnit | ProductUnitBase,
) => {
  return (
    // @ts-expect-error id not present on ProductBaseUnit
    productUnit.id ||
    `${productUnit.product_id}-${productUnit.unit_label}-${
      productUnit.unit_measure
    }-${productUnit.unit_quantity}-${
      productUnit.sub_unit
        ? getProductUnitKeyForNewOrExisting(productUnit.sub_unit)
        : "no-sub-unit"
    }`
  );
};
