import React, { useEffect, useState } from "react";
import PageOverview from "../components/PageOverview/PageOverview";
import { useAppSelector } from "../redux/hooks";
import { userSlice } from "../redux/userSlice";
import { useGetInventoryProductsQuery } from "../redux/apiSlice";
import { Badge, HStack, Td, useDisclosure } from "@chakra-ui/react";
import DataTabs from "../components/DataTabs/DataTabs";
import { Product } from "../types";
import { badgeColors, getProductUnitLabel } from "../utils/productUnitUtils";
import ProductViewEntry from "../feature/ProductView/ProductViewEntry";
import AddProductModal from "../feature/ProductView/AddProductModal";
import InventoryProductSearch from "../components/InventoryProductSearch/InventoryProductSearch";
import Page from "../components/Page/Page";
import { skipToken } from "@reduxjs/toolkit/query";
import * as Sentry from "@sentry/react";
import {
  getProductCategoryLabel,
  productCategoryBadgeColors,
} from "../utils/productUtils.ts";

function ProductsPage(): React.ReactElement {
  const { getCurrentOrganisationId } = userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);

  const [productList, setProductList] = useState<Product[]>([]);
  const [orgCategories, setOrgCategories] = useState<Set<string> | null>(null);
  const [productFilterId, setProductFilterId] = useState<
    number | null | undefined
  >(undefined);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    data: inventoryProducts,
    isLoading: productsLoading,
    error,
  } = useGetInventoryProductsQuery(organisationId ?? skipToken);

  useEffect(() => {
    if (inventoryProducts) {
      const categories = new Set<string>();
      let filteredProducts = inventoryProducts.slice().sort((a, b) => {
        // sort by org_category (if available) then by product name
        if (!a.org_category && b.org_category) {
          categories.add(b.org_category);
          return -1;
        }
        if (a.org_category && !b.org_category) {
          categories.add(a.org_category);
          return 1;
        }
        if (a.org_category && b.org_category) {
          categories.add(a.org_category);
          categories.add(b.org_category);
          return a.org_category.localeCompare(b.org_category);
        }
        return a.name.localeCompare(b.name);
      });

      setOrgCategories(categories);

      if (productFilterId) {
        filteredProducts = filteredProducts.filter(
          (product) => product.id === productFilterId,
        );
      }
      setProductList(filteredProducts);
      if (filteredProducts?.length > 1 && !filteredProducts[0].product_units) {
        Sentry.captureException("No units returned in ProductPage");
      }
    }
  }, [inventoryProducts, productFilterId]);

  return (
    <>
      <PageOverview heading={"Products"} subheading={""} />
      <Page isLoading={productsLoading} isError={Boolean(error)}>
        <HStack>
          <InventoryProductSearch
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setProductFilterId={setProductFilterId}
            includeSearchIcon
          />
        </HStack>
        <AddProductModal isOpen={isOpen} onClose={onClose} />
        <DataTabs
          showButton={true}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          data={[
            {
              label: "Products",
              headers:
                orgCategories === null || orgCategories.size === 0
                  ? ["Name", "Units", "Category"]
                  : ["Name", "Units", "Custom Category", "Category"],
              entries: productList
                ? productList.map((product, index) => (
                    <ProductViewEntry
                      key={index}
                      itemIndex={index}
                      product={product}
                    >
                      <Td fontSize="sm">{product.name}</Td>
                      <Td fontSize="sm">
                        {product.product_units?.map((unit, unitIndex) => (
                          <Badge
                            colorScheme={badgeColors[unitIndex % 8]}
                            fontSize="0.8em"
                            key={unit.id}
                            marginRight="0.5rem"
                          >
                            {getProductUnitLabel(unit)}
                          </Badge>
                        ))}
                      </Td>
                      {orgCategories && orgCategories.size > 0 && (
                        <Td fontSize="sm">
                          {product.org_category ? product.org_category : ""}
                        </Td>
                      )}
                      <Td fontSize="sm">
                        {product.category ? (
                          <Badge
                            colorScheme={productCategoryBadgeColors(
                              product.category,
                            )}
                            fontSize="0.8em"
                            marginRight="0.5rem"
                          >
                            {getProductCategoryLabel(product.category)}
                          </Badge>
                        ) : (
                          ""
                        )}
                      </Td>
                    </ProductViewEntry>
                  ))
                : [],
            },
          ]}
        />
      </Page>
    </>
  );
}

export default ProductsPage;
