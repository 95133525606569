import React from "react";
import PageOverview from "../components/PageOverview/PageOverview.tsx";
import Page from "../components/Page/Page.tsx";
import { Text } from "@chakra-ui/react";

function PrivacyPolicy(): React.ReactElement {
  return (
    <>
      <PageOverview heading={"Privacy Policy"} />
      <Page isLoading={false} isError={false}>
        <Text>Privacy Policy</Text>
        <Text fontSize="sm">
          This Privacy Policy describes how we collect, use, and protect your
          personal information when you use our application. By using our
          application, you agree to the terms outlined below.
        </Text>
        <Text fontWeight="bold" mt={4}>
          1. Information We Collect
        </Text>
        <Text fontSize="sm">
          - Personal information, such as your name, email address, and other
          details you provide during registration.
          <br />- Usage information, including how you interact with the
          application and your preferences.
        </Text>
        <Text fontWeight="bold" mt={4}>
          2. How We Use Your Information
        </Text>
        <Text fontSize="sm">
          - To provide and maintain our services.
          <br />
          - To improve user experience and enhance the quality of our
          application.
          <br />- To communicate with you about updates, promotions, and
          relevant information.
        </Text>
        <Text fontWeight="bold" mt={4}>
          3. Sharing Your Information
        </Text>
        <Text fontSize="sm">
          - We do not sell your personal information to third parties.
          <br />- We may share your data with trusted partners who assist in
          operating our application, subject to confidentiality agreements.
        </Text>
        <Text fontWeight="bold" mt={4}>
          4. Data Security
        </Text>
        <Text fontSize="sm">
          We enforce robust security measures to protect your data. However, no
          digital platform can guarantee absolute security, and we encourage you
          to exercise caution.
        </Text>
        <Text fontWeight="bold" mt={4}>
          5. Your Rights
        </Text>
        <Text fontSize="sm">
          You have the right to access, update, or delete your personal
          information. For assistance, please contact our support team.
        </Text>
        <Text fontWeight="bold" mt={4}>
          6. Changes to This Policy
        </Text>
        <Text fontSize="sm">
          We may update this Privacy Policy periodically. Please review it
          regularly to stay informed of changes.
        </Text>
        <Text fontSize="sm" mt={4}>
          If you have any questions about this Privacy Policy, please contact us
          at support@stocky-ai.com.
        </Text>
      </Page>
    </>
  );
}

export default PrivacyPolicy;
