import { Box } from "@chakra-ui/react";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
} from "recharts";
import { AgingAccountReceivables } from "../../types";
import { useEffect, useState } from "react";

// eslint-disable-next-line react-refresh/only-export-components
export const AGE_BUCKETS = [
  {
    label: "0-7 days",
    min: 0,
    max: 7,
    color: "#2E7D32",
    selectedColor: "#56e35d",
  },
  {
    label: "8-14 days",
    min: 8,
    max: 14,
    color: "#1976D2",
    selectedColor: "#3399ff",
  },
  {
    label: "15-21 days",
    min: 15,
    max: 21,
    color: "#FFA000",
    selectedColor: "#fbb640",
  },
  {
    label: "22-29 days",
    min: 22,
    max: 29,
    color: "#E64A19",
    selectedColor: "#fa6637",
  },
  {
    label: "30-60 days",
    min: 30,
    max: 60,
    color: "#D32F2F",
    selectedColor: "#fa3e3e",
  },
  {
    label: "61+ days",
    min: 61,
    max: Infinity,
    color: "#7B1FA2",
    selectedColor: "#bd2efa",
  },
];

interface AgingChartProps {
  agingInvoices: AgingAccountReceivables[];
  size?: "small" | "large";
  onClick?: (ageBucket: string) => void;
  selectedBucket?: string | null;
}

export default function AgingChart({
  agingInvoices,
  size = "large",
  onClick = () => {},
  selectedBucket,
}: AgingChartProps) {
  const [agingData, setAgingData] = useState<
    {
      name: string;
      value: number;
      count: number;
      color: string;
      selectedColor: string;
      isSelected: boolean;
    }[]
  >([]);

  useEffect(() => {
    const agingData = agingInvoices
      .filter((item) => item.total_amount > 0)
      .map((item) => {
        const bucket = AGE_BUCKETS.find((b) => b.label === item.age_bucket);
        return {
          name: item.age_bucket,
          value: Number(item.total_amount),
          count: item.invoice_count,
          color: bucket?.color || "#000000",
          selectedColor: bucket?.selectedColor || "#000000",
          isSelected: selectedBucket === item.age_bucket,
        };
      });
    setAgingData(agingData);
  }, [agingInvoices, selectedBucket]);

  const total = agingInvoices.reduce(
    (sum, item) => sum + Number(item.total_amount),
    0,
  );
  const dimensions = {
    small: {
      height: "200px",
      width: "450px",
      innerRadius: 40,
      outerRadius: 55,
    },
    large: {
      height: "300px",
      width: "600px",
      innerRadius: 60,
      outerRadius: 80,
    },
  };

  const { height, width, innerRadius, outerRadius } = dimensions[size];

  return (
    <Box height={height} width={width}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={agingData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            label={size === "large"}
          >
            {agingData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry.isSelected ? entry.selectedColor : entry.color}
                onClick={() => onClick(entry.name)}
                stroke={entry.isSelected ? "#000000" : "#ffffff"}
                strokeWidth={entry.isSelected ? 2 : 1}
              />
            ))}
          </Pie>
          <Legend
            layout="vertical"
            align="right"
            verticalAlign="middle"
            content={(props) => {
              const { payload } = props;
              return (
                <ul
                  style={{
                    listStyle: "none",
                    padding: 0,
                    margin: 0,
                    color: "black",
                  }}
                >
                  {payload?.map((entry, index) => {
                    const amount =
                      agingData.find((item) => item.name === entry.value)
                        ?.value || 0;
                    const percentage = ((amount / total) * 100).toFixed(1);
                    return (
                      <li key={`item-${index}`} style={{ margin: "5px 0" }}>
                        <span style={{ color: entry.color }}>●</span>{" "}
                        {entry.value}:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          $
                          {amount.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </span>{" "}
                        ({percentage}%)
                      </li>
                    );
                  })}
                  <li style={{ margin: "5px 0", fontWeight: "bold" }}>
                    Total: $
                    {total.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </li>
                </ul>
              );
            }}
          />
          <RechartsTooltip
            formatter={(value: number) => `$${value.toFixed(2)}`}
          />
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
}
