import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LocationPicker from "../feature/LocationPicker/LocationPicker";
import { routes } from "../index";
import { useGetCurrentUserQuery } from "../redux/apiSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { userSlice } from "../redux/userSlice";
import {
  checkIfLocationHasAccess,
  checkIfOrgHasAccess,
} from "../routes/PrivateRoute";
import { getLocationAndOrgName } from "../utils/invoiceUtils.ts";
import { checkIsLoginPath } from "../utils/routerUtils";
import { Logo } from "./Logo/Logo";
import { CollapsibleNavLink } from "./Navigation/CollapsibleNavLink";

export default function AppNavBar() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const navLocation = useLocation();
  const isAuthenticated = useAppSelector(
    userSlice.selectors.getIsAuthenticated,
  );
  const hasSettings = useAppSelector(
    userSlice.selectors.getHasSettings
  );

  const {
    getCurrentOrganisationId,
    getCurrentLocation,
    getCurrentOrganisation,
  } = userSlice.selectors;
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isTablet] = useMediaQuery("(max-width: 1024px)");
  const currentOrganisationId = useAppSelector(getCurrentOrganisationId);
  const currentLocation = useAppSelector(getCurrentLocation);
  const currentOrganisation = useAppSelector(getCurrentOrganisation);
  const [isAdmin, setIsAdmin] = useState(false);

  const { data: currentUser } = useGetCurrentUserQuery("", {
    skip: !isAuthenticated,
  });

  useEffect(() => {
    if (currentUser) {
      setIsAdmin(currentUser.organisation_id === 1);
    }
  }, [currentUser]);

  const isLoginPath = checkIsLoginPath(navLocation);

  const navigateToLogin = () => {
    if (!isLoginPath) {
      navigate("/login");
    }
  };

  const filteredRoutes = routes.filter((route) => {
    if (
      !currentLocation ||
      !route.includeInNav ||
      !route.navName ||
      !route.navLink ||
      !currentOrganisationId
    ) {
      return false;
    }
    if (!isAdmin && route.isPreRelease) {
      return false;
    }
    if (currentOrganisation && currentLocation) {
      if (route.locationFilters && route.locationFilters.length > 0) {
        if (!checkIfLocationHasAccess(currentLocation, route.locationFilters)) {
          return false;
        }
      }
      return checkIfOrgHasAccess(currentOrganisation, route.name);
    }
    return true;
  });

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [showSectionName, setShowSectionName] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    // Set timeout to display section name after collapsing
    if (!isCollapsed) {
      setTimeout(() => {
        setShowSectionName(true);
      }, 300); // TODO: fix, display of section name is buggy
    } else {
      setTimeout(() => {
        setShowSectionName(true);
      }, 100);
    }
  }, [isCollapsed]);

  const handleMouseEnter = () => {
    if (isTablet || isMobile) {
      return;
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsCollapsed(false);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsCollapsed(true);
    }, 300);
  };

  const renderNavLinks = (isMobile = false) => (
    <VStack spacing={2} align="stretch" w="full">
      {filteredRoutes.map((route, index) => {
        const isActive = navLocation.pathname === `/${route.navLink}`;
        if (route.isSection) {
          return (
            <Box key={route.navLink}>
              <Text
                fontSize="sm"
                fontWeight="bold"
                color="gray.500"
                textTransform="uppercase"
                height="32px"
                isTruncated={!isCollapsed}
                px={4}
                py={2}
              >
                {!isCollapsed && showSectionName ? route.navName : "-"}
              </Text>
            </Box>
          );
        }

        if (route.subLinks) {
          return (
            <CollapsibleNavLink
              key={route.navLink}
              navName={route?.navName || ""}
              navIcon={route.navIcon}
              navLink={route.navLink || ""}
              subLinks={route.subLinks}
              isCollapsed={isCollapsed}
              isMobile={isMobile}
              handleClick={
                () => {}
                // isTablet ? () => setIsCollapsed((prev) => !prev) : undefined
              }
              onSubLinkClick={
                isTablet ? () => setIsCollapsed((prev) => !prev) : undefined
              }
            />
          );
        }

        return (
          <React.Fragment key={route.navLink}>
            <Link
              to={`/${route.navLink}`}
              onClick={
                isMobile ? onClose : () => !isCollapsed && setIsCollapsed(true)
              }
            >
              <Button
                w="full"
                variant="ghost"
                p={3}
                _hover={{ bg: "teal.50" }}
                bg={isActive ? "teal.50" : "transparent"}
                color={isActive ? "teal.500" : "inherit"}
                display="flex"
                justifyContent={isCollapsed ? "center" : "flex-start"}
              >
                <Flex
                  alignItems="center"
                  w="full"
                  justifyContent={isCollapsed ? "center" : "flex-start"}
                >
                  <Box
                    fontSize="xl"
                    w="24px"
                    h="24px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mx={isCollapsed ? "0" : "0"}
                    color={isActive ? "teal.500" : "inherit"}
                  >
                    {route.navIcon}
                  </Box>
                  {(!isCollapsed || isMobile) && (
                    <Text ml={3} noOfLines={1}>
                      {route.navName}
                    </Text>
                  )}
                </Flex>
              </Button>
            </Link>
            {isMobile && index < filteredRoutes.length - 1 && <Divider />}
          </React.Fragment>
        );
      })}
    </VStack>
  );

  const bgColor = useColorModeValue("gray.100", "gray.900");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  const { isOpen, onOpen, onClose } = useDisclosure();

  if (isMobile) {
    return (
      <>
        <Flex
          as="nav"
          position="fixed"
          top={0}
          left={0}
          right={0}
          h="16"
          bg={bgColor}
          borderBottom="1px"
          borderColor={borderColor}
          px={4}
          alignItems="center"
          zIndex={1000}
        >
          <IconButton
            aria-label="Open menu"
            icon={<HamburgerIcon />}
            variant="ghost"
            onClick={onOpen}
            position="absolute"
            left={4}
          />
          <Box flex={1} display="flex" justifyContent="center">
            <Logo />
          </Box>
        </Flex>

        <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="full">
          <DrawerOverlay />
          <DrawerContent bg={bgColor}>
            <DrawerCloseButton />
            <DrawerBody pt={16}>
              <VStack spacing={6} align="stretch">
                {renderNavLinks(true)}
                {isAuthenticated && currentLocation && (
                  <VStack spacing={6}>
                    <Divider />
                    <Menu placement="bottom">
                      <MenuButton as={Button} w="full" variant="ghost">
                        <Flex alignItems="center" gap={3}>
                          <Avatar size="sm" />
                          <VStack spacing={0} alignItems="start">
                            <Text fontSize="sm">
                              {currentUser?.first_name} {currentUser?.last_name}
                            </Text>
                            <Text fontSize="xs" color="gray.500">
                              {getLocationAndOrgName(currentLocation)}
                            </Text>
                          </VStack>
                        </Flex>
                      </MenuButton>
                      <MenuList>
                        <Box p={2}>
                          {currentUser?.first_name} {currentUser?.last_name}
                        </Box>
                        <MenuDivider />
                        <LocationPicker />
                        <MenuItem isDisabled>Settings</MenuItem>
                        <MenuDivider />
                        <MenuItem
                          isDisabled={isLoginPath || !isAuthenticated}
                          onClick={() => {
                            dispatch(userSlice.actions.logout());
                            navigateToLogin();
                          }}
                        >
                          Sign out
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </VStack>
                )}
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    );
  }

  return (
    <Flex
      as="nav"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      position="fixed"
      left={0}
      top={0}
      h="100vh"
      w={isCollapsed ? "16" : "64"}
      direction="column"
      bg={bgColor}
      borderRight="1px"
      borderColor={borderColor}
      transition="all 0.3s ease-in-out"
    >
      {/* Logo section with collapse button */}
      <Flex
        p={4}
        mb={2}
        justifyContent={isCollapsed ? "center" : "space-between"}
        alignItems="center"
      >
        <Box
          width={isCollapsed ? "12" : "full"}
          transition="all 0.3s ease-in-out"
          transform={isCollapsed ? "scale(0.9)" : "scale(1)"}
          position="relative"
        >
          <Logo
            onClick={() => {
              setIsCollapsed((prev) => !prev);
            }}
          />
        </Box>
      </Flex>

      {/* Navigation section */}
      <Flex direction="column" flex="1" overflow="hidden">
        <Box
          flex="1"
          overflow="auto"
          px={isCollapsed ? 2 : 4}
          className="nav-item"
          css={{
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "gray.200",
              borderRadius: "24px",
            },
          }}
        >
          {renderNavLinks()}
        </Box>
      </Flex>

      {/* User section */}
      {isAuthenticated && currentLocation && (
        <Box
          p={isCollapsed ? 2 : 4}
          borderTop="1px"
          borderColor={borderColor}
          bg={bgColor}
        >
          <Menu placement="right">
            <MenuButton
              as={Button}
              w="full"
              variant="ghost"
              p={2}
              height="auto"
            >
              <Flex
                alignItems="center"
                gap={2}
                justifyContent="flex-start"
                w="full"
              >
                <Avatar size="sm" />
                {!isCollapsed && (
                  <VStack
                    spacing={0}
                    alignItems="flex-start"
                    flex={1}
                    overflow="hidden"
                  >
                    <Tooltip
                      label={`${currentUser?.first_name} ${currentUser?.last_name}`}
                    >
                      <Text fontSize="sm" isTruncated maxW="100%" w="full">
                        {currentUser?.first_name} {currentUser?.last_name}
                      </Text>
                    </Tooltip>
                    <Tooltip label={getLocationAndOrgName(currentLocation)}>
                      <Text
                        fontSize="xs"
                        color="gray.500"
                        isTruncated
                        maxW="100%"
                        w="full"
                      >
                        {getLocationAndOrgName(currentLocation)}
                      </Text>
                    </Tooltip>
                  </VStack>
                )}
              </Flex>
            </MenuButton>
            <MenuList>
              <Box p={2}>
                {currentUser?.first_name} {currentUser?.last_name}
              </Box>
              <MenuDivider />
              <LocationPicker />
              <MenuItem 
                isDisabled={ !hasSettings }
                onClick={()=>{
                  dispatch(userSlice.actions.toggleSettings());
                }}
              >
                Settings
              </MenuItem>
              <MenuDivider />
              <MenuItem
                isDisabled={isLoginPath || !isAuthenticated}
                onClick={() => {
                  dispatch(userSlice.actions.logout());
                  navigateToLogin();
                }}
              >
                Sign out
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      )}
    </Flex>
  );
}
