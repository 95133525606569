import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  NumberInput,
  NumberInputField,
  Select,
  Spacer,
  Stack,
  StackDivider,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { Payment, PaymentInvoiceJunction, PaymentType } from "../../types";
import {
  useGetPaymentInvoicesForPaymentQuery,
  useGetSupplierInvoicesQuery,
  usePutPaymentMutation,
} from "../../redux/apiSlice";
import {
  formatCurrency,
  getLocationAndOrgName,
} from "../../utils/invoiceUtils";
import { paymentTypeLabels } from "../../utils/paymentUtils";
import InvoicePaymentEntry from "./InvoicePaymentEntry";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";

interface PaymentDetailDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  payment: Payment;
  isReadOnly?: boolean;
}

function PaymentDetailDrawer({
  isOpen,
  onClose,
  payment,
  isReadOnly = false,
}: PaymentDetailDrawerProps): React.ReactElement {
  const toast = useToast();

  const [paymentDate, setPaymentDate] = useState(payment.payment_date);
  const [amount, setAmount] = useState<string>(
    Number(payment.amount).toFixed(2),
  );
  const [amountAssigned, setAmountAssigned] = useState<number>(
    payment.amount_assigned || 0,
  );
  const [amountRemaining, setAmountRemaining] = useState<number>(
    payment.amount - (payment.amount_assigned || 0),
  );
  const [paymentType, setPaymentType] = useState(payment.payment_type);
  const [referenceNumber, setReferenceNumber] = useState(
    payment.reference_number,
  );
  const [lodgedDate, setLodgedDate] = useState(payment.lodged_date);
  const [bouncedDate, setBouncedDate] = useState(payment.bounced_date);

  const [havePaymentInvoicesBeenModified, setHavePaymentInvoicesBeenModified] =
    useState(false);
  const [currentPaymentInvoices, setCurrentPaymentInvoices] = useState<
    PaymentInvoiceJunction[]
  >([]);
  const [invoiceIdToPaymentInvoice, setInvoiceIdToPaymentInvoice] = useState<
    Map<number, PaymentInvoiceJunction>
  >(new Map());
  const [
    invoiceIdToOriginalPaymentInvoice,
    setInvoiceIdToOriginalPaymentInvoice,
  ] = useState<Map<number, PaymentInvoiceJunction>>(new Map());

  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  useEffect(() => {
    setPaymentDate(payment.payment_date);
    setAmount(Number(payment.amount).toFixed(2));
    setPaymentType(payment.payment_type);
    setReferenceNumber(payment.reference_number);
    setLodgedDate(payment.lodged_date);
    setBouncedDate(payment.bounced_date);
  }, [payment]);

  const { data: invoices } = useGetSupplierInvoicesQuery(
    {
      organisationId: payment.organisation_id,
      locationId: payment.location_id,
      buyerLocationId: payment.customer_location_id,
    },
    {
      skip:
        !payment.organisation_id ||
        !payment.location_id ||
        !payment.customer_location_id,
    },
  );

  const { data: invoicePayments } = useGetPaymentInvoicesForPaymentQuery(
    { paymentId: payment.id },
    { skip: !payment.id },
  );

  const [putPayment, { isLoading: isPaymentUpdating }] =
    usePutPaymentMutation();

  useEffect(() => {
    if (invoicePayments) {
      setCurrentPaymentInvoices(invoicePayments);
      const invoiceIdToPaymentInvoiceMap = new Map<
        number,
        PaymentInvoiceJunction
      >();
      invoicePayments.forEach((paymentInvoice) => {
        invoiceIdToPaymentInvoiceMap.set(
          paymentInvoice.invoice_id,
          paymentInvoice,
        );
      });
      setInvoiceIdToPaymentInvoice(invoiceIdToPaymentInvoiceMap);
      setInvoiceIdToOriginalPaymentInvoice(invoiceIdToPaymentInvoiceMap);
    } else {
      setCurrentPaymentInvoices([]);
      setInvoiceIdToPaymentInvoice(new Map());
      setInvoiceIdToOriginalPaymentInvoice(new Map());
    }
  }, [invoicePayments]);

  useEffect(() => {
    if (payment) {
      let remaining = Number(payment.amount);
      if (currentPaymentInvoices) {
        const invoiceIdToPaymentInvoiceMap = new Map<
          number,
          PaymentInvoiceJunction
        >();
        currentPaymentInvoices.forEach((paymentInvoice) => {
          invoiceIdToPaymentInvoiceMap.set(
            paymentInvoice.invoice_id,
            paymentInvoice,
          );
        });
        setInvoiceIdToPaymentInvoice(invoiceIdToPaymentInvoiceMap);

        const assigned = currentPaymentInvoices.reduce(
          (acc, invoicePayment) => {
            return acc + Number(invoicePayment.amount_paid);
          },
          0,
        );
        setAmountAssigned(assigned);
        remaining -= assigned;
      }
      setAmountRemaining(remaining);
    }
  }, [payment, currentPaymentInvoices]);

  const addPaymentInvoiceToCurrent = (
    invoiceId: number,
    amountAssigned: number,
  ) => {
    const newPaymentInvoice: PaymentInvoiceJunction = {
      payment_id: payment.id,
      invoice_id: invoiceId,
      amount_paid: amountAssigned,
    };
    setCurrentPaymentInvoices([...currentPaymentInvoices, newPaymentInvoice]);
  };

  const updatePaymentInvoiceAmount = (
    invoiceId: number,
    amountPaid: number,
  ) => {
    const updatedPaymentInvoices = currentPaymentInvoices.map(
      (paymentInvoice) => {
        if (paymentInvoice.invoice_id === invoiceId) {
          return { ...paymentInvoice, amount_paid: amountPaid };
        }
        return paymentInvoice;
      },
    );
    setCurrentPaymentInvoices(updatedPaymentInvoices);
  };

  const handleAddOrUpdateInvoicePayment = (
    invoiceId: number,
    amountPaid: number,
  ) => {
    setHavePaymentInvoicesBeenModified(true);
    if (!invoiceIdToPaymentInvoice.get(invoiceId))
      addPaymentInvoiceToCurrent(invoiceId, amountPaid);
    else updatePaymentInvoiceAmount(invoiceId, amountPaid);
  };

  const handlePutPayment = () => {
    if (!payment?.id) {
      toast({
        title: "Payment ID not found!",
        description: "Please try again or contact support if issue persists",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    putPayment({
      id: payment.id,
      payment_date: paymentDate,
      amount: Number(amount),
      payment_type: paymentType,
      reference_number: referenceNumber,
      lodged_date: lodgedDate,
      bounced_date: bouncedDate,
      payment_invoices: havePaymentInvoicesBeenModified
        ? currentPaymentInvoices
        : undefined,
    })
      .unwrap()
      .then(() => {
        toast({
          title: "Payment updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onClose();
      })
      .catch((err) => {
        let errorMessage = "";
        if (err.data?.detail?.[0]?.msg) {
          errorMessage = " - " + err.data.detail[0].msg;
        }
        toast({
          title: `Error updating payment${errorMessage}`,
          description: "Please try again or contact support if issue persists",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <Drawer
      size={isFullscreen ? "full" : "xl"}
      isOpen={isOpen}
      onClose={onClose}
      placement="right"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <Stack direction={"row"}>
            <Heading size={"md"}>Payment</Heading>
            <Spacer />
            <Button
              onClick={() => setIsFullscreen(!isFullscreen)}
              size="md"
              variant="outline"
              mr={4}
            >
              {isFullscreen ? (
                <AiOutlineFullscreenExit />
              ) : (
                <AiOutlineFullscreen />
              )}
            </Button>
          </Stack>
        </DrawerHeader>
        <DrawerBody>
          <Grid templateColumns="repeat(3, 1fr)" gap={4}>
            <GridItem>
              <FormLabel size={"sm"}>Payment ID:</FormLabel>
              <Text size={"sm"}>{payment.id}</Text>
            </GridItem>
            <GridItem>
              <FormLabel size={"sm"}>Customer:</FormLabel>
              <Text size={"sm"}>
                {getLocationAndOrgName(payment.customer_location)}
              </Text>
            </GridItem>
            <GridItem>
              <FormLabel size={"sm"}>Amount:</FormLabel>
              <InputGroup>
                <InputLeftElement>$</InputLeftElement>
                <NumberInput
                  min={0.01}
                  isReadOnly={isReadOnly}
                  value={amount}
                  precision={2}
                  width="100%"
                >
                  <NumberInputField
                    paddingLeft="24px"
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </NumberInput>
              </InputGroup>
            </GridItem>
          </Grid>
          <Flex
            justifyContent={"space-between"}
            gap={4}
            mt={8}
            flexDirection={["column", "row"]}
          >
            <Stack>
              <FormLabel size={"sm"}>Payment Date:</FormLabel>
              <Input
                type={"date"}
                size={"sm"}
                value={paymentDate}
                onChange={(e) => setPaymentDate(e.target.value)}
                isReadOnly={isReadOnly}
              />
            </Stack>
            <Stack>
              <FormLabel size={"sm"}>Payment Type:</FormLabel>
              <Select
                size={"sm"}
                value={paymentType}
                onChange={(e) => setPaymentType(e.target.value as PaymentType)}
                isReadOnly={isReadOnly}
              >
                {Object.values(PaymentType).map((paymentType) => (
                  <option key={paymentType} value={paymentType}>
                    {paymentTypeLabels[paymentType]}
                  </option>
                ))}
              </Select>
            </Stack>
            <Stack>
              <FormLabel size={"sm"}>Reference Number:</FormLabel>
              <Input
                size={"sm"}
                value={referenceNumber}
                onChange={(e) => setReferenceNumber(e.target.value)}
                isReadOnly={isReadOnly}
              />
            </Stack>
            {paymentType === "CHEQUE" && (
              <>
                <Stack>
                  <FormLabel size={"sm"}>Lodged Date:</FormLabel>
                  <Input
                    type={"date"}
                    size={"sm"}
                    value={lodgedDate ? lodgedDate.slice(0, 10) : ""}
                    onChange={(e) => setLodgedDate(e.target.value)}
                    isReadOnly={isReadOnly}
                  />
                </Stack>
                <Stack>
                  <FormLabel size={"sm"}>Bounced Date:</FormLabel>
                  <Input
                    type={"date"}
                    size={"sm"}
                    value={bouncedDate ? bouncedDate.slice(0, 10) : ""}
                    onChange={(e) => setBouncedDate(e.target.value)}
                    isReadOnly={isReadOnly}
                  />
                </Stack>
              </>
            )}
          </Flex>
          <Card mt={8}>
            <Accordion allowToggle>
              <AccordionItem>
                <CardHeader>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        <Heading size="md">Invoices</Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <Stack direction={"row"} m={4}>
                    <Text>
                      <b>Amount Assigned:</b> ${formatCurrency(amountAssigned)}
                    </Text>
                    <Spacer />
                    <Text>
                      <b>Amount Remaining:</b> $
                      {formatCurrency(amountRemaining)}
                    </Text>
                  </Stack>
                </CardHeader>

                <AccordionPanel pb={4}>
                  <CardBody>
                    <Stack divider={<StackDivider />} spacing="4">
                      {invoices
                        ?.slice()
                        .sort((a, b) => {
                          const statusOrder = ["PAID", "PAID_BALANCED"];
                          if (
                            statusOrder.includes(a.status) &&
                            !statusOrder.includes(b.status)
                          ) {
                            return 1;
                          }
                          if (
                            !statusOrder.includes(a.status) &&
                            statusOrder.includes(b.status)
                          ) {
                            return -1;
                          }
                          return a.id - b.id;
                        })
                        .map((invoice, index) => (
                          <Box key={index}>
                            <InvoicePaymentEntry
                              paymentInvoice={invoiceIdToPaymentInvoice.get(
                                invoice.id,
                              )}
                              invoice={invoice}
                              paymentAmountRemaining={amountRemaining}
                              updatePaymentInvoiceAmount={
                                handleAddOrUpdateInvoicePayment
                              }
                              originalAmountAssigned={Number(
                                invoiceIdToOriginalPaymentInvoice.get(
                                  invoice.id,
                                )?.amount_paid ?? 0,
                              ).toFixed(2)}
                            />
                          </Box>
                        ))}
                    </Stack>
                  </CardBody>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Card>
        </DrawerBody>
        <DrawerFooter>
          <Button
            colorScheme="teal"
            onClick={handlePutPayment}
            isLoading={isPaymentUpdating}
          >
            Update Payment
          </Button>
          <Spacer />
          <Button colorScheme="red" variant={"ghost"} mr={3} onClick={onClose}>
            Close
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export default PaymentDetailDrawer;
