import { Checkbox, Td, useDisclosure } from "@chakra-ui/react";
import * as React from "react";
import InteractiveRow from "../../components/InteractiveRow/InteractiveRow";
import { AccountInfo } from "./types";
import AvatarEntry from "../../components/AvatarEntry/AvatarEntry";
import AccountsDrawer from "../../routes/AccountsDrawer.tsx";

interface AccountsEntryProps {
  index: number;
  accountInfo: AccountInfo;
  isSelected: boolean;
  onSelect: () => void;
}
export default function AccountsEntry({
  index,
  accountInfo,
  isSelected,
  onSelect,
}: AccountsEntryProps): React.ReactElement {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <InteractiveRow
        key={index}
        onClick={isOpen ? onClose : onOpen}
        children={[
          <Td>
            <Checkbox isChecked={isSelected} onChange={onSelect} />
          </Td>,
          <Td fontSize="sm">
            <AvatarEntry name={accountInfo.name} />
          </Td>,
        ]}
      />
      {isOpen && (
        <AccountsDrawer
          isOpen={isOpen}
          onClose={onClose}
          accountInfo={accountInfo}
        />
      )}
    </>
  );
}
