import { Td, useDisclosure } from "@chakra-ui/react";
import React from "react";
import InteractiveRow from "../../components/InteractiveRow/InteractiveRow";
import { EventSalesReconciliation } from "../../types";
import EventSalesRecordModal from "./EventSalesRecordModal.tsx";

interface EventSalesViewEntryProps {
  index: number;
  eventInfo: EventSalesReconciliation;
}

export default function EventSalesViewEntry({
  index,
  eventInfo,
}: EventSalesViewEntryProps): React.ReactElement {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {isOpen && (
        <EventSalesRecordModal
          isOpen={isOpen}
          onClose={onClose}
          isUpdate={true}
          existingRecord={eventInfo}
        />
      )}
      <InteractiveRow key={index} onClick={isOpen ? onClose : onOpen}>
        <Td fontSize="sm">{eventInfo.event_date.slice(0, 10)}</Td>
        <Td fontSize="sm">{eventInfo.event_name}</Td>
        <Td fontSize="sm">
          {eventInfo.reconciled_at && eventInfo.reconciled_at}
        </Td>
      </InteractiveRow>
    </>
  );
}
