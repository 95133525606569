import { Badge, Checkbox, Td, useDisclosure } from "@chakra-ui/react";
import * as React from "react";
import InteractiveRow from "../../components/InteractiveRow/InteractiveRow";
import {
  formatCurrency,
  getInvoiceStatusColor,
  getInvoiceStatusLabel,
} from "../../utils/invoiceUtils";
import { AccountInfo } from "./types";
import AvatarEntry from "../../components/AvatarEntry/AvatarEntry";
import { useState } from "react";
import { Invoice, Payment } from "../../types.ts";
import InvoiceDrawer from "../OrdersView/InvoiceDrawer.tsx";
import PaymentDetailDrawer from "../PaymentsView/PaymentDetailDrawer.tsx";

interface AccountsReceivablesEntryProps {
  index: number;
  accountInfo: AccountInfo;
  isSelected: boolean;
  onSelect: () => void;
}
export default function AccountsReceivablesEntry({
  index,
  accountInfo,
  isSelected,
  onSelect,
}: AccountsReceivablesEntryProps): React.ReactElement {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | undefined>(
    undefined,
  );
  const {
    isOpen: isInvoiceDetailsDrawerOpen,
    onOpen: onInvoiceDetailsDrawerOpen,
    onClose: onInvoiceDetailsDrawerClose,
  } = useDisclosure();

  const [selectedPayment, setSelectedPayment] = useState<Payment | null>(null);
  const {
    isOpen: isPaymentDetailsDrawerOpen,
    onOpen: onPaymentDetailsDrawerOpen,
    onClose: onPaymentDetailsDrawerClose,
  } = useDisclosure();

  return (
    <>
      <InteractiveRow key={index} onClick={isOpen ? onClose : onOpen}>
        <Td>
          <Checkbox isChecked={isSelected} onChange={onSelect} />
        </Td>
        <Td fontSize="sm">
          <AvatarEntry name={accountInfo.name} />
        </Td>
        <Td fontSize="sm">${formatCurrency(accountInfo.amountOwed)}</Td>
        <Td fontSize="sm">${formatCurrency(accountInfo.creditAmount)}</Td>
        <Td fontSize="sm">${formatCurrency(accountInfo.amountPaid)}</Td>
        <Td fontSize="sm">${formatCurrency(accountInfo.balance)}</Td>
      </InteractiveRow>
      {isOpen && (
        <>
          {accountInfo.accountEntries.map((entry, entryIndex) => {
            return (
              <InteractiveRow
                key={`${index}-${entryIndex}`}
                onClick={() => {
                  if (entry.invoice) {
                    setSelectedInvoice(entry.invoice);
                    onInvoiceDetailsDrawerOpen();
                  } else if (entry.payment) {
                    setSelectedPayment(entry.payment);
                    onPaymentDetailsDrawerOpen();
                  }
                }}
              >
                <Td />
                <Td fontSize="sm" pl={16} fontWeight={600}>
                  {entry.name}{" "}
                  {entry.isInvoice && (
                    <Badge
                      colorScheme={getInvoiceStatusColor(entry.status, true)}
                    >
                      {getInvoiceStatusLabel(entry.status, true)}
                    </Badge>
                  )}
                </Td>
                <Td fontSize="sm" fontWeight={600}>
                  ${formatCurrency(entry.amountOwed)}
                </Td>
                <Td fontSize="sm" fontWeight={600}>
                  ${formatCurrency(entry.creditAmount)}
                </Td>
                <Td fontSize="sm" fontWeight={600}>
                  {entry.isInvoice ? "(" : ""}$
                  {formatCurrency(entry.amountPaid)}
                  {entry.isInvoice ? ")" : ""}
                </Td>
                <Td fontSize="sm" fontWeight={600}>
                  ${formatCurrency(entry.balance)}
                </Td>
              </InteractiveRow>
            );
          })}
        </>
      )}
      {isPaymentDetailsDrawerOpen && selectedPayment && (
        <PaymentDetailDrawer
          isOpen={isPaymentDetailsDrawerOpen}
          onClose={onPaymentDetailsDrawerClose}
          payment={selectedPayment}
        />
      )}
      {isInvoiceDetailsDrawerOpen && selectedInvoice && (
        <InvoiceDrawer
          isOpen={isInvoiceDetailsDrawerOpen}
          onClose={onInvoiceDetailsDrawerClose}
          invoice={selectedInvoice}
          isSupplierView={true}
        />
      )}
    </>
  );
}
