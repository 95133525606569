import { useEffect, useRef, useState } from "react";
import {
  useGetLatestQuickbooksTaskQuery,
  useGetQuickbooksTaskQuery,
  usePostQuickbooksOauthCallbackMutation,
  usePostQuickbooksOrganisationsMutation,
  usePostQuickbooksProductsMutation,
} from "../redux/apiSlice";
import { Box, Button, Spinner, Text, useToast, VStack } from "@chakra-ui/react";
import PageOverview from "../components/PageOverview/PageOverview";
import { userSlice } from "../redux/userSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import QuickbooksLoginButton, {
  useQBOauthPopupListener,
} from "../components/Quickbooks/LoginButton";
import Page from "../components/Page/Page";
import { handleException } from "../utils/errorUtils.ts";
import {
  QuickbooksTask,
  QuickbooksTaskStatus,
  QuickbooksTaskType,
} from "../types.ts";
import {
  formatDateTimeStringToLocal,
  getTimeDeltaInWords,
  getTimeDeltaInMinutes,
} from "../utils/dateUtils.ts";

function getSearchParams() {
  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get("code");
  const realmId = searchParams.get("realmId");
  const state = searchParams.get("state");
  const tab = searchParams.get("tab");
  return { code, realmId, state, tab };
}

enum QuickbooksTab {
  AccountManagement,
  OAuthCallback,
}

const SYNC_TIMEOUT_LIMIT_MINUTES = 1;

export default function QuickbooksPanel() {
  const { code, realmId, state, tab } = getSearchParams();

  let showPage;

  if (tab && tab == "account") {
    showPage = QuickbooksTab.AccountManagement;
  } else if (code && realmId && state) {
    showPage = QuickbooksTab.OAuthCallback;
  } else {
    throw new Error("Invalid tab");
  }

  switch (showPage) {
    case QuickbooksTab.AccountManagement:
      return <QuickbooksAccountManagement />;
    case QuickbooksTab.OAuthCallback:
      return <QuickbooksOauthCallback />;
  }
}

function QuickbooksUserCompanyInfo() {
  const { getQuickbooksUserCompany } = userSlice.selectors;
  const company = useAppSelector(getQuickbooksUserCompany);

  return (
    <>
      {company && (
        <VStack align={"flex-start"} spacing={4} margin={4}>
          <Box>
            <h1>
              <strong>{company.name}</strong>
            </h1>
          </Box>
          <Box>{company.email}</Box>
        </VStack>
      )}
    </>
  );
}

function QuickbooksExportPanel() {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { getCurrentOrganisationId, getCurrentLocationId } =
    userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const locationId = useAppSelector(getCurrentLocationId);
  const setConnectedToQuickbooks = userSlice.actions.setConnectedToQuickbooks;

  const [exportOrganisationsToQuickbooks] =
    usePostQuickbooksOrganisationsMutation();

  const [exportProductsToQuickbooks] = usePostQuickbooksProductsMutation();

  const [activeOrgSyncTask, setActiveOrgSyncTask] =
    useState<QuickbooksTask | null>(null);
  const [activeProductSyncTask, setActiveProductSyncTask] =
    useState<QuickbooksTask | null>(null);

  const checkIfTaskFinished = (task: QuickbooksTask | null): boolean => {
    return Boolean(
      task &&
        (task.status === QuickbooksTaskStatus.SUCCESS ||
          task.status === QuickbooksTaskStatus.FAILED),
    );
  };

  const checkIfTaskPending = (task: QuickbooksTask | null): boolean =>
    Boolean(task && task.status === QuickbooksTaskStatus.PENDING);

  const checkIfTaskOngoing = (task: QuickbooksTask | null): boolean =>
    Boolean(task && task.status === QuickbooksTaskStatus.RUNNING);

  const checkIfTaskSucceeded = (task: QuickbooksTask | null): boolean =>
    Boolean(task && task.status === QuickbooksTaskStatus.SUCCESS);

  const {
    data: latestOrgSyncTask = null,
    isLoading: fetchingLatestOrgSyncTask,
    refetch: refetchLatestOrgSyncTask,
  } = useGetLatestQuickbooksTaskQuery(
    {
      organisationId,
      locationId,
      taskType: QuickbooksTaskType.EXPORT_CUSTOMERS_AND_SUPPLIERS,
    },
    {
      skip: !organisationId || !locationId,
    },
  );

  const {
    data: latestProductSyncTask = null,
    isLoading: fetchingLatestProductSyncTask,
    refetch: refetchLatestProductSyncTask,
  } = useGetLatestQuickbooksTaskQuery(
    {
      organisationId,
      locationId,
      taskType: QuickbooksTaskType.EXPORT_PRODUCTS,
    },
    {
      skip: !organisationId || !locationId,
    },
  );

  const {
    data: currentOrgSyncTask = null,
    isLoading: fetchingCurrentOrgSyncTask,
  } = useGetQuickbooksTaskQuery(activeOrgSyncTask?.id || 0, {
    skip: !activeOrgSyncTask || checkIfTaskFinished(activeOrgSyncTask),
    pollingInterval:
      !activeOrgSyncTask ||
      (activeOrgSyncTask &&
        getTimeDeltaInMinutes(activeOrgSyncTask.updated_at) <
          SYNC_TIMEOUT_LIMIT_MINUTES)
        ? 3000
        : 60000,
    skipPollingIfUnfocused: true,
  });

  const {
    data: currentProductSyncTask = null,
    isLoading: fetchingCurrentProductSyncTask,
  } = useGetQuickbooksTaskQuery(activeProductSyncTask?.id || 0, {
    skip: !activeProductSyncTask || checkIfTaskFinished(activeProductSyncTask),
    pollingInterval:
      !activeProductSyncTask ||
      (activeProductSyncTask &&
        getTimeDeltaInMinutes(activeProductSyncTask.updated_at) <
          SYNC_TIMEOUT_LIMIT_MINUTES)
        ? 3000
        : 60000,
    skipPollingIfUnfocused: true,
  });

  useEffect(() => {
    if (
      checkIfTaskOngoing(latestOrgSyncTask) ||
      checkIfTaskPending(latestOrgSyncTask)
    ) {
      setActiveOrgSyncTask(latestOrgSyncTask);
    } else {
      setActiveOrgSyncTask(null);
    }
  }, [latestOrgSyncTask]);

  useEffect(() => {
    if (
      checkIfTaskOngoing(latestProductSyncTask) ||
      checkIfTaskPending(latestProductSyncTask)
    ) {
      setActiveProductSyncTask(latestProductSyncTask);
    } else {
      setActiveProductSyncTask(null);
    }
  }, [latestProductSyncTask]);

  useEffect(() => {
    if (checkIfTaskFinished(currentOrgSyncTask)) {
      toast({
        title: `Customers & Suppliers ${
          checkIfTaskSucceeded(currentOrgSyncTask)
            ? "exported"
            : "failed to export"
        } to QuickBooks`,
        status: checkIfTaskSucceeded(currentOrgSyncTask) ? "success" : "error",
        duration: 9000,
        isClosable: true,
      });
      refetchLatestOrgSyncTask();
      setActiveOrgSyncTask(null);
    } else {
      setActiveOrgSyncTask(currentOrgSyncTask);
    }
  }, [currentOrgSyncTask]);

  useEffect(() => {
    if (checkIfTaskFinished(currentProductSyncTask)) {
      toast({
        title: `Products ${
          checkIfTaskSucceeded(currentProductSyncTask)
            ? "exported"
            : "failed to export"
        } to QuickBooks`,
        status: checkIfTaskSucceeded(currentProductSyncTask)
          ? "success"
          : "error",
        duration: 9000,
        isClosable: true,
      });
      refetchLatestProductSyncTask();
      setActiveOrgSyncTask(null);
    } else {
      setActiveOrgSyncTask(currentProductSyncTask);
    }
  }, [currentProductSyncTask]);

  function exportOrganisations() {
    if (!organisationId || !locationId) {
      handleException(
        "No organisation or location selected in QuickBooks exportOrganisations",
      );
      return;
    }
    exportOrganisationsToQuickbooks({
      organisationId,
      locationId,
    })
      .unwrap()
      .then(() => {
        refetchLatestOrgSyncTask();
        toast({
          title: "Exporting to QuickBooks",
          description: "Customers & Suppliers are being exported to QuickBooks",
          status: "info",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error) => {
        const { data } = error;
        let errorTitle = "Error";
        let errorMessage =
          "Failed to export customers and suppliers to QuickBooks";
        if (data.detail?.includes("Authentication Error")) {
          errorTitle = "Authentication Error";
          errorMessage =
            "Please re-authenticate with QuickBooks from the Account Settings page";
          dispatch(setConnectedToQuickbooks(false));
        }
        console.error(error);
        handleException(error);
        toast({
          title: errorTitle,
          description: errorMessage,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  }

  function exportProducts() {
    if (!organisationId || !locationId) {
      handleException(
        "No organisation or location selected in QuickBooks exportProducts",
      );
      return;
    }
    exportProductsToQuickbooks({
      organisationId,
      locationId,
    })
      .unwrap()
      .then(() => {
        refetchLatestProductSyncTask();
        toast({
          title: "Exporting to QuickBooks",
          description: "Products are being exported to QuickBooks",
          status: "info",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error) => {
        const { data } = error;
        let errorTitle = "Error";
        let errorMessage = "Failed to export products to QuickBooks";
        if (data.detail?.includes("Authentication Error")) {
          errorTitle = "Authentication Error";
          errorMessage =
            "Please re-authenticate with QuickBooks from the Account Settings page";
          dispatch(setConnectedToQuickbooks(false));
        }
        console.error(error);
        handleException(error);
        toast({
          title: errorTitle,
          description: errorMessage,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  }

  const getIsDisabled = (task: QuickbooksTask | null) => {
    if (!task) {
      return false;
    }
    if (checkIfTaskOngoing(task)) {
      return (
        getTimeDeltaInMinutes(task.updated_at) < SYNC_TIMEOUT_LIMIT_MINUTES
      );
    }
    if (checkIfTaskPending(task)) {
      return (
        getTimeDeltaInMinutes(task.created_at) < SYNC_TIMEOUT_LIMIT_MINUTES
      );
    }
  };

  const getSyncingOrLastSynced = (task: QuickbooksTask | null) => {
    let message = null;
    if (!task) {
      message = "Never";
    } else {
      if (checkIfTaskOngoing(task)) {
        const timeInMinutes = getTimeDeltaInMinutes(task.updated_at);
        message =
          timeInMinutes > SYNC_TIMEOUT_LIMIT_MINUTES ? (
            "TIMED_OUT - please retry"
          ) : (
            <Spinner />
          );
      } else if (checkIfTaskPending(task)) {
        message = "Pending since ";
        const timeInMinutes = getTimeDeltaInMinutes(task.created_at);
        message += `${getTimeDeltaInWords(task.created_at)} ago`;

        if (timeInMinutes > SYNC_TIMEOUT_LIMIT_MINUTES) {
          message += " - please retry.";
          handleException(
            `Quickbooks Org Sync task is pending for more than 10 minutes:
            task_id=${task.id}, task_type=${task.task_type}, task_status=${task.status},
            task_created_at=${task.created_at}, task_updated_at=${task.updated_at},
            location_id=${locationId}, organisation_id=${organisationId}
            `,
          );
        }
      } else {
        message = formatDateTimeStringToLocal(task.updated_at);
        message += ` - Status: ${task.status}`;
        // message += ` Details: ${JSON.stringify(task.task_detail)}`;
      }
    }
    return <Text>Last synced: {message}</Text>;
  };

  return (
    <>
      <VStack align={"flex-start"}>
        <Box>
          <Button
            onClick={exportOrganisations}
            colorScheme="blue"
            isDisabled={
              getIsDisabled(activeOrgSyncTask || latestOrgSyncTask) ||
              fetchingLatestOrgSyncTask ||
              fetchingCurrentOrgSyncTask
            }
          >
            Export Customers
          </Button>
          {getSyncingOrLastSynced(activeOrgSyncTask || latestOrgSyncTask)}
        </Box>
        <Box>
          <Button
            onClick={exportProducts}
            colorScheme="green"
            isDisabled={
              getIsDisabled(activeProductSyncTask || latestProductSyncTask) ||
              fetchingLatestProductSyncTask ||
              fetchingCurrentProductSyncTask
            }
          >
            Export Products
          </Button>
          {getSyncingOrLastSynced(
            activeProductSyncTask || latestProductSyncTask,
          )}
        </Box>
      </VStack>
    </>
  );
}

function QuickbooksAccountManagement() {
  const { getConnectedToQuickbooks } = userSlice.selectors;
  const connectedToQuickbooks = useAppSelector(getConnectedToQuickbooks);

  useQBOauthPopupListener();

  return (
    <>
      <PageOverview heading={"QuickBooks Account Settings"} />
      <Page isLoading={false}>
        <VStack align={"flex-start"} spacing={4} margin={4}>
          {connectedToQuickbooks && <QuickbooksUserCompanyInfo />}
          <QuickbooksLoginButton />
          {connectedToQuickbooks && <QuickbooksExportPanel />}
        </VStack>
      </Page>
    </>
  );
}

function QuickbooksOauthCallback() {
  const authRequestInitialized = useRef(false);
  const [continueAuthorization, requestState] =
    usePostQuickbooksOauthCallbackMutation();
  const { isLoading, isSuccess, isError, data: responseData } = requestState;

  const toast = useToast();

  function errorToast() {
    toast({
      title: "Error",
      description: "Authentication with QuickBooks failed, please try again.",
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  }

  const { code, realmId, state } = getSearchParams();

  useEffect(() => {
    // ensure this only runs one time
    // suppresses StrictMode development double render
    if (!authRequestInitialized.current) {
      authRequestInitialized.current = true;
      if (code && realmId && state) {
        continueAuthorization({ code: code, realm_id: realmId, state: state });
      } else {
        errorToast();
      }
    }
  }, []);

  useEffect(() => {
    if (isLoading) {
      toast({
        id: "loading_quickbooks_authentication",
        title: "Loading",
        description: "Authenticating with QuickBooks",
        status: "info",
        duration: 9000,
        isClosable: true,
      });
    }

    if (!isLoading) {
      setTimeout(() => toast.close("loading_quickbooks_authentication"), 2000);
    }

    if (isSuccess && responseData) {
      toast({
        title: "Success",
        description: "Authenticated with QuickBooks, Redirecting...",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      const { requesting_url, company } = responseData;

      if (requesting_url) {
        setTimeout(() => {
          window.opener.dispatchEvent(
            new CustomEvent("quickbooks_connected", { detail: { company } }),
          );
          window.close();
        }, 2000);
      }
    }

    if (isError) {
      errorToast();
    }
  }, [isLoading, isSuccess, isError]);

  return (
    <>
      <PageOverview heading={"Authenticating with QuickBooks"} />
      <Box
        w={"100%"}
        h={"100%"}
        display={"flex"}
        justifyContent={"center"}
        justifyItems={"center"}
      >
        <Spinner scale={4} color="white" />
      </Box>
    </>
  );
}
