import { ProductCategory } from "../types.ts";
import { capitalizeWords } from "./stringUtils.ts";

export const productCategoryBadgeColors = (category?: ProductCategory) => {
  switch (category) {
    case ProductCategory.PRODUCE:
      return "green";
    case ProductCategory.FROZEN_FOOD:
      return "cyan";
    case ProductCategory.DAIRY:
      return "blue";
    case ProductCategory.MEAT:
      return "red";
    case ProductCategory.SEAFOOD:
      return "cyan";
    case ProductCategory.DRY_GOODS:
      return "orange";
    case ProductCategory.CANNED_GOODS:
      return "blackAlpha";
    case ProductCategory.CONDIMENTS:
      return "pink";
    case ProductCategory.BAKERY:
      return "purple";
    case ProductCategory.BEVERAGES:
      return "teal";
    case ProductCategory.SNACKS:
      return "yellow";
    case ProductCategory.CONFECTIONERY:
      return "orange";
    case ProductCategory.ALCOHOL:
      return "blackAlpha";
    case ProductCategory.TOBACCO:
      return "gray";
    case ProductCategory.HOUSEHOLD:
      return "cyan";
    case ProductCategory.HEALTH_BEAUTY:
      return "purple";
    case ProductCategory.BABY:
      return "pink";
    case ProductCategory.PETS:
      return "green";
    case ProductCategory.OTHER:
      return "gray";
    default:
      return "gray";
  }
};

export const getProductCategoryLabel = (category?: ProductCategory) => {
  return category
    ? capitalizeWords(category.replace(/_/g, " ").toLowerCase())
    : "";
};
