import React, { useEffect, useState } from "react";
import PageOverview from "../components/PageOverview/PageOverview";
import { useAppSelector } from "../redux/hooks";
import { userSlice } from "../redux/userSlice";
import {
  useGetBuyerLocationsQuery,
  useGetBuyersQuery,
  useGetInventoryItemsQuery,
  useGetPricesQuery,
  useGetProductsQuery,
  useGetProductUnitsQuery,
} from "../redux/apiSlice";
import {
  Badge,
  HStack,
  InputGroup,
  InputLeftElement,
  Td,
  useDisclosure,
} from "@chakra-ui/react";
import DataTabs from "../components/DataTabs/DataTabs";
import { Organisation, Price, ProductUnitAndPrices } from "../types";
import { badgeColors, getProductUnitLabel } from "../utils/productUnitUtils";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "../components/Autocomplete";
import { SearchIcon } from "@chakra-ui/icons";
import AddProductModal from "../feature/ProductView/AddProductModal";
import InventoryProductSearch from "../components/InventoryProductSearch/InventoryProductSearch";
import InteractiveRow from "../components/InteractiveRow/InteractiveRow";
import PriceViewEntry from "../feature/ProductView/PriceViewEntry";
import Page from "../components/Page/Page";
import { skipToken } from "@reduxjs/toolkit/query";

function PricesPage(): React.ReactElement {
  const { getCurrentOrganisationId, getCurrentLocationId } =
    userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const locationId = useAppSelector(getCurrentLocationId);

  // const [productList, setProductList] = useState<Product[]>([]);
  const [productUnitAndPricesList, setProductUnitAndPricesList] = useState<
    ProductUnitAndPrices[]
  >([]);
  const [productUnitIdToPriceMap, setProductUnitIdToPriceMap] = useState<
    Map<number, Price[]>
  >(new Map());
  const [productFilterId, setProductFilterId] = useState<
    number | null | undefined
  >(undefined);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [customerFilter, setCustomerFilter] = useState<string | undefined>(
    undefined,
  );
  const [showCustomerFilter, setShowCustomerFilter] = useState<boolean>(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    data: inventoryItems,
    isLoading: isLoadingInventoryItems,
    error: errorInventoryItems,
  } = useGetInventoryItemsQuery(
    {
      organisationId,
    },
    { skip: !organisationId },
  );

  const {
    data: productUnits,
    isLoading: isLoadingProductUnits,
    error: errorProductUnits,
  } = useGetProductUnitsQuery("");
  const {
    data: products,
    isLoading: isLoadingProducts,
    error: errorProducts,
  } = useGetProductsQuery("");

  const {
    data: prices,
    isLoading: isLoadingPrices,
    error: errorPrices,
  } = useGetPricesQuery(organisationId ?? skipToken);

  const {
    data: buyers,
    isLoading: isLoadingBuyers,
    error: errorBuyers,
  } = useGetBuyersQuery(
    { organisationId, locationId },
    { skip: !organisationId },
  );

  const { data: buyerLocations } = useGetBuyerLocationsQuery(
    { organisationId, locationId },
    { skip: !organisationId || !locationId },
  );

  // useEffect(() => {
  //   if (
  //     inventoryItems &&
  //     inventoryItems.length > 0 &&
  //     products &&
  //     products.length > 0
  //   ) {
  //     const productIds = new Set(inventoryItems.map((item) => item.product_id));
  //     setProductList(
  //       products
  //         .slice()
  //         .sort((a, b) => a.name.localeCompare(b.name))
  //         .filter((product) => productIds.has(product.id)),
  //     );
  //   }
  // }, [inventoryItems, products]);

  useEffect(() => {
    if (inventoryItems && productUnits && prices && products) {
      const productUnitIds = inventoryItems.map((item) => item.product_unit_id);
      let inventoryUnits = productUnits.filter((unit) =>
        productUnitIds.includes(unit.id),
      );

      // const productIds = new Set(inventoryItems.map((item) => item.product_id));
      // let filteredProducts = products
      //   .slice()
      //   .sort((a, b) => a.name.localeCompare(b.name))
      //   .filter((product) => productIds.has(product.id));

      if (productFilterId) {
        inventoryUnits = inventoryUnits.filter(
          (unit) => unit.product_id === productFilterId,
        );
        // filteredProducts = filteredProducts.filter(
        //   (product) => product.id === productFilterId,
        // );
      }
      // setProductList(filteredProducts);
      let filteredPrices = prices;
      if (customerFilter && customerFilter.length > 0) {
        if (customerFilter === "Default") {
          filteredPrices = prices.filter(
            (price) => !price.buyer_organisation_id,
          );
        } else {
          filteredPrices = prices.filter(
            (price) =>
              buyers?.find((buyer) => buyer.name === customerFilter)?.id ===
                price.buyer_organisation_id || !price.buyer_organisation_id,
          );
        }
      }
      // TODO: test this
      setProductUnitIdToPriceMap(
        filteredPrices.reduce((map, price) => {
          const prices = map.get(price.product_unit_id) || [];
          prices.push(price);
          map.set(price.product_unit_id, prices);
          return map;
        }, new Map<number, Price[]>()),
      );
      setProductUnitAndPricesList(
        inventoryUnits.map((unit) => {
          const price = prices.find(
            (price) => price.product_unit_id === unit.id,
          );
          return {
            product_unit: unit,
            current_price: price?.price || 1.1,
            supplier_price: (price?.price || 1.1) * (Math.random() * 0.3 + 0.6),
          };
        }),
      );
    }
  }, [inventoryItems, productUnits, prices, productFilterId, customerFilter]);

  return (
    <>
      <PageOverview heading={"Prices"} subheading={""} />
      <Page
        isLoading={
          isLoadingInventoryItems ||
          isLoadingProductUnits ||
          isLoadingPrices ||
          isLoadingProducts ||
          isLoadingBuyers
        }
        isError={Boolean(
          errorInventoryItems ||
            errorProductUnits ||
            errorPrices ||
            errorProducts ||
            errorBuyers,
        )}
      >
        <>
          <HStack>
            <InventoryProductSearch
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              setProductFilterId={setProductFilterId}
              includeSearchIcon
            />
            {showCustomerFilter && (
              <AutoComplete openOnFocus>
                <HStack>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color="gray.300"
                      fontSize="1.2em"
                    >
                      <SearchIcon />
                    </InputLeftElement>
                    <AutoCompleteInput
                      onItemSelect={(value) => setCustomerFilter(value)}
                      placeholder={"Filter by customer"}
                      onChange={(e) => {
                        if (e.target.value.length === 0) {
                          setProductFilterId(undefined);
                        }
                      }}
                    />
                  </InputGroup>
                  <AutoCompleteList>
                    <AutoCompleteItem
                      id={"option-default"}
                      key={"option-default"}
                      value={"Default prices"}
                      textTransform="capitalize"
                      onClick={() => setCustomerFilter("Default")}
                    >
                      Default prices
                    </AutoCompleteItem>
                    {buyers?.map((buyer: Organisation) => (
                      <AutoCompleteItem
                        id={`option-${buyer.id}`}
                        key={`option-${buyer.id}`}
                        value={buyer.name}
                        textTransform="capitalize"
                        onClick={() => setCustomerFilter(buyer.name)}
                      >
                        {buyer.name}
                      </AutoCompleteItem>
                    ))}
                  </AutoCompleteList>
                </HStack>
              </AutoComplete>
            )}
          </HStack>
          <AddProductModal isOpen={isOpen} onClose={onClose} />
          <DataTabs
            onChange={(e) => setShowCustomerFilter(Boolean(e))}
            showButton={false}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            data={[
              {
                label: "All Prices",
                buttonLabel: "Add Price",
                headers: [
                  "Name",
                  "Unit",
                  "Price List",
                  "MOQ",
                  "Cost per Unit",
                  "Sales Price per Unit",
                  "Profit per Unit",
                ],
                entries: productUnitAndPricesList
                  ? productUnitAndPricesList.map((item, index) => (
                      <>
                        <PriceViewEntry
                          key={index}
                          itemIndex={index}
                          item={item}
                          prices={
                            productUnitIdToPriceMap.get(item.product_unit.id) ||
                            []
                          }
                          buyers={buyerLocations || []}
                        >
                          <Td fontSize="sm">
                            {item.product_unit.product_name}
                          </Td>
                          <Td fontSize="sm">
                            {getProductUnitLabel(item.product_unit)}
                          </Td>
                          <Td fontSize="sm"></Td>
                          <Td fontSize="sm"></Td>
                          <Td fontSize="sm"></Td>
                          <Td fontSize="sm"></Td>
                          <Td fontSize="sm"></Td>
                        </PriceViewEntry>
                        {productUnitIdToPriceMap
                          .get(item.product_unit.id)
                          ?.map((price, index) => (
                            <InteractiveRow key={index}>
                              <Td fontSize="sm" paddingLeft={"4rem"}>
                                ------
                              </Td>
                              <Td fontSize="sm" paddingLeft={"4rem"}>
                                ------
                              </Td>
                              <Td fontSize="sm">
                                <Badge
                                  colorScheme={
                                    price.buyer_organisation_id
                                      ? badgeColors[
                                          price.buyer_organisation_id % 8
                                        ]
                                      : "green"
                                  }
                                >
                                  {buyers?.find(
                                    (buyer) =>
                                      buyer.id === price.buyer_organisation_id,
                                  )?.name || "Default"}
                                </Badge>
                              </Td>
                              <Td fontSize="sm">
                                {price.minimum_order_quantity}
                              </Td>
                              <Td fontSize="sm">
                                ${Number(item.supplier_price).toFixed(2)}
                              </Td>
                              <Td fontSize="sm">
                                ${Number(price.price).toFixed(2)}
                              </Td>
                              <Td fontSize="sm">
                                $
                                {(
                                  Number(price.price) -
                                  Number(item.supplier_price)
                                ).toFixed(2)}{" "}
                                (
                                {(
                                  (Number(price.price) /
                                    Number(item.supplier_price)) *
                                    100 -
                                  100
                                ).toFixed(2)}
                                %)
                              </Td>
                            </InteractiveRow>
                          ))}
                      </>
                    ))
                  : [],
              },
            ]}
          />
        </>
      </Page>
    </>
  );
}

export default PricesPage;
