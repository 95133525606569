import React, { useEffect, useState } from "react";
import PageOverview from "../components/PageOverview/PageOverview";
import PurchaseOrdersView from "../feature/OrdersView/PurchaseOrdersView";
import { filterDatesSlice } from "../redux/filterDatesSlice";
import { useAppSelector } from "../redux/hooks";
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { PlusSquareIcon } from "@chakra-ui/icons";
import OrderListModal from "../feature/OrdersView/OrderListModal.tsx";
import { useGetCurrentUserQuery } from "../redux/apiSlice.ts";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";
import { ImportPricesModal } from "../feature/OrdersView/PriceImportModule";

function PurchaseOrdersPage(): React.ReactElement {
  const { getStartDate, getEndDate } = filterDatesSlice.selectors;
  const startDate = useAppSelector(getStartDate);
  const endDate = useAppSelector(getEndDate);

  const { data: currentUser } = useGetCurrentUserQuery("");

  const [isUserAdmin, setIsUserAdmin] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const initialTab = searchParams.get("tab") || "orders";

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isImportPricesModalOpen,
    onOpen: onImportPricesModalOpen,
    onClose: onImportPricesModalClose,
  } = useDisclosure();

  useEffect(() => {
    if (!searchParams.get("tab")) {
      setSearchParams({ tab: "orders" });
    }
  }, [searchParams, setSearchParams]);

  const getTabIndex = (tab: string) => {
    switch (tab) {
      case "invoices":
        return 1;
      case "orders":
      default:
        return 0;
    }
  };

  const tabIndex = getTabIndex(initialTab);

  const handleTabChange = (index: number) => {
    const newTab = index === 0 ? "orders" : "invoices";
    setSearchParams({ tab: newTab });
  };

  useEffect(() => {
    if (currentUser && currentUser.organisation_id === 1) {
      setIsUserAdmin(true);
    }
  }, [currentUser]);

  return (
    <>
      <PageOverview
        heading={"Purchases"}
        subheading={startDate && endDate && `${startDate} - ${endDate}`}
        showDateRangePicker={true}
        endElement={
          <>
            {initialTab === "orders" && (
              <Flex
                gap={2}
                alignItems={"end"}
                flexDirection={["column", "row"]}
              >
                <Tooltip
                  label="Create Order List"
                  aria-label="Create Order List"
                >
                  <Button
                    aria-label={"Create Order List"}
                    colorScheme={"teal"}
                    onClick={onOpen}
                  >
                    <PlusSquareIcon mr={2} />
                    Create Order List
                  </Button>
                </Tooltip>
                {isUserAdmin && ( // TODO: replace with feature flag
                  <Menu>
                    <MenuButton as={Button}>
                      <HiOutlineDotsVertical />
                    </MenuButton>
                    <MenuList textColor={"black"}>
                      <MenuItem onClick={onImportPricesModalOpen}>
                        Import Supplier Prices
                      </MenuItem>
                    </MenuList>
                  </Menu>
                )}
              </Flex>
            )}
          </>
        }
      />
      <PurchaseOrdersView setTabIndex={handleTabChange} tabIndex={tabIndex} />
      <OrderListModal isOpen={isOpen} onClose={onClose} />
      {isImportPricesModalOpen && (
        <ImportPricesModal
          isOpen={isImportPricesModalOpen}
          onClose={onImportPricesModalClose}
        />
      )}
    </>
  );
}

export default PurchaseOrdersPage;
