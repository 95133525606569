import { createSlice } from "@reduxjs/toolkit";
import { QuickbooksCompanyInfo } from "../types";

const parsePotentialUndefined = (itemKey: any) => {
  try {
    const value = localStorage.getItem(itemKey);
    if (value === "undefined" || value === "null" || !value) {
      return null;
    }
    return JSON.parse(value);
  } catch (e) {
    console.error(`Error parsing ${itemKey} from local storage: ${e}`);
    return null;
  }
};

export const userSlice = createSlice({
  name: "user",
  initialState: {
    token: localStorage.getItem("token"),
    isAuthenticated: JSON.parse(
      localStorage.getItem("isAuthenticated") || "false",
    ),
    userId: parsePotentialUndefined("userId"),
    organisation: parsePotentialUndefined("organisation"),
    organisationId: parsePotentialUndefined("organisationId"),
    location: parsePotentialUndefined("location"),
    locationId: parsePotentialUndefined("locationId"),
    isChangingLocation: false,
    isLoggingIn: false,
    hasSettings: true,
    settingsOpen: false,
    connectedToQuickbooks: parsePotentialUndefined("connectedToQuickbooks"),
    quickbooksUserCompany: parsePotentialUndefined("quickbooksUserCompany"),
  },
  reducers: {
    setIsLoggingIn: (state, action) => {
      state.isLoggingIn = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem("token", action.payload);
      state.isAuthenticated = true;
      localStorage.setItem("isAuthenticated", "true");
    },
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
      localStorage.setItem("userId", action.payload);
    },
    setOrganisation: (state, action) => {
      if (action.payload && !isNaN(Number(action.payload.id))) {
        state.organisation = action.payload;
        state.organisationId = action.payload.id;
        localStorage.setItem("organisationId", action.payload.id);
        localStorage.setItem("organisation", JSON.stringify(action.payload));
      }
    },
    setLocation: (state, action) => {
      if (action.payload && !isNaN(Number(action.payload.id))) {
        state.location = action.payload;
        state.locationId = action.payload.id;
        localStorage.setItem("locationId", action.payload.id);
        localStorage.setItem("location", JSON.stringify(action.payload));
      }
    },
    setChangingLocation: (state, action) => {
      state.isChangingLocation = action.payload;
    },
    login: (state, action) => {
      state.token = action.payload.token.access_token;
      state.isAuthenticated = true;
      state.userId = action.payload.user.id;
      state.organisation = action.payload.organisation;
      state.location = action.payload.location;
      state.organisationId = action.payload.organisation.id;
      state.locationId = action.payload.location.id;
      localStorage.setItem("token", action.payload.token.access_token);
      localStorage.setItem("userId", action.payload.user.id);
      localStorage.setItem("organisationId", action.payload.organisation.id);
      localStorage.setItem(
        "locationId",
        action.payload.location_id || action.payload.location.id,
      );
      localStorage.setItem("isAuthenticated", "true");
      localStorage.setItem("location", JSON.stringify(action.payload.location));
      localStorage.setItem(
        "organisation",
        JSON.stringify(action.payload.organisation),
      );
    },
    logout: (state) => {
      state.token = "";
      state.isAuthenticated = false;
      state.userId = "";
      state.organisation = null;
      state.location = null;
      state.organisationId = "";
      state.locationId = "";
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("organisationId");
      localStorage.removeItem("locationId");
      localStorage.removeItem("location");
      localStorage.removeItem("organisation");
      localStorage.setItem("isAuthenticated", "false");
    },
    toggleSettings: (state) => {
      state.settingsOpen = !state.settingsOpen;
    },
    setConnectedToQuickbooks: (state, action) => {
      state.connectedToQuickbooks = action.payload;
      localStorage.setItem(
        "connectedToQuickbooks",
        JSON.stringify(action.payload),
      );
    },
    setQuickbooksUserCompany: (state, action) => {
      state.quickbooksUserCompany = action.payload;
      localStorage.setItem(
        "quickbooksUserCompany",
        JSON.stringify(action.payload),
      );
    },
  },
  selectors: {
    getIsLoggingIn: (state): boolean => state.isLoggingIn,
    getToken: (state): string => (state.token ? state.token : ""),
    getIsAuthenticated: (state): boolean => state.isAuthenticated,
    getUserId: (state): number => (state.userId ? state.userId : ""),
    getCurrentOrganisation: (state) => state.organisation,
    getCurrentOrganisationId: (state): number | undefined =>
      state.organisationId ? state.organisationId : state.organisation?.id,
    getCurrentLocation: (state) => state.location,
    getCurrentLocationId: (state): string | undefined =>
      state.locationId ? state.locationId : state.location?.id,
    getIsChangingLocation: (state): boolean => state.isChangingLocation,
    getSettingsOpen: (state): boolean => state.settingsOpen,
    getHasSettings: (state): boolean => state.hasSettings,
    getConnectedToQuickbooks: (state): boolean => state.connectedToQuickbooks,
    getQuickbooksUserCompany: (state): QuickbooksCompanyInfo =>
      state.quickbooksUserCompany,
  },
});

export const {
  setIsLoggingIn,
  setOrganisation,
  setLocation,
  setChangingLocation,
  setToken,
  logout,
} = userSlice.actions;
