import * as React from "react";
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  useDisclosure,
} from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  useGetInvoicesQuery,
  useGetOrdersQuery,
  useGetPurchaseInvoiceByIdQuery,
  useGetPurchaseOrderByIdQuery,
  useGetSuppliersQuery,
} from "../../redux/apiSlice";
import OrderViewEntry from "./OrderViewEntry";
import { filterDatesSlice } from "../../redux/filterDatesSlice";
import { useAppSelector } from "../../redux/hooks";
import debounce from "../../utils/debounce";
import { userSlice } from "../../redux/userSlice";
import DataTabs from "../../components/DataTabs/DataTabs";
import InvoiceViewEntry from "./InvoiceViewEntry";
import OrderModal from "./OrderModal";
import { Invoice, Order } from "../../types.ts";
import OrderStatusFilter from "../../components/OrderStatusFilter/OrderStatusFilter.tsx";
import CustomerSearch from "../../components/CustomerSearch/CustomerSearch.tsx";
import { SearchIcon } from "@chakra-ui/icons";

const PurchaseOrdersView = ({
  tabIndex,
  setTabIndex,
}: {
  tabIndex: number;
  setTabIndex: (index: number) => void;
}): React.ReactElement => {
  const { getStartDate, getEndDate } = filterDatesSlice.selectors;
  const startDate = useAppSelector(getStartDate);
  const endDate = useAppSelector(getEndDate);
  const { getCurrentLocationId, getCurrentOrganisationId } =
    userSlice.selectors;
  const locationId = useAppSelector(getCurrentLocationId);
  const organisationId = useAppSelector(getCurrentOrganisationId);

  const [searchById, setSearchById] = useState<number | null>(null);
  const [idSearchValue, setIdSearchValue] = useState<string>("");
  const searchTimeout = useRef<NodeJS.Timeout | null>(null);

  const {
    data: orders,
    error: ordersError,
    isLoading: ordersLoading,
  } = useGetOrdersQuery({ organisationId, locationId, startDate, endDate });

  const {
    data: suppliers,
    error: suppliersError,
    isLoading: suppliersLoading,
  } = useGetSuppliersQuery({ organisationId, locationId });

  const {
    data: invoices,
    error: invoicesError,
    isLoading: invoicesLoading,
  } = useGetInvoicesQuery({ organisationId, locationId, startDate, endDate });

  const { data: orderByID, isLoading: ordersLoadingByID } =
    useGetPurchaseOrderByIdQuery(
      {
        orderId: searchById ?? 0,
        organisationId,
        locationId,
      },
      { skip: tabIndex === 1 || !organisationId || !locationId || !searchById },
    );

  const { data: invoiceByID, isLoading: invoiceLoadingByID } =
    useGetPurchaseInvoiceByIdQuery(
      {
        invoiceId: searchById ?? 0,
        organisationId,
        locationId,
      },
      { skip: tabIndex === 0 || !searchById || !organisationId || !locationId },
    );

  const [filterOrders, setFilterOrders] = useState<boolean>(true);
  const [ordersList, setOrdersList] = useState<Order[]>([]);
  const [invoicesList, setInvoicesList] = useState<Invoice[]>([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedCustomerOrgId, setSelectedCustomerOrgId] = useState<
    number | null | undefined
  >(null);

  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([
    "PENDING",
    "PROCESSING",
  ]);

  const filterOrdersByStatus = useCallback(
    (orders: Order[]) => {
      if (selectedStatuses.length === 0) {
        return orders;
      }
      return orders.filter((order) =>
        selectedStatuses.includes(order?.status || ""),
      );
    },
    [selectedStatuses],
  );

  const getSupplierName = (supplierId: number) => {
    return (
      suppliers?.find((supplier) => supplier.id === supplierId)?.name || ""
    );
  };
  const debouncedSetFilterOrders = debounce(
    () => setFilterOrders(!filterOrders),
    300,
  );

  useEffect(() => {
    if (searchById) {
      if (tabIndex === 0) {
        return setOrdersList(
          orderByID && Number(orderByID.id) === Number(searchById)
            ? [orderByID]
            : [],
        );
      } else {
        return setInvoicesList(
          invoiceByID && Number(invoiceByID.id) === Number(searchById)
            ? [invoiceByID]
            : [],
        );
      }
    }

    if (orders) {
      let ordersList = orders.slice();
      if (selectedCustomerOrgId || filterOrders) {
        ordersList = ordersList.filter((order) => {
          if (selectedCustomerOrgId) {
            return order.organisation_id === selectedCustomerOrgId;
          }
          return true;
        });
      }
      ordersList = filterOrdersByStatus(ordersList);
      setOrdersList(ordersList);
    } else {
      setOrdersList([]);
    }
    if (invoices) {
      let invoicesList = invoices.slice();
      if (selectedCustomerOrgId) {
        invoicesList = invoicesList.filter(
          (invoice) => invoice.organisation_id === selectedCustomerOrgId,
        );
      }
      setInvoicesList(invoicesList);
    } else {
      setInvoicesList([]);
    }
  }, [
    orders,
    invoices,
    filterOrders,
    selectedCustomerOrgId,
    searchById,
    orderByID,
    invoiceByID,
    filterOrdersByStatus,
  ]);

  return (
    <Box
      p={2}
      my={10}
      mx={[0, 0, 10]}
      bg={"gray.200"}
      border="1px solid"
      borderColor="gray.400"
      rounded="md"
      boxShadow="lg"
      overflow="auto"
      minH="300px"
    >
      {isOpen && <OrderModal isOpen={isOpen} onClose={onClose} />}
      <DataTabs
        onChange={setTabIndex}
        tabIndex={tabIndex}
        filterApplied={filterOrders}
        setFilterApplied={debouncedSetFilterOrders}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        hideTabs
        searchElements={
          <>
            <CustomerSearch
              includeSearchIcon
              placeholder={"Filter by customer"}
              setCustomerOrganisationId={setSelectedCustomerOrgId}
            />
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                color="gray.300"
                fontSize="1.2em"
              >
                <SearchIcon />
              </InputLeftElement>
              <Input
                placeholder={`Search for an ${
                  tabIndex === 0 ? "order" : "invoice"
                } ID`}
                type="number"
                defaultValue={idSearchValue ?? ""}
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  setIdSearchValue(e.target.value);
                  setSearchById(isNaN(value) ? null : value);
                  if (isNaN(value)) {
                    clearTimeout(searchTimeout.current as NodeJS.Timeout);
                    setSearchById(null);
                  } else {
                    clearTimeout(searchTimeout.current as NodeJS.Timeout);
                    searchTimeout.current = setTimeout(
                      () => setSearchById(value),
                      300,
                    );
                  }
                }}
              />
            </InputGroup>
          </>
        }
        data={[
          {
            label: "Orders",
            buttonLabel: "Add Purchase Order",
            headers: [
              "Order ID",
              "Supplier",
              "Order Date",
              "Requested For",
              "Status",
              "Value",
            ],
            showFilter: false,
            customFilter: (
              <OrderStatusFilter
                selectedStatuses={selectedStatuses}
                setSelectedStatuses={setSelectedStatuses}
              />
            ),
            filterLabel: "Filter delivered orders",
            isLoading: ordersLoadingByID || ordersLoading || suppliersLoading,
            entries:
              ordersList && suppliers
                ? ordersList
                    .slice()
                    .sort((a, b) => b.id - a.id)
                    .map((order, index) => (
                      <OrderViewEntry
                        key={index}
                        itemIndex={index}
                        order={order}
                        organisations={suppliers}
                        supplierOrCustomerName={getSupplierName(
                          order.supplier_organisation_id,
                        )}
                      />
                    ))
                : [],
          },
          {
            label: "Invoices",
            headers: [
              "Invoice ID",
              "Order ID",
              "Supplier",
              "Invoice Date",
              "Delivery Date",
              "Status",
              "Total",
              "Credit",
              "Balance",
              "Paid",
              "Due",
            ],
            hideButton: true,
            isLoading:
              invoiceLoadingByID || invoicesLoading || suppliersLoading,
            entries: invoicesList
              ? invoicesList
                  .slice()
                  .sort((a, b) => b.id - a.id)
                  .map((invoice, index) => (
                    <InvoiceViewEntry
                      key={index}
                      itemIndex={index}
                      invoice={invoice}
                      supplierOrCustomerName={getSupplierName(
                        invoice.supplier_organisation_id,
                      )}
                    />
                  ))
              : [],
          },
        ]}
      />
      {ordersError && <div>Failed to load orders</div>}
      {suppliersError && <div>Failed to load suppliers</div>}
      {invoicesError && <div>Failed to load invoices</div>}
      {(ordersLoading || suppliersLoading || invoicesLoading) && (
        <div>Loading...</div>
      )}
    </Box>
  );
};

export default PurchaseOrdersView;
