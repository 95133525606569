import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  useColorModeValue,
  Spinner,
  useMediaQuery,
} from "@chakra-ui/react";
import { userSlice } from "../redux/userSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  useGetCurrentUserQuery,
  useGetLocationsQuery,
  useGetOrganisationsQuery,
  usePostRefreshTokenMutation,
} from "../redux/apiSlice";
import { jwtDecode } from "jwt-decode";
import * as Sentry from "@sentry/react";
import { routes } from "../index";
import {
  checkIsHarvestPath,
  checkIsLoginPath,
  checkIsProductPath,
  checkIsPublicPath,
  checkPath,
} from "../utils/routerUtils";
import { Navbar } from "./PublicSite/Navbar";
import AppNavBar from "../components/AppNavBar";
import SettingsModal from "../components/SettingsModal/SettingsModal";

export default function Root(): React.ReactElement {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const navLocation = useLocation();
  const token = useAppSelector(userSlice.selectors.getToken);
  const isAuthenticated = useAppSelector(
    userSlice.selectors.getIsAuthenticated,
  );

  const {
    getCurrentLocationId,
    getCurrentOrganisationId,
    getIsChangingLocation,
    getCurrentLocation,
    getSettingsOpen,
  } = userSlice.selectors;

  const currentLocationId = useAppSelector(getCurrentLocationId);
  const currentOrganisationId = useAppSelector(getCurrentOrganisationId);
  const isChangingLocation = useAppSelector(getIsChangingLocation);
  const currentLocation = useAppSelector(getCurrentLocation);
  const settingsOpen = useAppSelector(getSettingsOpen);

  const { data: organisations = [], isLoading: isLoadingOrganisations } =
    useGetOrganisationsQuery("", { skip: !isAuthenticated });
  const { data: locations, isLoading: isLoadingLocations } =
    useGetLocationsQuery("", { skip: !isAuthenticated });

  const { data: currentUser, error: currentUserError } = useGetCurrentUserQuery(
    "",
    { skip: !isAuthenticated },
  );

  const [postRefreshToken, { error, isLoading }] =
    usePostRefreshTokenMutation();

  const isLoginPath = checkIsLoginPath(navLocation);

  const currentRoute = routes.find((route) =>
    checkPath(navLocation, route.path),
  );

  const navigateToLogin = () => {
    if (!isLoginPath) {
      navigate("/login");
    }
  };

  const navigateToDashboard = () => {
    // TODO: temp for release to Klippers, update when dashboard is available
    if (currentLocation && currentLocation.is_producer) {
      if (!checkIsHarvestPath(navLocation)) {
        navigate("/harvest");
      }
    } else {
      if (!checkIsProductPath(navLocation)) {
        navigate("/products");
      }
    }
  };

  const handleTokenExpiryChecks = () => {
    let authenticated = isAuthenticated;
    let authToken = token.length > 0;
    if (token && isAuthenticated) {
      const decodedToken: { exp: number } = jwtDecode(token);
      const currentDate = new Date();

      // JWT exp is in seconds
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        authenticated = false;
        authToken = false;
      }
    }

    const shouldLogin = !authToken || !authenticated;

    if (shouldLogin && !checkIsPublicPath(navLocation)) {
      dispatch(userSlice.actions.logout());
      navigateToLogin();
    }

    return shouldLogin;
  };

  const shouldRefreshToken = () => {
    // refresh token if 10 mins old
    if (token && isAuthenticated) {
      const decodedToken: { ts: number } = jwtDecode(token);
      return decodedToken.ts * 1000 < new Date().getTime() - 600000;
    }
    return true;
  };

  useEffect(() => {
    const shouldLogin = handleTokenExpiryChecks();
    if (shouldLogin && checkIsPublicPath(navLocation)) {
      return;
    }
    if (!shouldLogin && isLoginPath) {
      navigateToDashboard();
    }

    if (token && token.length > 0 && !error && !isLoading) {
      if (!shouldRefreshToken()) return;
      postRefreshToken("")
        .unwrap()
        .then((response) => {
          dispatch(userSlice.actions.setToken(response.access_token)); // sets isAuthenticated to true
          if (isLoginPath) {
            navigateToDashboard();
            // toast({
            //   title: "Already logged in. Redirected to dashboard.",
            //   status: "info",
            //   duration: 3000,
            //   isClosable: true,
            // });
          }
        })
        .catch((error) => {
          try {
            Sentry.captureException(JSON.stringify(error));
          } catch {
            Sentry.captureException(error);
          }
          if (!isLoginPath) {
            dispatch(userSlice.actions.logout());
            navigateToLogin();
          }
        });
    }
  }, [navLocation, token, isAuthenticated]);

  useEffect(() => {
    if (currentUserError) {
      Sentry.captureException(currentUserError);
    }
  }, [currentUserError]);

  useEffect(() => {
    if (
      isAuthenticated &&
      (!currentLocation ||
        !currentLocationId ||
        isNaN(Number(currentLocationId))) &&
      currentUser
    ) {
      let firstLocation = locations?.filter(
        (location) => location.id === currentUser.location_id,
      )?.[0];
      if (!firstLocation) {
        firstLocation = locations?.filter(
          (location) =>
            location.organisation_id === currentUser.organisation_id,
        )?.[0];
        dispatch(userSlice.actions.setLocation(firstLocation));
      }
      const organisation = organisations?.filter(
        (organisation) => organisation.id === currentUser.organisation_id,
      )?.[0];
      dispatch(userSlice.actions.setOrganisation(organisation));
    }
  }, [isAuthenticated, currentLocationId, currentUser]);

  useEffect(() => {
    if (currentUser && currentLocationId && currentOrganisationId) {
      Sentry.setUser({
        ...currentUser,
        location_id: currentLocationId,
        organisation_id: currentOrganisationId,
      });
    }
  }, [currentUser, currentLocationId, currentOrganisationId]);

  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const isPublicPath = checkIsPublicPath(navLocation);

  return (
    <>
      <div
        style={{ width: "100%", position: "sticky", top: 0, zIndex: 1 }}
        onClick={handleTokenExpiryChecks}
      >
        <Box bg={useColorModeValue("gray.100", "gray.900")}>
          {currentRoute?.usePublicSiteNavbar ? (
            <Navbar />
          ) : (
            isAuthenticated && <AppNavBar />
          )}
        </Box>
      </div>

      <Box
        id="page-wrap"
        pl={isMobile ? 0 : isPublicPath ? 0 : "60px"}
        pt={isMobile ? "50px" : 0}
      >
        <div id="detail" onClick={handleTokenExpiryChecks}>
          {isLoadingOrganisations ||
          isLoadingLocations ||
          isChangingLocation ? (
            <Spinner />
          ) : (
            <>
              <Outlet />
              {settingsOpen && (
                <SettingsModal
                  isOpen={settingsOpen}
                  onClose={() => dispatch(userSlice.actions.toggleSettings())}
                />
              )}
            </>
          )}
        </div>
      </Box>
    </>
  );
}
