import { Barcode, Product, ProductUnit } from "../../types.ts";
import {
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Spacer,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getProductUnitLabel } from "../../utils/productUnitUtils.ts";
import { BarcodeScanInput } from "../../components/BarcodeScanInput/BarcodeScanInput.tsx";
import { CheckIcon } from "@chakra-ui/icons";
import { IoIosSave } from "react-icons/io";
import { usePutBarcodeListMutation } from "../../redux/apiSlice";
import { getBarcodeType } from "../../utils/barcodeUtils";

interface UnitAndCaseBarcodeScanProps {
  product: Product;
  unit: ProductUnit;
  parentUnit: ProductUnit | undefined;
}

const UnitAndCaseBarcodeScan: React.FC<UnitAndCaseBarcodeScanProps> = ({
  product,
  unit,
  parentUnit,
}) => {
  const toast = useToast();

  const [
    putBarcodeList,
    { isLoading: isPuttingBarcode, error: putBarcodeListError },
  ] = usePutBarcodeListMutation();

  const [unitBarcode, setUnitBarcode] = useState<string>(
    unit.barcode?.code || "",
  );
  const [parentBarcode, setParentBarcode] = useState<string>(
    parentUnit?.barcode?.code || "",
  );
  const [hasEdited, setHasEdited] = useState<boolean>(false);

  useEffect(() => {
    setHasEdited(false);
  }, [unit, parentUnit]);

  const handleSave = () => {
    if (!hasEdited || isPuttingBarcode) return;
    const barcodes: Barcode[] = [
      {
        id: unit.barcode?.id,
        product_id: product.id,
        product_unit_id: unit.id,
        code: unitBarcode,
        code_type: getBarcodeType(unitBarcode) || "EAN-13",
      },
    ];
    if (parentUnit && parentBarcode && parentBarcode.length) {
      barcodes.push({
        id: parentUnit?.barcode?.id,
        product_id: product.id,
        product_unit_id: parentUnit.id,
        code: parentBarcode,
        code_type: getBarcodeType(parentBarcode) || "EAN-13",
      });
    }
    putBarcodeList({
      barcodes,
    })
      .unwrap()
      .then(() => {
        toast({
          title: "Barcode saved",
          status: "success",
        });
        setHasEdited(false);
      })
      .catch((error) => {
        let errorMessage = "Invalid barcode";
        if (error.data && error.data.detail?.length) {
          try {
            errorMessage = error.data.detail.map((e) => e.msg).join(", ");
          } catch (e) {
            errorMessage = error.data.detail;
          }
        }
        toast({
          title: "Failed to save barcode: " + errorMessage,
          status: "error",
        });
      });
  };

  return (
    <Flex direction={"row"} gap={8}>
      <FormControl>
        <FormLabel>{getProductUnitLabel(unit)}</FormLabel>
        <BarcodeScanInput
          value={unitBarcode}
          onChange={(barcode) => {
            setHasEdited(true);
            setUnitBarcode(barcode);
          }}
        />
      </FormControl>
      {parentUnit ? (
        <>
          {/*<FormControl>*/}
          {/*  <FormLabel>Generate</FormLabel>*/}
          {/*  <HStack>*/}
          {/*    <IconButton size={"xs"} aria-label={"Generate from unit"} icon={<FaArrowCircleRight />} />*/}
          {/*    <IconButton size={"xs"} aria-label={"Generate from case"} icon={<FaArrowCircleLeft />} />*/}
          {/*  </HStack>*/}

          {/*</FormControl>*/}
          <FormControl>
            <FormLabel>{getProductUnitLabel(parentUnit)}</FormLabel>
            <BarcodeScanInput
              value={parentBarcode}
              onChange={(barcode) => {
                setHasEdited(true);
                setParentBarcode(barcode);
              }}
            />
          </FormControl>
        </>
      ) : (
        <Spacer />
      )}
      <FormControl>
        <FormLabel>Actions</FormLabel>
        <IconButton
          size={"sm"}
          colorScheme={hasEdited ? "gray" : "teal"}
          aria-label="Save"
          icon={hasEdited ? <IoIosSave /> : <CheckIcon />}
          onClick={handleSave}
          isDisabled={!hasEdited}
        />
      </FormControl>
    </Flex>
  );
};

export default UnitAndCaseBarcodeScan;
