import { AddIcon, ArrowBackIcon, ChevronDownIcon } from "@chakra-ui/icons";
import * as chakra from "@chakra-ui/react";
import {
  Button,
  Checkbox,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import Fuse from "fuse.js";
import Papa from "papaparse";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";
import VirtualizedDataTable from "../../../components/DataTable/VirtualizedDataTable.tsx";
import FileUpload from "../../../components/FileUpload/FileUpload.tsx";
import SupplierLocationSearch from "../../../components/SupplierLocationSearch/SupplierLocationSearch.tsx";
import {
  useGetProductsQuery,
  useImportSupplierPricesMutation,
  usePostProductUnitWithoutCacheInvalidationMutation,
} from "../../../redux/apiSlice.ts";
import {
  Price,
  Product,
  ProductUnit,
  ProductUnitBase,
} from "../../../types.ts";
import {
  detectUnits,
  processProductName,
  removeProductStopwords,
  removeTrailingS,
} from "../../../utils/nlpUtils.ts";
import { capitalizeWords } from "../../../utils/stringUtils.ts";
import AddProductModal from "../../ProductView/AddProductModal.tsx";
import ColumnMappingConfig from "./ColumnMappingConfig.tsx";
import { ColumnConfig, ColumnType } from "./types.ts";
import { COLUMN_TYPE_OPTIONS, getColumnId, getColumnTypeOption } from "./utils";
import {
  findMatchingProductUnit,
  getProductUnitKeyForNewOrExisting,
  getProductUnitLabel,
} from "../../../utils/productUnitUtils.ts";
import { userSlice } from "../../../redux/userSlice.ts";
import { useAppSelector } from "../../../redux/hooks.ts";
import { handleException } from "../../../utils/errorUtils.ts";

interface ImportPricesModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export type PriceImport = {
  product_name?: string;
  product_id?: number;
  product_unit_id?: number;
  product?: Product;
  product_units?: (ProductUnit | ProductUnitBase)[];
  suggested_product?: Product;
  product_suggestions?: Product[];
  confident_match?: boolean;
  product_unit?: ProductUnit | ProductUnitBase;
  supplier_product_name: string;
  certificates?: string;
  certifying_body?: string;
  unit_quantity: number;
  weight: number;
  price: number;
  price_per_base_unit: number;
  origin: string;
  producer: string;
};
type RawDataRow = {
  [key: string]: string;
};

// const KG_TO_LB = 2.20462;

const ImportPricesModal = ({
  isOpen,
  onClose,
}: ImportPricesModalProps): React.ReactElement => {
  const toast = chakra.useToast();
  const [isFullscreen, setIsFullscreen] = useState<boolean>(true);
  const [parsedData, setParsedData] = useState<PriceImport[]>([]);
  const [supplierBaseUnit, setSupplierBaseUnit] = useState<string>("lb");
  const parsedRowsCount = React.useRef(0);
  const tempParsedData = React.useRef<PriceImport[]>([]);
  const parserRef = React.useRef<unknown>(null);
  const [fileProcessed, setFileProcessed] = useState(false);
  const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
  const [addProductModalData, setAddProductModalData] =
    useState<PriceImport | null>(null);
  const [currentRowIndex, setCurrentRowIndex] = useState<number | null>(null);
  const [initialProductName, setInitialProductName] = useState<string>("");
  const [addProductDescription, setAddProductDescription] =
    useState<string>("");
  const [fromDate, setFromDate] = useState<string>(
    new Date().toISOString().split("T")[0],
  );
  const [toDate, setToDate] = useState<string>("");
  const [showColumnMapping, setShowColumnMapping] = useState(false);
  const [previewData, setPreviewData] = useState<string[][]>([]);
  const [headerRow, setHeaderRow] = useState(0);
  const [startRow, setStartRow] = useState(1);
  const [endRow, setEndRow] = useState<number>(startRow + 1);

  const { getCurrentLocationId, getCurrentOrganisationId } =
    userSlice.selectors;
  const locationId = useAppSelector(getCurrentLocationId);
  const organisationId = useAppSelector(getCurrentOrganisationId);

  const [isImporting, setIsImporting] = useState(false);

  const {
    data: products,
    isLoading: isLoadingProducts,
    refetch: refetchProducts,
  } = useGetProductsQuery("");
  const [importPrices, { isLoading: isLoadingImportPrices }] =
    useImportSupplierPricesMutation();
  const [postProductUnit, { isLoading: isPostingProductUnit }] =
    usePostProductUnitWithoutCacheInvalidationMutation();

  // Add new state for tracking the view state
  const [currentView, setCurrentView] = useState<"mapping" | "preview">(
    "mapping",
  );

  // Add state for column configurations
  const [columnConfigs, setColumnConfigs] = useState<ColumnConfig[]>([]);

  // Add these state variables to track price and weight columns
  const [priceColumnId, setPriceColumnId] = useState<string | null>(null);
  const [weightColumnId, setWeightColumnId] = useState<string | null>(null);
  const [originColumnId, setOriginColumnId] = useState<string | null>(null);
  const [producerNameColumnId, setProducerNameColumnId] = useState<
    string | null
  >(null);
  const [certificatesColumnId, setCertificatesColumnId] = useState<
    string | null
  >(null);

  // Add state for tracking product name column
  const [productNameColumnId, setProductNameColumnId] = useState<string | null>(
    null,
  );

  const [selectedSupplierLocationId, setSelectedSupplierLocationId] = useState<
    number | null
  >(null);
  const [selectedSupplierOrganisationId, setSelectedSupplierOrganisationId] =
    useState<number | null>(null);

  useEffect(() => {
    setIsImporting(isLoadingImportPrices || isPostingProductUnit);
  }, [isLoadingImportPrices, isPostingProductUnit]);

  const handleImport = async () => {
    if (!parsedData.length) {
      toast({
        title: "No data to import",
        description: "Please upload a file with data to import",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (!selectedSupplierLocationId) {
      toast({
        title: "Supplier is required",
        description: "Please select a supplier for these prices",
        status: "error",
        duration: 3000,
      });
      return;
    }
    if (!selectedSupplierOrganisationId) {
      toast({
        title: "Supplier is required",
        description: "Please select a supplier for these prices",
      });
      return;
    }
    if (!fromDate) {
      toast({
        title: "From Date is required",
        description: "Please select a start date for these prices",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (!weightColumnId) {
      toast({
        title: "Weight is required to create units",
        description: "Please select a weight column to create units",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (!priceColumnId) {
      toast({
        title: "Price is required to import prices",
        description: "Please select a price column to import prices",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    // if (!toDate) {
    //   toast({
    //     title: "To Date is required",
    //     description: "Please select a end date for these prices",
    //     status: "error",
    //     duration: 3000,
    //   })
    // }
    if (!organisationId) {
      toast({
        title: "Error",
        description:
          "Error loading organisation id. Please refresh and try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const updatedData: PriceImport[] = [...parsedData];
    for (const [index, data] of updatedData.entries()) {
      // cycle through parsedData and add any new product units
      if (!data.product_unit) {
        toast({
          title: "Product Unit is required",
          description: `Please select a product unit for line ${index + 1}: ${
            data.supplier_product_name
          }`,
          status: "error",
          duration: 12000,
          isClosable: true,
        });
        return;
      }
      if (data.product_unit && !data.product_unit.id) {
        const pendingProductUnit: ProductUnit | ProductUnitBase =
          data.product_unit;
        const potentialSubUnit = pendingProductUnit.sub_unit;
        // @ts-expect-error: id check is valid
        if (potentialSubUnit && !potentialSubUnit.id) {
          await postProductUnit({
            organisationId,
            locationId,
            productUnit: potentialSubUnit,
          })
            .unwrap()
            .then((newUnit: ProductUnit) => {
              // data.product_units contains the old ProductUnitBase for this unit, replace it with the newly created unit
              pendingProductUnit.sub_unit = newUnit as ProductUnit;
              pendingProductUnit.sub_unit_id = newUnit.id;
              pendingProductUnit.sub_unit_quantity =
                potentialSubUnit.quantity_in_parent;
              data.product_unit = pendingProductUnit;
              updatedData[index] = data;
              setParsedData(updatedData);
            })
            .catch((error) => {
              handleException(error);
              toast({
                title: "Product Unit Error",
                description: `Please select a product unit for line ${
                  index + 1
                }: ${data.supplier_product_name}`,
                status: "error",
                duration: 3000,
                isClosable: true,
              });
              setParsedData(updatedData);
              return;
            });
        }
        await postProductUnit({
          organisationId,
          locationId,
          productUnit: pendingProductUnit,
        })
          .unwrap()
          .then((newUnit) => {
            // data.product_units contains the old ProductUnitBase for this unit, replace it with the newly created unit
            if (!data.product_units) {
              data.product_units = [newUnit];
            } else {
              data.product_units = data.product_units.map((unit) =>
                getProductUnitKeyForNewOrExisting(unit) ===
                getProductUnitKeyForNewOrExisting(pendingProductUnit)
                  ? newUnit
                  : unit,
              );
            }

            data.product_unit = newUnit;
            data.product_unit_id = newUnit.id;

            updatedData[index] = data;
            setParsedData(updatedData);
          })
          .catch((error) => {
            handleException(error);
            toast({
              title: "Product Unit Error",
              description: `Please select a product unit for line ${
                index + 1
              }: ${data.supplier_product_name}`,
              status: "error",
              duration: 3000,
              isClosable: true,
            });
            setParsedData(updatedData);
            return;
          });
      }
    }
    setParsedData(updatedData);
    refetchProducts();

    importPrices({
      organisationId,
      supplierOrganisationId: selectedSupplierOrganisationId,
      prices: updatedData.map(
        (row) =>
          ({
            product_id: row.product?.id,
            product_unit_id: row.product_unit_id,
            price:
              parseFloat(row.price.toString().replace(/[^0-9.]/g, "")) || 0,
            supplier_organisation_id: selectedSupplierOrganisationId,
            // buyer_organisation_id: organisationId,
            minimum_order_quantity: 0,
            effective_date: fromDate,
            deactivated_date: toDate && toDate.length ? toDate : null,
            // supplier_sku: row.supplier_sku,
            // TODO: implement SKU and UPC
            supplier_product_name: row.supplier_product_name,
            product_origin: row.origin,
            product_producer: row.producer,
            product_certification: row.certificates,
          }) as Price,
      ),
    })
      .unwrap()
      .then(() => {
        toast({
          title: "Prices Imported",
          description: `Prices imported successfully`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        // resetMappingStates();
      })
      .catch((error) => {
        handleException(error);
        toast({
          title: "Prices Import Error",
          description: `Error importing prices`,
          status: "error",
          duration: 3000,
        });
      });
  };

  const findMatchingUnitsForProduct = (
    supplierName: string,
    product: Product,
    caseWeight: number,
  ): ProductUnit | ProductUnitBase | undefined => {
    const potentialSubUnits = detectUnits(supplierName);

    let subUnit;

    let unitToCheck = supplierBaseUnit;

    if (potentialSubUnits && potentialSubUnits.isCaseUnit) {
      const matchingSubUnit = findMatchingProductUnit(
        product,
        potentialSubUnits.unit,
        potentialSubUnits.unitQuantity,
        potentialSubUnits.label,
      );
      if (matchingSubUnit) {
        subUnit = {
          ...matchingSubUnit,
          quantity_in_parent: potentialSubUnits.quantity,
        };
      } else {
        subUnit = {
          product_id: product.id,
          unit_quantity: potentialSubUnits.unitQuantity,
          unit_measure: potentialSubUnits.unit,
          unit_system:
            potentialSubUnits.unit === "kg"
              ? "METRIC"
              : potentialSubUnits.unit === "unit"
              ? "QUANTITY"
              : "IMPERIAL_US",
          unit_label: potentialSubUnits.label,
          quantity_in_parent: potentialSubUnits.quantity,
        } as ProductUnitBase;
      }
    } else if (isNaN(caseWeight) && !potentialSubUnits?.isCaseUnit) {
      unitToCheck = potentialSubUnits?.unit || supplierBaseUnit;
    }
    let matchingProductUnit: ProductUnit | ProductUnitBase | undefined =
      findMatchingProductUnit(
        product,
        unitToCheck,
        caseWeight,
        !potentialSubUnits?.isCaseUnit
          ? potentialSubUnits?.label || "case"
          : "case",
        subUnit,
      );
    if (matchingProductUnit && subUnit && !matchingProductUnit.sub_unit) {
      toast({
        title: "Product matching error",
        description: "Product unit does not have sub unit",
      });
      return;
    }
    if (!matchingProductUnit) {
      if (caseWeight && !isNaN(caseWeight)) {
        matchingProductUnit = {
          product_id: product.id,
          unit_quantity: subUnit ? subUnit.quantity_in_parent : caseWeight,
          unit_measure: unitToCheck,
          unit_system:
            unitToCheck === "kg"
              ? "METRIC"
              : unitToCheck === "unit"
              ? "QUANTITY"
              : "IMPERIAL_US",
          unit_label: "case",
        } as ProductUnitBase;
        if (subUnit && subUnit.quantity_in_parent) {
          matchingProductUnit.unit_quantity = subUnit.quantity_in_parent;
          matchingProductUnit.sub_unit = subUnit;
          matchingProductUnit.sub_unit_id = subUnit.id;
          matchingProductUnit.unit_measure = "unit";
          matchingProductUnit.unit_system = "QUANTITY";
        }
      } else if (potentialSubUnits) {
        if (!potentialSubUnits.isCaseUnit) {
          matchingProductUnit = {
            product_id: product.id,
            unit_quantity: potentialSubUnits.unitQuantity,
            unit_measure: potentialSubUnits.unit,
            unit_system:
              potentialSubUnits.unit === "kg"
                ? "METRIC"
                : potentialSubUnits.unit === "unit"
                ? "QUANTITY"
                : "IMPERIAL_US",
            unit_label: potentialSubUnits.label,
          } as ProductUnitBase;
        } else {
          matchingProductUnit = {
            product_id: product.id,
            unit_quantity: subUnit.quantity_in_parent,
            unit_measure: "unit",
            unit_system: "QUANTITY",
            unit_label: "case",
            sub_unit: subUnit,
          } as ProductUnitBase;
        }
      }
    }

    return matchingProductUnit;
  };

  // Update matchProductsWithPrices to be more strict about requirements
  const matchImportWithProducts = useCallback(
    (
      rawPriceData: PriceImport[],
      newProductNameColumnId: string,
    ): PriceImport[] => {
      // Early return if we don't have the required data
      if (
        !products?.length ||
        !newProductNameColumnId ||
        !rawPriceData?.length
      ) {
        console.log("Missing required data for product matching:", {
          hasProducts: `${!!products?.length} - ${products?.length}`,
          hasProductNameColumn: `${!!newProductNameColumnId} - ${newProductNameColumnId}`,
          hasData: `${!!rawPriceData?.length} - ${rawPriceData?.length}`,
        });
        return rawPriceData;
      }

      const productsProcessed = products.map((product) => ({
        ...product,
        keyName: processProductName(product.name),
      }));

      const productFuse = new Fuse(productsProcessed, {
        keys: ["keyName"],
      });
      const matchedData = rawPriceData.map((row) => {
        const newRow = { ...row };
        let supplierName = row[
          newProductNameColumnId as keyof PriceImport
        ] as string;

        const originalSupplierName = supplierName;

        if (!supplierName || !supplierName.length) {
          return newRow;
        }

        supplierName = removeProductStopwords(processProductName(supplierName));
        const matches = productFuse.search(supplierName?.trim());

        const sortedProducts = products.slice().sort((a, b) => {
          return a.name.localeCompare(b.name);
        });

        if (matches && matches.length) {
          const bestMatch = matches[0];
          newRow.suggested_product = bestMatch.item;
          newRow.product = bestMatch.item;
          newRow.product_id = bestMatch.item.id;
          newRow.product_units = newRow.product.product_units;

          const matchingProductUnit = findMatchingUnitsForProduct(
            originalSupplierName,
            newRow.product,
            parseFloat(row.weight.toString().replace(/[^0-9.]/g, "")),
          );
          // @ts-expect-error id not present on ProductBaseUnit
          if (matchingProductUnit && !matchingProductUnit.id) {
            newRow.product_units = [
              ...newRow.product_units,
              matchingProductUnit,
            ];
          }
          // @ts-expect-error id not present on ProductBaseUnit
          newRow.product_unit_id = matchingProductUnit?.id;
          newRow.product_unit = matchingProductUnit;
          let sortedMatches = matches.map((match) => match.item);
          sortedMatches = [
            ...sortedMatches.splice(0, 10),
            ...sortedMatches.sort((a, b) => a.name.localeCompare(b.name)),
          ];
          newRow.product_suggestions = sortedMatches;

          if (
            removeTrailingS(supplierName) ===
            removeTrailingS(processProductName(bestMatch.item.name))
          ) {
            newRow.confident_match = true;
          } else if (bestMatch.score && bestMatch.score < 0.2) {
            newRow.confident_match = true;
          }
        } else {
          newRow.product_suggestions = sortedProducts;
        }

        return newRow;
      });

      return matchedData;
    },
    [products],
  );

  const handleColumnTypeChange = useCallback(
    (columnId: string, newType: ColumnType[]) => {
      setColumnConfigs((prevConfigs) => {
        if (!prevConfigs.length) {
          return prevConfigs;
        }

        // Update the types for the selected column
        const newConfigs = prevConfigs.map((config) => {
          if (config.id === columnId) {
            const uniqueTypes = Array.from(new Set(newType));
            return { ...config, types: uniqueTypes };
          }
          return config;
        });
        return newConfigs;
      });

      if (newType.includes("price")) {
        setPriceColumnId(columnId);
      }

      if (newType.includes("weight")) {
        setWeightColumnId(columnId);
      }

      if (newType.includes("certificates")) {
        setCertificatesColumnId(columnId);
      }

      if (newType.includes("origin")) {
        setOriginColumnId(columnId);
      }

      if (newType.includes("producer")) {
        setProducerNameColumnId(columnId);
      }
      // TODO: implement SKU and UPC

      // Only handle product name separately since it requires data updates
      if (newType.includes("product_name")) {
        setProductNameColumnId(columnId);

        // Update parsedData to include supplier_product_name && rename price and weight columns
        if (parsedData.length > 0) {
          // rename priceColumnId and weightColumnId to "price" and "weight"
          const matchedData = matchImportWithProducts(
            parsedData.map((row) => ({
              ...row,
              supplier_product_name: row[
                columnId as keyof PriceImport
              ] as string,
            })),
            columnId,
          );
          setParsedData(matchedData);
        }
      }

      if (newType.length === 0) {
        setParsedData((prev) => [...prev]);
      }
    },
    [parsedData, matchImportWithProducts],
  );

  // const calculatePricePerBaseUnit = (price: number, quantity: number, baseUnit: string): number => {
  //   const pricePerKg = price / quantity;
  //   return baseUnit === "lb" ? pricePerKg * KG_TO_LB : pricePerKg;
  // };

  // Add this function to reset all states
  const resetMappingStates = useCallback(() => {
    setHeaderRow(0);
    setStartRow(1);
    setEndRow(1);
    setSelectionStep("header");
    setColumnConfigs([]);
    setPriceColumnId(null);
    setWeightColumnId(null);
    setProductNameColumnId(null);
    setCurrentView("mapping");
    setShowColumnMapping(true);
    setPreviewData([]);
    setParsedData([]);
    setFileProcessed(false);
  }, []);

  // Update the handleFileUpload function
  const handleFileUpload = useCallback(
    (file: File | null) => {
      if (!fromDate) {
        toast({
          title: "From Date is required",
          description: "Please select a start date for these prices",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (!selectedSupplierLocationId) {
        toast({
          title: "Supplier is required",
          description: "Please select a supplier for these prices",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      // Reset all states when a new file is uploaded
      resetMappingStates();

      if (!file) {
        return;
      }

      parsedRowsCount.current = 0;
      tempParsedData.current = [];
      Papa.parse(file, {
        complete: (results) => {
          setPreviewData(results.data as string[][]);
          setEndRow((results.data as string[][]).length - 1);
          setShowColumnMapping(true);
        },
        error: (error) => {
          toast({
            title: "Failed to read file",
            description: error.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      });
    },
    [fromDate, toast, resetMappingStates, selectedSupplierLocationId],
  );

  useEffect(() => {
    if (!isOpen) {
      if (
        parserRef.current &&
        typeof parserRef.current === "object" &&
        "abort" in parserRef.current
      ) {
        (parserRef.current as { abort: () => void }).abort();
      }
      setParsedData([]);
      setFileProcessed(false);
      parsedRowsCount.current = 0;
      tempParsedData.current = [];
      setCurrentView("mapping");
    }
  }, [isOpen]);

  const changeSelectedProduct = useCallback(
    async (
      index: number,
      productId: number,
      supplierName: string,
      caseWeight: number,
      newProduct?: Product,
    ) => {
      if (isLoadingProducts) {
        // wait until products stopped loading
        await new Promise((resolve) => {
          const interval = setInterval(() => {
            if (!isLoadingProducts) {
              clearInterval(interval);
              resolve(true);
            }
          }, 100);
        });
      }
      const updatedData = [...parsedData];
      let product;
      if (!newProduct) {
        product = products?.find((product) => product.id === productId);
        if (!product) {
          console.error(
            "Product not found on ImportPricesModal.changeSelectedProduct",
            {
              index,
              productId,
              supplierName,
              caseWeight,
              products:
                products?.map((product) => ({
                  id: product.id,
                  name: product.name,
                })) || [],
              parsedData: parsedData,
            },
          );
          handleException(
            new Error(
              "Product not found on ImportPricesModal.changeSelectedProduct",
            ),
          );
          toast({
            title: "Product not found",
            description: "Product not found",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          return;
        }
      } else {
        product = newProduct;
        updatedData[index].suggested_product = product;
        updatedData[index].confident_match = true;
        if (
          updatedData[index].product_suggestions &&
          updatedData[index].product_suggestions.length
        ) {
          updatedData[index].product_suggestions.push(product);
        } else {
          updatedData[index].product_suggestions = [product];
        }
      }

      updatedData[index].product = product;
      updatedData[index].product_id = productId;
      updatedData[index].product_units = product?.product_units || [];
      const matchingProductUnit = findMatchingUnitsForProduct(
        supplierName,
        product,
        caseWeight,
      );
      if (!matchingProductUnit) {
        handleException(
          new Error(
            "matchingProductUnit not found on ImportPricesModal.changeSelectedProduct",
          ),
        );
        toast({
          title: "Product matching error",
          description: `Error finding product unit for ${product.name} (${supplierName})`,
        });
        return;
      }
      if (!matchingProductUnit.id) {
        if (updatedData[index].product_units?.length) {
          updatedData[index].product_units = [
            ...updatedData[index].product_units,
            matchingProductUnit,
          ];
        } else {
          updatedData[index].product_units = [matchingProductUnit];
        }
      }
      setParsedData(updatedData);
      changeSelectedProductUnit(
        index,
        getProductUnitKeyForNewOrExisting(matchingProductUnit),
      );
    },
    [parsedData, products],
  );

  const changeSelectedProductUnit = useCallback(
    (index: number, unitId: string) => {
      const updatedData = [...parsedData];
      updatedData[index].product_unit = parsedData[index]?.product_units?.find(
        (productUnit) =>
          getProductUnitKeyForNewOrExisting(productUnit).toString() === unitId,
      );
      setParsedData(updatedData);
    },
    [parsedData],
  );

  const handleAddProductClick = useCallback(
    (rowIndex: number) => {
      const currentRow = parsedData[rowIndex];
      const cleanedProductName = capitalizeWords(
        removeProductStopwords(
          processProductName(currentRow.supplier_product_name),
        ),
      );

      setCurrentRowIndex(rowIndex);
      setInitialProductName(cleanedProductName);
      setAddProductDescription(currentRow.supplier_product_name);
      setAddProductModalData(currentRow);
      setIsAddProductModalOpen(true);
    },
    [parsedData],
  );

  const handleAddProductClose = () => {
    setIsAddProductModalOpen(false);
    setInitialProductName("");
  };

  const handleProductCreated = async (newProduct: Product) => {
    if (!addProductModalData || currentRowIndex === null) {
      handleException(
        new Error(
          "addProductModalData not found on ImportPricesModal.handleProductCreated",
        ),
      );
      toast({
        title: "Issue matching newly created product",
        description: "Please refresh the page and try again",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const newParsedData = parsedData.map((row, index) => {
      if (index === currentRowIndex) {
        return {
          ...row,
          suggested_product: newProduct,
          product: newProduct,
          product_suggestions: row.product_suggestions
            ? [...row.product_suggestions, newProduct]
            : [newProduct],
        };
      }
      return row;
    });
    setParsedData(newParsedData);
    await changeSelectedProduct(
      currentRowIndex,
      newProduct.id,
      addProductModalData?.supplier_product_name || "",
      addProductModalData?.weight || 1,
      newProduct,
    );
    changeSelectedProductUnit(
      currentRowIndex,
      getProductUnitKeyForNewOrExisting(newProduct.product_units[0]),
    );
    handleAddProductClose();
  };

  const getColumnTypeOptions = useCallback(
    (header: string) => {
      return COLUMN_TYPE_OPTIONS.map((option) => ({
        ...option,
        status: option.exclusive
          ? header === productNameColumnId && option.value === "product_name"
            ? "current"
            : header === priceColumnId && option.value === "price"
            ? "current"
            : header === weightColumnId && option.value === "weight"
            ? "current"
            : productNameColumnId && option.value === "product_name"
            ? "taken"
            : priceColumnId && option.value === "price"
            ? "taken"
            : weightColumnId && option.value === "weight"
            ? "taken"
            : undefined
          : undefined,
      }));
    },
    [priceColumnId, productNameColumnId, weightColumnId],
  );

  const ColumnTypeSelect = useCallback(
    ({
      header,
      currentTypes,
      onChange,
    }: {
      header: string;
      currentTypes: ColumnType[];
      onChange: (header: string, types: ColumnType[]) => void;
    }) => {
      const options = getColumnTypeOptions(header);

      const handleTypeToggle = (type: ColumnType) => {
        const newTypes: ColumnType[] = currentTypes.includes(type)
          ? currentTypes.filter((t) => t !== type)
          : [...currentTypes, type];

        onChange(header, newTypes);
      };

      return (
        <Menu closeOnSelect={false}>
          <MenuButton
            as={Button}
            size="xs"
            rightIcon={<ChevronDownIcon />}
            bg="white"
            borderColor="gray.200"
            _hover={{ borderColor: "gray.300" }}
          >
            Column Types
          </MenuButton>
          <MenuList minWidth="200px">
            {options.map((option) => (
              <MenuItem key={option.value} onClick={(e) => e.stopPropagation()}>
                <Checkbox
                  isChecked={currentTypes.includes(option.value as ColumnType)}
                  onChange={() => handleTypeToggle(option.value as ColumnType)}
                  isDisabled={option.status === "taken"}
                >
                  {`${option.icon} ${option.label}`}
                  {option.status === "current" && " (Current)"}
                  {option.status === "taken" && " (Already Set)"}
                </Checkbox>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      );
    },
    [getColumnTypeOptions],
  );
  const hasData = useCallback(
    (data: RawDataRow[], columnId: string): boolean => {
      return data.some((row) => {
        const value = row[columnId];
        return (
          value !== undefined &&
          value !== null &&
          value?.trim &&
          value?.trim() !== ""
        );
      });
    },
    [],
  );

  const columns = React.useMemo(() => {
    if (!parsedData.length || !parsedData[0]) {
      return [];
    }
    const headersToOmit = ["supplier_product_name"];
    const headers = Object.keys(parsedData[0]);
    const nonEmptyHeaders = headers.filter(
      (header) =>
        Boolean(header) &&
        hasData(parsedData as unknown as RawDataRow[], header) &&
        !headersToOmit.includes(header),
    );

    const headerColumns: ColumnDef<PriceImport>[] = nonEmptyHeaders.map(
      (header, index) => ({
        id: header || `column${index}`,
        accessorKey: header,
        header: () => (
          <chakra.Flex direction="column" align="stretch" gap={1} width="100%">
            <chakra.Text fontWeight="medium" fontSize="sm">
              {header || `Column ${index}`}
            </chakra.Text>
            <chakra.Text fontSize="xs" color="gray.500">
              {columnConfigs
                .find((c) => c.id === header)
                ?.types.map((type) => {
                  const option = getColumnTypeOption(type);
                  return option ? `${option.icon} ${option.label}` : type;
                })
                .join(" • ")}
            </chakra.Text>
          </chakra.Flex>
        ),
        cell: (info) => {
          const value = info.getValue() as string;

          return (
            <chakra.Tooltip label={value}>
              <chakra.Box
                maxW={isFullscreen ? "350px" : "150px"}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {value}
              </chakra.Box>
            </chakra.Tooltip>
          );
        },
        size: 100 / (headers.length + 2), // +2 to account for product suggestion and price per unit columns
      }),
    );

    // Add product suggestion column after the first column (usually product name)
    const allColumns: ColumnDef<PriceImport>[] = [
      headerColumns[0],
      {
        id: "suggested_product",
        accessorKey: "suggested_product",
        header: "Product",
        cell: (info) => {
          const row = info.row.original;
          const suggestions = row.product_suggestions || [];
          const currentProduct = row.product;
          const suggestedProduct = row.suggested_product;

          return (
            <chakra.Flex gap={2} alignItems="center" maxW="220px">
              <chakra.Select
                color={
                  currentProduct?.id === suggestedProduct?.id
                    ? row.confident_match
                      ? "green.500"
                      : "orange.500"
                    : "black"
                }
                onChange={(e) =>
                  changeSelectedProduct(
                    info.row.index,
                    Number(e.target.value),
                    info.row.original.supplier_product_name,
                    info.row.original.weight,
                  )
                }
                value={currentProduct?.id || ""}
                placeholder="Select product..."
              >
                {suggestions.map((product: Product) => (
                  <option
                    key={product.id}
                    value={product.id}
                    style={{
                      color:
                        product.id === suggestedProduct?.id
                          ? row.confident_match
                            ? "green"
                            : "orange"
                          : "black",
                    }}
                  >
                    {product.name}
                  </option>
                ))}
              </chakra.Select>
              <chakra.Tooltip label="Add new product">
                <chakra.IconButton
                  aria-label="Add new product"
                  icon={<AddIcon />}
                  size="sm"
                  onClick={() => handleAddProductClick(info.row.index)}
                />
              </chakra.Tooltip>
            </chakra.Flex>
          );
        },
        size: 20,
      },
      {
        id: "product_unit_id",
        accessorKey: "product_unit_id",
        header: "Product Unit",
        cell: (info) => {
          const row = info.row.original;
          const productUnits = row.product_units || [];
          const currentProductUnit = row.product_unit;

          return (
            <chakra.Select
              onChange={(e) =>
                changeSelectedProductUnit(info.row.index, e.target.value)
              }
              value={
                currentProductUnit
                  ? getProductUnitKeyForNewOrExisting(currentProductUnit)
                  : ""
              }
              placeholder="Select product unit..."
            >
              {productUnits.map(
                (productUnit: ProductUnit | ProductUnitBase) => (
                  <option
                    key={getProductUnitKeyForNewOrExisting(productUnit)}
                    value={getProductUnitKeyForNewOrExisting(productUnit)}
                  >
                    {`${getProductUnitLabel(productUnit)}${
                      // @ts-expect-error id not present on ProductBaseUnit
                      !productUnit.id ? " (New)" : ""
                    }`}
                  </option>
                ),
              )}
            </chakra.Select>
          );
        },
        size: 20,
      },
      {
        id: "weight",
        accessorKey: "weight",
        header: "Weight",
        cell: (info) => info.getValue(),
        size: 20,
      },
      ...headerColumns.slice(1).filter((c) => c.id !== "weight"),
    ];
    return allColumns;
  }, [
    parsedData,
    hasData,
    columnConfigs,
    isFullscreen,
    changeSelectedProduct,
    handleAddProductClick,
  ]);

  // Update pricePerUnitColumn to be more reactive
  // const pricePerUnitColumn = useMemo(
  //   (): ColumnDef<PriceImport> => ({
  //     id: "price_per_base_unit",
  //     header: `Price per ${baseUnit}`,
  //     accessorFn: (row) => {
  //       if (!priceColumnId || !weightColumnId) {
  //         return null;
  //       }

  //       const price = parseFloat(row[priceColumnId as keyof PriceImport] as string);
  //       const weight = parseFloat(row[weightColumnId as keyof PriceImport] as string);

  //       if (isNaN(price) || isNaN(weight) || weight === 0) {
  //         return null;
  //       }

  //       return calculatePricePerBaseUnit(price, weight, baseUnit);
  //     },
  //     cell: (info) => {
  //       const value = info.getValue() as string;
  //       return (
  //         <chakra.Tooltip label={value}>
  //           <chakra.Box
  //             maxW={isFullscreen ? "350px" : "150px"}
  //             overflow="hidden"
  //             textOverflow="ellipsis"
  //             whiteSpace="nowrap"
  //             color={!priceColumnId || !weightColumnId ? "gray.400" : undefined}
  //           >
  //             {value
  //               ? `$${parseFloat(value).toFixed(2)}/${baseUnit}`
  //               : !priceColumnId || !weightColumnId
  //               ? "Set price and weight columns"
  //               : "-"}
  //           </chakra.Box>
  //         </chakra.Tooltip>
  //       );
  //     },
  //     size: 20,
  //   }),
  //   [baseUnit, priceColumnId, weightColumnId, isFullscreen]
  // ); // Add all dependencies

  const handleConfirm = () => {
    if (
      !previewData ||
      headerRow === null ||
      startRow === null ||
      endRow === null
    ) {
      return;
    }
    if (
      productNameColumnId === null ||
      priceColumnId === null ||
      weightColumnId === null
    ) {
      toast({
        title: "Missing column",
        description: "Please set the product name, price, and weight columns",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // Process the selected range of data
    const headers = previewData[headerRow].map((_, index) =>
      getColumnId(previewData[headerRow], index),
    );
    const dataRows = previewData.slice(startRow, endRow + 1);
    const headerRename = (header: string) => {
      header = header.trim();
      if (header === weightColumnId) {
        return "weight";
      }
      if (header === priceColumnId) {
        return "price";
      }
      if (header === originColumnId) {
        return "origin";
      }
      if (header === producerNameColumnId) {
        return "producer";
      }
      if (header === certificatesColumnId) {
        return "certificates";
      }
      // TODO: implement SKU and UPC
      // if (header === upcColumnId) {
      //   return "UPC"
      // }
      // if (header === vendorSkuColumnId) {
      //   return "SKU"
      // }
      return header;
    };
    const invalidRows: number[] = [];
    // Process the data with the selected range
    const processedData = dataRows
      .map((row) => {
        const rowData: { [key: string]: string } = {};
        headers.forEach((header, index) => {
          rowData[headerRename(header.trim())] = row[index];
        });
        return rowData;
      })
      .filter((row, index) => {
        // filter out rows with invalid or mostly empty information
        // check for product name, weight, price
        const productName = row[productNameColumnId];
        const price = row[priceColumnId] || row.price;

        const isValid =
          productName &&
          productName.trim() !== "" &&
          price &&
          !isNaN(parseFloat(price.toString().replace(/[^0-9.]/g, "")));
        if (!isValid) {
          invalidRows.push(index);
        }
        return isValid;
      });

    if (invalidRows.length > 0) {
      toast({
        title: "Invalid rows have been removed",
        description: `Invalid rows found at indices: ${invalidRows.join(", ")}`,
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }

    // Add supplier_product_name from the product name column if it exists
    const dataWithSupplierNames = processedData.map((row) => ({
      ...row,
      supplier_product_name: productNameColumnId
        ? row[productNameColumnId]
        : "",
    }));

    // Run the product matching
    const matchedData = matchImportWithProducts(
      dataWithSupplierNames as PriceImport[],
      productNameColumnId || "",
    );

    setParsedData(matchedData);
    setShowColumnMapping(false);
    setFileProcessed(true);
    setCurrentView("preview");
  };

  const handleCancel = () => {
    setShowColumnMapping(false);
    setHeaderRow(0);
    setStartRow(1);
    setEndRow(startRow + 10);
    setPreviewData([]);
  };

  // Add a handler for going back to mapping view
  const handleBackToMapping = () => {
    setCurrentView("mapping");
    setShowColumnMapping(true);
    // Reset selection step to "end" since we already have all the configurations
    setSelectionStep("end");
  };

  // Add selection step state to the parent
  const [selectionStep, setSelectionStep] = useState<
    "header" | "start" | "end"
  >("header");

  const initializeColumnConfigs = useCallback(
    (headerRowIndex: number) => {
      if (previewData.length > 0 && headerRowIndex !== null) {
        const headers = previewData[headerRowIndex];
        const initialConfigs = headers.map((header, index) => ({
          id: header.trim() || `column${index}`,
          types: [] as ColumnType[],
          header: header.trim() || `column${index}`,
        }));
        setColumnConfigs(initialConfigs);
      }
    },
    [previewData],
  );

  return (
    <>
      <chakra.Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size={isFullscreen ? "full" : "5xl"}
      >
        <chakra.ModalOverlay />
        <chakra.ModalContent>
          <chakra.ModalHeader>
            <chakra.Flex alignItems="center">
              <chakra.Stack w="80%" direction="row" spacing={4}>
                <chakra.Heading w="600px">
                  Import Supplier Prices
                </chakra.Heading>
                <chakra.Spacer />
                <SupplierLocationSearch
                  includeSearchIcon
                  setSupplierLocationId={setSelectedSupplierLocationId}
                  setSupplierOrganisationId={setSelectedSupplierOrganisationId}
                  placeholder="Select supplier for prices"
                />
              </chakra.Stack>
              <chakra.Spacer />
              <chakra.Button
                onClick={() => setIsFullscreen(!isFullscreen)}
                size="md"
                variant="outline"
                mr={8}
              >
                {isFullscreen ? (
                  <AiOutlineFullscreenExit />
                ) : (
                  <AiOutlineFullscreen />
                )}
              </chakra.Button>
            </chakra.Flex>
          </chakra.ModalHeader>
          <chakra.ModalCloseButton />
          <chakra.ModalBody>
            {isOpen && (
              <>
                <chakra.Flex gap={4} justifyContent="space-between">
                  {currentView !== "preview" && (
                    <FileUpload
                      onFileRead={handleFileUpload}
                      buttonLabel="Import"
                      isProcessed={fileProcessed}
                    />
                  )}
                  <chakra.Flex
                    gap={4}
                    borderWidth="1px"
                    borderColor="border.disabled"
                    p={4}
                    borderRadius="md"
                    ml="auto"
                  >
                    <chakra.FormControl w="150px">
                      <chakra.FormLabel>Supplier Base Unit</chakra.FormLabel>
                      <chakra.Select
                        value={supplierBaseUnit}
                        onChange={(e) => {
                          setSupplierBaseUnit(e.target.value);
                          // Force a refresh of the table data
                          setParsedData((prev) => [...prev]);
                        }}
                      >
                        <option value="kg">kg</option>
                        <option value="lb">lb</option>
                      </chakra.Select>
                    </chakra.FormControl>
                    <chakra.FormControl isRequired w="150px">
                      <chakra.FormLabel>From Date</chakra.FormLabel>
                      <chakra.Input
                        type="date"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                      />
                    </chakra.FormControl>
                    <chakra.FormControl w="150px">
                      <chakra.FormLabel>To Date (Optional)</chakra.FormLabel>
                      <chakra.Input
                        type="date"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                        min={fromDate}
                      />
                    </chakra.FormControl>
                  </chakra.Flex>
                </chakra.Flex>
                {currentView === "mapping" &&
                  showColumnMapping &&
                  previewData.length > 0 && (
                    <chakra.Box mt={4}>
                      <ColumnMappingConfig
                        selectedLocationId={selectedSupplierLocationId}
                        selectedOrganisationId={selectedSupplierOrganisationId}
                        previewData={previewData}
                        setPreviewData={setPreviewData}
                        headerRow={headerRow}
                        onHeaderRowChange={(index) => {
                          initializeColumnConfigs(index);
                          setHeaderRow(index);
                        }}
                        startRow={startRow}
                        onStartRowChange={setStartRow}
                        endRow={endRow}
                        onEndRowChange={setEndRow}
                        onConfirm={handleConfirm}
                        onCancel={handleCancel}
                        columnTypeSelect={(header: string) => (
                          <ColumnTypeSelect
                            header={header}
                            currentTypes={
                              columnConfigs.find((c) => c.id === header)
                                ?.types || []
                            }
                            onChange={(header, newType) =>
                              handleColumnTypeChange(header, newType)
                            }
                          />
                        )}
                        columnConfigs={columnConfigs}
                        baseUnit={supplierBaseUnit}
                        setColumnConfigs={setColumnConfigs}
                        handleColumnTypeChange={handleColumnTypeChange}
                        selectionStep={selectionStep}
                        setSelectionStep={setSelectionStep}
                      />
                    </chakra.Box>
                  )}
                {currentView === "preview" && parsedData.length > 0 && (
                  <chakra.Box mt={4}>
                    <chakra.Flex justify="space-between" mb={4}>
                      <chakra.Button
                        leftIcon={<ArrowBackIcon />}
                        onClick={handleBackToMapping}
                      >
                        Back to Header Selection
                      </chakra.Button>
                    </chakra.Flex>
                    <VirtualizedDataTable
                      data={parsedData}
                      columns={columns}
                      isFullscreen={isFullscreen}
                    />
                  </chakra.Box>
                )}
              </>
            )}
          </chakra.ModalBody>
          <chakra.ModalFooter>
            <chakra.Button colorScheme="red" variant="ghost" onClick={onClose}>
              Close
            </chakra.Button>
            <chakra.Spacer />
            <chakra.Button
              colorScheme="teal"
              onClick={handleImport}
              isDisabled={!fileProcessed}
              isLoading={isImporting}
            >
              Import
            </chakra.Button>
          </chakra.ModalFooter>
        </chakra.ModalContent>
      </chakra.Modal>
      {isAddProductModalOpen && (
        <AddProductModal
          title={addProductDescription}
          isOpen={isAddProductModalOpen}
          onClose={handleAddProductClose}
          onProductCreated={handleProductCreated}
          onProductSelected={handleProductCreated}
          initialProductName={initialProductName}
        />
      )}
    </>
  );
};

export default ImportPricesModal;
