import { Td, useDisclosure } from "@chakra-ui/react";
import React from "react";
import InteractiveRow from "../../components/InteractiveRow/InteractiveRow";
import { SeedRecord } from "../../types";
import SeedRecordDetailModal from "./SeedRecordDetailModal.tsx";

interface SeedRecordViewEntryProps {
  index: number;
  seedRecord: SeedRecord;
}

export default function SeedRecordViewEntry({
  index,
  seedRecord,
}: SeedRecordViewEntryProps): React.ReactElement {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {isOpen && <SeedRecordDetailModal isOpen={isOpen} onClose={onClose} />}
      <InteractiveRow key={index} onClick={isOpen ? onClose : onOpen}>
        <Td fontSize="sm">{seedRecord.planted_at.slice(0, 10)}</Td>
        <Td fontSize="sm">
          {seedRecord.seed_product?.name || "Lettuce - Romaine"}
        </Td>
        <Td fontSize="sm">{seedRecord.seed_quantity}</Td>
        <Td fontSize="sm">
          {seedRecord.grow_area?.name || seedRecord.grow_area_id}
        </Td>
      </InteractiveRow>
    </>
  );
}
