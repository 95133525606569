import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useDisclosure,
  useToast,
  FormControl,
  FormLabel,
  Text,
  HStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  Badge,
  useMediaQuery,
  Input,
} from "@chakra-ui/react";
import InteractiveRow from "../../components/InteractiveRow/InteractiveRow";
import { Product } from "../../types";
import { badgeColors, getProductUnitLabel } from "../../utils/productUnitUtils";
import {
  useDeleteInventoryItemsFromUnitIdListMutation,
  useGetUnitsForProductQuery,
  usePostBarcodeListMutation,
  usePostInventoryItemListMutation,
  usePostProductUnitListMutation,
  usePutBarcodeListMutation,
  usePatchInventoryProductOrgCategoryMutation,
} from "../../redux/apiSlice";
import * as Sentry from "@sentry/react";
import ProductUnitManager from "../../components/ProductUnitManager/ProductUnitManager.tsx";
import { ProductUnitMgr } from "../../components/ProductUnitManager/types.ts";
import { userSlice } from "../../redux/userSlice.ts";
import { useAppSelector } from "../../redux/hooks.ts";
import {
  getProductCategoryLabel,
  productCategoryBadgeColors,
} from "../../utils/productUtils.ts";

interface ProductViewEntryProps {
  itemIndex: number;
  product: Product;
  children?: React.ReactNode[];
  onClick?: () => void;
}

export default function ProductViewEntry({
  itemIndex,
  product,
  children,
}: ProductViewEntryProps) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [productUnitMgr, setProductUnitMgr] = useState<ProductUnitMgr | null>(
    null,
  );

  const [customCategory, setCustomCategory] = useState<string>("");

  useEffect(() => {
    if (product?.org_category) {
      setCustomCategory(product.org_category);
    }
  }, [product]);

  const { data: productUnits = [], isLoading: isLoadingProductUnits } =
    useGetUnitsForProductQuery(product.id, {
      refetchOnMountOrArgChange: true,
      skip: !isOpen,
    });

  useEffect(() => {
    if (isOpen) {
      setProductUnitMgr(
        new ProductUnitMgr(
          productUnits,
          new Set(product.product_units?.map((unit) => unit.id)),
        ),
      );
    }
  }, [product.product_units, productUnits, isLoadingProductUnits, isOpen]);

  const [
    postProductUnits,
    { isLoading: isPostingProductUnits, error: postProductUnitsError },
  ] = usePostProductUnitListMutation();

  const [
    postInventoryItemList,
    { isLoading: isPostingInventoryItems, error: postInventoryItemsError },
  ] = usePostInventoryItemListMutation();
  const [
    deleteInventoryItemListFromUnitIdList,
    { isLoading: isDeletingInventoryItems, error: deleteInventoryItemsError },
  ] = useDeleteInventoryItemsFromUnitIdListMutation();

  const [
    postBarcodeList,
    { isLoading: isPostingBarcode, error: postBarcodeListError },
  ] = usePostBarcodeListMutation();
  const [
    putBarcodeList,
    { isLoading: isPuttingBarcode, error: putBarcodeListError },
  ] = usePutBarcodeListMutation();

  const [patchProductOrgCategory] =
    usePatchInventoryProductOrgCategoryMutation();

  const { getCurrentLocationId, getCurrentOrganisationId } =
    userSlice.selectors;
  const locationId = useAppSelector(getCurrentLocationId);
  const organisationId = useAppSelector(getCurrentOrganisationId);

  useEffect(() => {
    if (isSaving) {
      if (
        !isPostingProductUnits &&
        !isPostingInventoryItems &&
        !isDeletingInventoryItems &&
        !isPostingBarcode &&
        !isPuttingBarcode
      ) {
        if (
          postProductUnitsError ||
          postInventoryItemsError ||
          deleteInventoryItemsError ||
          postBarcodeListError ||
          putBarcodeListError
        ) {
          const message = [
            postProductUnitsError,
            postInventoryItemsError,
            deleteInventoryItemsError,
            postBarcodeListError,
            putBarcodeListError,
          ]
            .filter((error) => error)
            .map((error) => error?.data?.detail || error?.message)
            .join("\n");

          toast({
            title: "Error",
            description: "Failed to save product units: " + message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Success",
            description: "Product updated successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setTimeout(() => {
            onClose();
          }, 1000);
        }
        setIsSaving(false);
      }
    }
  }, [
    isPostingProductUnits,
    isPostingInventoryItems,
    isDeletingInventoryItems,
    isPostingBarcode,
    isPuttingBarcode,
  ]);

  const handleAddProductUnit = async () => {
    if (!productUnitMgr) {
      return;
    }
    setIsSaving(true);
    const newProductUnits = productUnitMgr.newProductUnits;
    const newInventoryUnitIds = productUnitMgr.getNewInventoryUnitIds();
    const removedInventoryUnitIds = productUnitMgr.getRemovedInventoryUnitIds();
    const newBarcodes = productUnitMgr.getNewBarcodes();
    const updatedBarcodes = productUnitMgr.getUpdatedBarcodes();
    if (
      !(
        newProductUnits.length ||
        newInventoryUnitIds.length ||
        removedInventoryUnitIds.length ||
        newBarcodes.length ||
        updatedBarcodes.length ||
        customCategory !== product.org_category
      )
    ) {
      toast({
        title: "No units to add or modify.",
        description: "Please add or modify units before saving.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const errors = {
      newProductUnits: null,
      newInventoryUnitIds: null,
      removedInventoryUnitIds: null,
      newBarcodes: null,
      updatedBarcodes: null,
      customCategory: null,
    };

    const requests = [];

    if (newInventoryUnitIds.length) {
      if (!organisationId) {
        toast({
          title: "No organisation ID.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      requests.push(
        postInventoryItemList({
          organisationId,
          inventoryItems: newInventoryUnitIds.map((productUnitId) => ({
            product_id: product.id,
            product_unit_id: productUnitId,
            organisation_id: organisationId,
          })),
        }).catch((error) => {
          try {
            Sentry.captureException(JSON.stringify(error));
          } catch {
            Sentry.captureException(error);
          }
          errors["newInventoryUnitIds"] = error;
        }),
      );
    }

    if (newBarcodes.length) {
      requests.push(
        postBarcodeList({
          barcodes: newBarcodes,
        }).catch((error) => {
          try {
            Sentry.captureException(JSON.stringify(error));
          } catch {
            Sentry.captureException(error);
          }
          errors["newBarcodes"] = error;
        }),
      );
    }

    if (updatedBarcodes.length) {
      requests.push(
        putBarcodeList({
          barcodes: updatedBarcodes,
        }).catch((error) => {
          try {
            Sentry.captureException(JSON.stringify(error));
          } catch {
            Sentry.captureException(error);
          }
          errors["updatedBarcodes"] = error;
        }),
      );
    }

    if (removedInventoryUnitIds.length) {
      if (!organisationId) return;
      requests.push(
        deleteInventoryItemListFromUnitIdList({
          organisationId,
          productUnitIds: removedInventoryUnitIds,
        }).catch((error) => {
          try {
            Sentry.captureException(JSON.stringify(error));
          } catch {
            Sentry.captureException(error);
          }
          errors["removedInventoryUnitIds"] = error;
        }),
      );
    }

    requests.push(
      postProductUnits({
        organisationId,
        locationId,
        productId: product.id,
        productUnits: newProductUnits,
      }).catch((error) => {
        try {
          Sentry.captureException(JSON.stringify(error));
        } catch {
          Sentry.captureException(error);
        }
        errors["newProductUnits"] = error;
      }),
    );

    if (customCategory !== product.org_category) {
      requests.push(
        patchProductOrgCategory({
          organisationId: organisationId || 0,
          productId: product.id,
          category: customCategory,
        }).catch((error) => {
          try {
            Sentry.captureException(JSON.stringify(error));
          } catch {
            Sentry.captureException(error);
          }
          errors["customCategory"] = error;
        }),
      );
    }

    await Promise.all(requests);
  };

  const [isTablet] = useMediaQuery("(max-width: 1024px)");

  return (
    <InteractiveRow key={itemIndex} onClick={onOpen}>
      <Modal
        size={"6xl"}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent p={4}>
          <ModalHeader>{product.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody padding={isTablet ? 6 : "auto"}>
            <HStack>
              <FormControl>
                <FormLabel>Product</FormLabel>
                <Text>{product.name}</Text>
              </FormControl>
              <FormControl>
                <FormLabel>Units</FormLabel>
                {product.product_units?.map((unit, unitIndex) => (
                  <Badge
                    colorScheme={badgeColors[unitIndex % 8]}
                    fontSize="0.8em"
                    key={unit.id}
                    marginRight="0.5rem"
                  >
                    {getProductUnitLabel(unit)}
                  </Badge>
                ))}
              </FormControl>
              {/*<FormControl>*/}
              {/*  <FormLabel>Default Reporting Unit</FormLabel>*/}
              {/*  <Select>*/}
              {/*    {product.product_units?.map((unit) => (*/}
              {/*      <option key={unit.id} value={unit.id}>*/}
              {/*        {getProductUnitLabel(unit)}*/}
              {/*      </option>*/}
              {/*    ))}*/}
              {/*  </Select>*/}
              {/*</FormControl>*/}
            </HStack>
            <HStack marginTop={4}>
              <FormControl>
                <FormLabel>Category</FormLabel>
                <Badge
                  colorScheme={productCategoryBadgeColors(product.category)}
                  fontSize="0.8em"
                  marginRight="0.5rem"
                >
                  {getProductCategoryLabel(product.category)}
                </Badge>
              </FormControl>
              <FormControl>
                <FormLabel>Custom Category</FormLabel>
                <Input
                  placeholder="Custom Category"
                  value={customCategory}
                  onChange={(e) => {
                    setCustomCategory(e.target.value);
                  }}
                />
              </FormControl>
            </HStack>
            <Accordion defaultIndex={[0]} allowMultiple marginTop={"1rem"}>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <b>Units</b>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  {productUnitMgr && (
                    <ProductUnitManager
                      product={product}
                      productUnitMgr={productUnitMgr}
                    />
                  )}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="teal"
              mr={3}
              onClick={() => handleAddProductUnit()}
              isLoading={
                isPostingProductUnits ||
                isPostingInventoryItems ||
                isDeletingInventoryItems
              }
            >
              Save
            </Button>
            <Spacer />
            <Button
              colorScheme="red"
              variant={"ghost"}
              mr={3}
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {children}
    </InteractiveRow>
  );
}
