import {
  Box,
  Button,
  Collapse,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Spinner,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Text,
  useMediaQuery,
  useSteps,
  useToast,
} from "@chakra-ui/react";
import CreateOrderList from "./CreateOrderList.tsx";
import React, { useEffect, useState } from "react";
import {
  Order,
  OrderBase,
  OrderItem,
  Price,
  Product,
  ProductUnit,
} from "../../types.ts";
import InventoryProductSearch from "../../components/InventoryProductSearch/InventoryProductSearch.tsx";
import { userSlice } from "../../redux/userSlice.ts";
import { useAppSelector } from "../../redux/hooks.ts";
import {
  useGetInventoryProductsQuery,
  useGetSupplierPricesQuery,
  useGetSuppliersQuery,
  usePostOrderListMutation,
} from "../../redux/apiSlice.ts";
import { skipToken } from "@reduxjs/toolkit/query";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";
import OrderAggregator from "./OrderAggregator.tsx";

interface OrderListModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export type OrderListItem = {
  product_id: number;
  product: Product;
  product_unit_id: number;
  product_unit: ProductUnit;
  quantity: number | string;
  suggested_price: Price;
  selected_price: Price;
  prices: Price[];
  total: number;
};

const OrderListModal = ({
  isOpen,
  onClose,
}: OrderListModalProps): React.ReactElement => {
  const toast = useToast();
  const { getCurrentLocationId, getCurrentOrganisationId, getUserId } =
    userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const locationId = useAppSelector(getCurrentLocationId);
  const userId = useAppSelector(getUserId);

  const { data: products, isLoading: productsLoading } =
    useGetInventoryProductsQuery(organisationId ?? skipToken);

  const { data: suppliers, isLoading: suppliersLoading } = useGetSuppliersQuery(
    {
      organisationId,
      locationId,
    },
  );

  const {
    data: supplierPrices,
    isLoading: supplierPricesLoading,
    error: supplierPricesError,
  } = useGetSupplierPricesQuery(
    suppliers?.map((supplier) => supplier.id) ?? [],
    {
      skip: !suppliers || suppliers.length === 0,
    },
  );

  const [postOrderList, { isLoading: isPostingOrderList }] =
    usePostOrderListMutation();

  const [supplierIdToNameMap, setSupplierIdToNameMap] = useState<
    Map<number, string>
  >(
    suppliers
      ? new Map(suppliers.map((supplier) => [supplier.id, supplier.name]))
      : new Map(),
  );

  const [cachedOrderList, setCachedOrderList] = React.useState<OrderListItem[]>(
    [],
  );
  const [orderList, setOrderList] = React.useState<OrderListItem[]>([]);
  const [availablePrices, setAvailablePrices] = React.useState<
    Map<number, { suggested: Price; available: Price[] }>
  >(new Map());

  const [selectedProductId, setSelectedProductId] = useState<
    number | null | undefined
  >(null);

  const [searchTerm, setSearchTerm] = useState<string>("");

  const [baseUnit, setBaseUnit] = useState<string>("kg");

  const steps = [
    { title: "First", description: "Quantities & Pricing" },
    { title: "Second", description: "Confirm Orders" },
  ];

  const [sectionOpen, setSectionOpen] = React.useState(true);
  const [isStepOneValid, setIsStepOneValid] = useState<boolean>(false);

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });
  const [isMobile] = useMediaQuery("(max-width: 478px)");
  const [isFullscreen, setIsFullscreen] = useState<boolean>(true);

  const [orders, setOrders] = React.useState<OrderBase[]>([]);

  useEffect(() => {
    if (
      locationId &&
      organisationId &&
      cachedOrderList &&
      cachedOrderList.length > 0
    ) {
      const supplierIdToOrderItemsMap = new Map<number, OrderItem[]>();
      cachedOrderList.forEach((item) => {
        if (item.quantity || Number(item.quantity) !== 0) {
          const newOrderItem = {
            product_id: item.product_id,
            product: item.product,
            product_unit_id: item.product_unit_id,
            product_unit: item.selected_price.product_unit,
            quantity: item.quantity,
            price: item.selected_price.price,
            total: item.total,
          } as OrderItem;
          const currentOrderItems = supplierIdToOrderItemsMap.get(
            item.selected_price.supplier_organisation_id,
          );
          if (currentOrderItems) {
            supplierIdToOrderItemsMap.set(
              item.selected_price.supplier_organisation_id,
              [...currentOrderItems, newOrderItem],
            );
          } else {
            supplierIdToOrderItemsMap.set(
              item.selected_price.supplier_organisation_id,
              [newOrderItem],
            );
          }
        }
      });
      const newOrders = Array.from(supplierIdToOrderItemsMap.entries()).map(
        ([supplierId, orderItems]) => {
          return {
            supplier_organisation_id: supplierId,
            location_id: Number(locationId),
            organisation_id: organisationId,
            user_id: userId,
            order_items: orderItems,
            order_date: new Date().toISOString(),
            // TODO: replace with Supplier delivery date
            delivery_date: new Date(
              Date.now() + Math.random() * 7 * 24 * 60 * 60 * 1000,
            ).toISOString(),
            total: orderItems.reduce((acc, cur) => acc + Number(cur.total), 0),
            is_pickup: false,
          } as Order;
        },
      );
      setOrders(newOrders);
    }
  }, [cachedOrderList]);

  useEffect(() => {
    if (suppliers && suppliers.length) {
      const supplierMap = new Map<number, string>();
      suppliers.forEach((supplier) => {
        supplierMap.set(supplier.id, supplier.name);
      });
      setSupplierIdToNameMap(supplierMap);
    }
  }, [suppliers]);

  useEffect(() => {
    if (!isOpen) return;
    if (supplierPrices && supplierPrices.length) {
      const priceMap = new Map<
        number,
        { suggested: Price; available: Price[] }
      >();

      supplierPrices.forEach((price) => {
        const existingPriceInfo = priceMap.get(price.product_id);
        const productUnitQuantity = price.product_unit?.unit_quantity || 1;
        const baseUnitQuantity =
          baseUnit === price.product_unit?.unit_measure
            ? productUnitQuantity
            : baseUnit === "kg"
            ? productUnitQuantity * 2.20462
            : productUnitQuantity * 0.453592;
        const priceCalcd = {
          ...price,
          price_per_base_unit:
            price.price /
            (price.product_unit?.unit_measure === "unit"
              ? productUnitQuantity
              : baseUnitQuantity),
        };
        if (existingPriceInfo) {
          // Update the existing array of prices
          existingPriceInfo.available.push(priceCalcd);

          if (
            priceCalcd.price_per_base_unit <
            (existingPriceInfo.suggested.price_per_base_unit || 1)
          ) {
            existingPriceInfo.suggested = priceCalcd;
          }
          priceMap.set(price.product_id, existingPriceInfo);
        } else {
          // Initialize a new array of prices for this productUnitId
          priceMap.set(priceCalcd.product_id, {
            suggested: priceCalcd,
            available: [priceCalcd],
          });
        }
      });

      setAvailablePrices(priceMap);
    } else if (supplierPrices && !supplierPricesLoading) {
      if (supplierPricesError) {
        toast({
          title: "Failed to load supplier prices.",
          description: "Please try again later",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "No supplier prices found.",
          description: "Please import prices to create an order list.",
          status: "warning",
          duration: 3000,
        });
      }
    }
  }, [
    isOpen,
    baseUnit,
    supplierPrices,
    supplierPricesLoading,
    supplierPricesError,
  ]);

  useEffect(() => {
    if (products && products.length) {
      const newOrderList = products
        .slice()
        .filter(
          (product) =>
            product.name.length > 0 &&
            product.category === "PRODUCE" &&
            availablePrices.has(product.id),
        )
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((product) => {
          // find the first 1lb unit, default to the first unit if not found
          const priceInfo = availablePrices.get(product.id);
          let baseProductUnit = product.product_units.find(
            (unit) =>
              unit.unit_measure === "lb" && Number(unit.unit_quantity) === 1,
          );
          if (!baseProductUnit) {
            baseProductUnit = product.product_units.find(
              (unit) => unit.unit_measure === "lb",
            );
          }
          if (!baseProductUnit) {
            baseProductUnit = product.product_units[0];
          }

          return {
            product_id: product.id,
            product,
            product_unit_id:
              priceInfo?.suggested?.product_unit_id || baseProductUnit.id,
            product_unit: priceInfo?.suggested?.product_unit || baseProductUnit,
            quantity: 0,
            suggested_price: priceInfo?.suggested,
            selected_price: priceInfo?.suggested,
            //TODO: remove this logic once we have BE implementation to handle the correct id for the price object
            prices:
              priceInfo?.available
                .sort((a, b) => a.price - b.price)
                .map((price) => ({
                  ...price,
                })) || [],
            total: 0,
          } as OrderListItem;
        });
      setCachedOrderList(newOrderList);
      setOrderList(newOrderList);
    }
  }, [products, availablePrices, isOpen, onClose]);

  useEffect(() => {
    if (selectedProductId) {
      setOrderList(
        cachedOrderList.filter((item) => item.product_id === selectedProductId),
      );
    } else if (searchTerm) {
      setOrderList(
        cachedOrderList.filter((item) =>
          item.product.name.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      );
    } else {
      setOrderList(cachedOrderList);
    }
  }, [selectedProductId, cachedOrderList]);

  useEffect(() => {
    // valid if at least one quantity has been set
    setIsStepOneValid(
      Boolean(
        orderList.find((item) => item.quantity !== 0 && item.quantity !== "0"),
      ),
    );
  }, [orderList]);

  const handleCachedQuantityChange = (
    index: number,
    updatedQuantity: number,
  ) => {
    const newItems = cachedOrderList.slice();
    newItems[index] = {
      ...newItems[index],
      quantity: updatedQuantity,
      total:
        updatedQuantity === 0
          ? 0
          : updatedQuantity * newItems[index].selected_price?.price || 1,
    };
    setCachedOrderList(newItems);
  };

  const handleCachedSelectedPriceChange = (
    index: number,
    selectedPrice: Price,
  ) => {
    const newItems = cachedOrderList.slice();
    newItems[index] = {
      ...newItems[index],
      selected_price: selectedPrice,
      product_unit_id: selectedPrice.product_unit_id,
      total:
        newItems[index].quantity === 0
          ? 0
          : newItems[index].quantity * selectedPrice.price || 1,
    };
    setCachedOrderList(newItems);
  };

  const handleAddOrderListItem = (
    orderListItem: OrderListItem,
    index: number,
  ) => {
    // insert orderListItem after index
    const newOrderList = [...cachedOrderList];
    newOrderList.splice(index + 1, 0, {
      ...orderListItem,
      prices: availablePrices.get(orderListItem.product_id)?.available || [],
      quantity: 0,
      total: 0,
    });
    setCachedOrderList(newOrderList);
  };

  const handleSectionChange = (index: number) => {
    if (index !== activeStep) {
      setSectionOpen(false);
      setTimeout(() => {
        setActiveStep(index);
        setSectionOpen(true);
      }, 400);
    }
  };

  const nextStep = () => {
    if (activeStep < steps.length) {
      handleSectionChange(activeStep + 1);
    }
  };

  const prevStep = () => {
    if (activeStep > 0) {
      handleSectionChange(activeStep - 1);
    }
  };

  const handleChangeStep = (index: number) => {
    if (index !== activeStep) {
      if (index > activeStep) {
        nextStep();
      } else {
        prevStep();
      }
    }
  };

  const handleSave = () => {
    if (isPostingOrderList) return;
    postOrderList(orders.filter((order) => order.order_items.length > 0))
      .unwrap()
      .then(() => {
        toast({
          title: "Order list created successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onClose();
        setActiveStep(0);
        const quantityResetItems = cachedOrderList.map((item) => ({
          ...item,
          quantity: 0,
        }));
        setCachedOrderList(quantityResetItems);
        setOrderList([]);
      })
      .catch(() => {
        toast({
          title: "Failed to create order list.",
          description: "Please try again later",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const handleTransferOrders = (
    fromSupplierId: number,
    toSupplierId: number,
    orderItems: OrderItem[],
  ) => {
    // find transferable order items - available price required. Update price on transfer to new supplier price
    const nonTransferableOrderItems = orderItems.filter(
      (orderItem) =>
        !availablePrices
          .get(orderItem.product_id)
          ?.available.some(
            (price) =>
              price.supplier_organisation_id === toSupplierId &&
              price.product_unit_id === orderItem.product_unit_id,
          ),
    );
    const transferableOrderItems = orderItems.filter(
      (orderItem) =>
        availablePrices
          .get(orderItem.product_id)
          ?.available.some(
            (price) =>
              price.supplier_organisation_id === toSupplierId &&
              price.product_unit_id === orderItem.product_unit_id,
          ),
    );
    if (transferableOrderItems && transferableOrderItems.length > 0) {
      const totalToBeDeducted = transferableOrderItems.reduce(
        (acc, cur) => acc + Number(cur.total),
        0,
      );
      const orderItemsToTransfer = transferableOrderItems.map((orderItem) => {
        const priceInfo = availablePrices.get(orderItem.product_id);
        const toSupplierPrices = priceInfo?.available.filter(
          (price) =>
            price.supplier_organisation_id === toSupplierId &&
            price.product_unit_id === orderItem.product_unit_id,
        );
        if (toSupplierPrices && toSupplierPrices.length > 0) {
          const bestPrice = toSupplierPrices.reduce((acc, cur) => {
            if (acc.price_per_base_unit < cur.price_per_base_unit) {
              return cur;
            }
            return acc;
          });
          toast({
            title: `Transferred ${orderItem.quantity} of ${
              orderItem.product.name
            } from ${supplierIdToNameMap.get(
              fromSupplierId,
            )} to ${supplierIdToNameMap.get(toSupplierId)}`,
            description: `Used best price per unit for supplier of $${bestPrice.price_per_base_unit} per ${bestPrice.product_unit.unit_measure}`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          console.log("bestPrice: ", bestPrice);
          return {
            ...orderItem,
            selected_price: bestPrice,
            price: bestPrice.price,
            total: orderItem.quantity * Number(bestPrice.price),
          };
        }
        return orderItem;
      });
      setOrders((prevOrders) => {
        let newOrders = prevOrders.map((order) => {
          if (order.supplier_organisation_id === fromSupplierId) {
            return {
              ...order,
              order_items: order.order_items.filter(
                (item) =>
                  !transferableOrderItems.some(
                    (transferItem) => transferItem === item,
                  ),
              ),
              total: order.total - totalToBeDeducted,
            };
          }
          return order;
        });

        const existingToOrder = newOrders.find(
          (order) => order.supplier_organisation_id === toSupplierId,
        );

        if (existingToOrder) {
          newOrders = newOrders.map((order) => {
            if (order.supplier_organisation_id === toSupplierId) {
              return {
                ...order,
                order_items: [...order.order_items, ...orderItemsToTransfer],
                total:
                  order.total +
                  orderItemsToTransfer.reduce(
                    (acc, item) => acc + item.total,
                    0,
                  ),
              };
            }
            return order;
          });
        } else {
          // Create a new order for the supplier if it doesn't exist
          const newOrder: OrderBase = {
            supplier_organisation_id: toSupplierId,
            location_id: Number(locationId),
            organisation_id: Number(organisationId),
            user_id: userId,
            order_items: orderItemsToTransfer,
            order_date: new Date().toISOString(),
            delivery_date: new Date(
              Date.now() + Math.random() * 7 * 24 * 60 * 60 * 1000,
            ).toISOString(),
            total: orderItemsToTransfer.reduce(
              (acc, item) => acc + item.total,
              0,
            ),
            is_pickup: false,
          };
          newOrders.push(newOrder);
        }
        return newOrders;
      });
    }
    if (nonTransferableOrderItems.length > 0) {
      toast({
        title: `Failed to transfer ${
          nonTransferableOrderItems.length
        } item(s) from ${supplierIdToNameMap.get(
          fromSupplierId,
        )} to ${supplierIdToNameMap.get(toSupplierId)}`,
        description: `Not enough prices available for transfer`,
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    }
  };

  const handleRemoveOrderItemFromOrderItems = (orderItem: OrderItem) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) => ({
        ...order,
        order_items: order.order_items.filter(
          (item) => item.product_id !== orderItem.product_id,
        ),
      })),
    );
  };

  const handleEditOrderItemQuantity = (orderItem: OrderItem) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) => ({
        ...order,
        order_items: order.order_items.map((item) =>
          item.product_id === orderItem.product_id ? orderItem : item,
        ),
      })),
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={isFullscreen ? "full" : "5xl"}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex alignItems="center">
            <Heading>Create Order List</Heading>
            <Spacer />
            <Spacer />
            <Spacer />
            <Text>Base Unit: </Text>
            <Select
              w={"100px"}
              value={baseUnit}
              onChange={(e) => setBaseUnit(e.target.value as string)}
            >
              <option value="lb">lb</option>
              <option value="kg">kg</option>
            </Select>
            <Spacer />
            <Button
              onClick={() => setIsFullscreen(!isFullscreen)}
              size="md"
              variant="outline"
              mr={8}
            >
              {isFullscreen ? (
                <AiOutlineFullscreenExit />
              ) : (
                <AiOutlineFullscreen />
              )}
            </Button>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stepper
            index={activeStep}
            colorScheme={"teal"}
            width={"100%"}
            gap={0}
            marginBottom={"16px"}
            orientation={isMobile ? "vertical" : "horizontal"}
          >
            {steps.map((step, index) => (
              <Step key={step.title} onClick={() => handleChangeStep(index)}>
                <StepIndicator>
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                </StepIndicator>
                <Box flexShrink="0" ml={[4, 0]}>
                  <StepTitle>
                    <Text fontSize={{ base: "sm", md: "md" }}>
                      {step.title}
                    </Text>
                  </StepTitle>
                  <StepDescription>
                    <Text fontSize={{ base: "xxs", md: "sm" }}>
                      {step.description}
                    </Text>
                  </StepDescription>
                </Box>
                <StepSeparator />
              </Step>
            ))}
          </Stepper>
          <Collapse in={sectionOpen} animateOpacity>
            {activeStep === 0 && (
              <InventoryProductSearch
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                setProductFilterId={setSelectedProductId}
                includeSearchIcon
              />
            )}
            <Box pt={4}>
              {activeStep === 0 ? (
                <>
                  {productsLoading || suppliersLoading ? (
                    <Spinner />
                  ) : (
                    <CreateOrderList
                      baseUnit={baseUnit}
                      orderList={orderList || []}
                      isFullscreen={isFullscreen}
                      handleCachedQuantityChange={handleCachedQuantityChange}
                      handleSelectedPriceChange={
                        handleCachedSelectedPriceChange
                      }
                      handleAddOrderListItem={handleAddOrderListItem}
                      supplierIdToNameMap={supplierIdToNameMap}
                    />
                  )}
                </>
              ) : (
                <OrderAggregator
                  isFullscreen={isFullscreen}
                  orders={orders}
                  supplierIdToNameMap={supplierIdToNameMap}
                  onTransferOrders={handleTransferOrders}
                  onRemoveOrderItem={handleRemoveOrderItemFromOrderItems}
                  onEditOrderItemQuantity={handleEditOrderItemQuantity}
                />
              )}
            </Box>
          </Collapse>
        </ModalBody>
        <ModalFooter flexDirection="column" width="100%" px={4} py={6}>
          <Flex
            width="100%"
            justifyContent="space-between"
            flexWrap="wrap"
            gap={2}
          >
            <Button
              colorScheme="gray"
              onClick={prevStep}
              flex={{ base: "1 1 100%", sm: "0 1 auto" }}
              mb={{ base: 2, sm: 0 }}
            >
              Back
            </Button>
            <Flex
              gap={2}
              flex={{ base: "1 1 100%", sm: "0 1 auto" }}
              justifyContent={{ base: "space-between", sm: "flex-end" }}
            >
              {activeStep < steps.length - 1 ? (
                <Button
                  onClick={nextStep}
                  isDisabled={!isStepOneValid}
                  flex={{ base: 1, sm: "0 1 auto" }}
                >
                  Next
                </Button>
              ) : (
                <Button
                  colorScheme="teal"
                  onClick={handleSave}
                  isLoading={isPostingOrderList}
                  flex={{ base: 1, sm: "0 1 auto" }}
                >
                  Submit
                </Button>
              )}
            </Flex>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OrderListModal;
