import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  IconButton,
  Select,
  Text,
} from "@chakra-ui/react";
import { createColumnHelper, RowSelectionState } from "@tanstack/react-table";
import React, { useCallback, useMemo, useState } from "react";
import DataTable from "../../components/DataTable/DataTable";
import { OrderBase, OrderItem, Price } from "../../types";
import { getProductUnitLabel } from "../../utils/productUnitUtils";
import { default as EditableQuantity } from "./EditableQuantity";

interface TransferableOrderSummaryProps {
  order: OrderBase;
  supplierIdToNameMap: Map<number, string>;
  onTransferOrders: (
    fromSupplierId: number,
    toSupplierId: number,
    orderItems: OrderItem[],
  ) => void;
  onRemoveOrderItem: (orderItem: OrderItem) => void;
  onEditOrderItemQuantity: (orderItem: OrderItem) => void;
}

export default function TransferableOrderSummary({
  supplierIdToNameMap,
  onTransferOrders,
  order,
  onRemoveOrderItem,
  onEditOrderItemQuantity,
}: TransferableOrderSummaryProps): React.ReactElement {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [targetSupplier, setTargetSupplier] = useState<number | string>("");

  const currentSupplierId = order.supplier_organisation_id;
  const orderItems = order.order_items;

  const handleTransfer = useCallback(() => {
    if (targetSupplier && targetSupplier !== currentSupplierId) {
      const itemsToTransfer = orderItems.filter(
        (_, index) => rowSelection[index],
      );
      if (itemsToTransfer.length > 0) {
        onTransferOrders(
          currentSupplierId,
          Number(targetSupplier),
          itemsToTransfer,
        );
        setRowSelection({});
      }
    }
  }, [
    targetSupplier,
    currentSupplierId,
    orderItems,
    rowSelection,
    onTransferOrders,
  ]);

  const columnHelper = createColumnHelper<OrderItem>();

  const handleRemoveItem = useCallback(
    (index: number) => {
      onRemoveOrderItem(orderItems[index]);
    },
    [orderItems, onRemoveOrderItem],
  );
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.id, {
        id: "select",
        header: ({ table }) => (
          <Checkbox
            isChecked={table.getIsAllRowsSelected()}
            isIndeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            colorScheme="teal"
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            isChecked={row.getIsSelected()}
            isDisabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
            colorScheme="teal"
          />
        ),
        size: 50,
      }),
      columnHelper.accessor("product", {
        cell: (info) => info.getValue()?.name,
        header: "Product",
        size: 200,
      }),
      columnHelper.accessor("product_unit", {
        cell: (info) => getProductUnitLabel(info.getValue()),
        header: "Unit",
        size: 90,
      }),
      columnHelper.accessor("quantity", {
        cell: (info) => (
          <EditableQuantity
            value={info.getValue()}
            onChange={(newValue) => {
              const updatedItem = {
                ...info.row.original,
                quantity: newValue,
                total: newValue * info.row.original.price,
              };
              onEditOrderItemQuantity(updatedItem);
            }}
          />
        ),
        header: "Quantity",
        size: 120,
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor("price", {
        cell: (info) => `$${Number(info.getValue()).toFixed(2)}`,
        header: "Price",
        size: 120,
        meta: {
          isNumeric: true,
          isCurrency: true,
        },
      }),
      columnHelper.accessor("total", {
        cell: (info) => `$${Number(info.getValue()).toFixed(2)}`,
        header: "Total",
        size: 120,
        meta: {
          isNumeric: true,
          isCurrency: true,
        },
      }),
      columnHelper.accessor((row) => row.id, {
        cell: ({ row }) => (
          <IconButton
            aria-label="Remove item"
            icon={<DeleteIcon />}
            size="sm"
            colorScheme="gray"
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveItem(row.index);
            }}
          />
        ),
        header: "Actions",
        size: 5,
      }),
    ],
    [columnHelper, handleRemoveItem, onEditOrderItemQuantity],
  );

  const getMinimumOrderValue = () => {
    // get a random value between 100 and 300, in increments of 50
    // return Math.floor(Math.random() * 5) * 50 + 100;
    return undefined;
  };

  return (
    <Box>
      <Flex>
        <Box>
          <HStack justifyContent="space-between" mb={2}>
            <Box fontWeight="bold">
              Supplier:{" "}
              {supplierIdToNameMap.get(order.supplier_organisation_id)}
            </Box>
          </HStack>
          <Box>
            Minimum Order Value: <b>${getMinimumOrderValue()}</b>
          </Box>
          <Box>Order Date: {undefined}</Box>
          {/*<Box>Order Date: {order.order_date.slice(0, 10)}</Box>*/}
          <Box>Next Delivery Date: {undefined}</Box>
          {/*<Box>Next Delivery Date: {order.delivery_date.slice(0, 10)}</Box>*/}
        </Box>
        <Flex
          mb={4}
          flexDirection="column"
          gap={4}
          width="fit-content"
          alignItems="flex-end"
          ml="auto"
        >
          <Select
            placeholder="Transfer to..."
            value={targetSupplier}
            onChange={(e) => setTargetSupplier(e.target.value)}
            width="-webkit-fill-available"
          >
            {Array.from(supplierIdToNameMap.entries())
              .filter(([id]) => id !== currentSupplierId)
              .map(([id, name]) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
          </Select>
          <Button
            colorScheme="teal"
            isDisabled={
              !targetSupplier ||
              targetSupplier === currentSupplierId.toString() ||
              !Object.values(rowSelection).some(Boolean)
            }
            onClick={handleTransfer}
          >
            Transfer Selected Items
          </Button>
        </Flex>
      </Flex>
      <Box
        overflowX="auto"
        maxWidth="100%"
        border="1px solid"
        borderColor="gray.200"
        borderRadius="md"
      >
        <Box minWidth="500px">
          <DataTable
            data={orderItems}
            columns={columns}
            enableRowSelection={true}
            onRowSelectionChange={setRowSelection}
            rowSelection={rowSelection}
          />
        </Box>
      </Box>
      <Flex justifyContent="flex-end" mt={4}>
        <Text fontSize="lg" fontWeight="bold">
          Total: $
          {orderItems.reduce((acc, item) => acc + item.total, 0).toFixed(2)}
        </Text>
      </Flex>
    </Box>
  );
}
