export const isColumnEmpty = (
  data: string[][],
  columnIndex: number,
): boolean => {
  return data.every(
    (row) =>
      !row[columnIndex] ||
      row[columnIndex].trim() === "" ||
      row[columnIndex] === "-",
  );
};

export const getNonEmptyColumns = (data: string[][]): number[] => {
  if (!data.length || !data[0]) return [];

  return data[0].reduce((acc: number[], _, index) => {
    if (!isColumnEmpty(data, index)) {
      acc.push(index);
    }
    return acc;
  }, []);
};
