import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Button,
  Checkbox,
  IconButton,
  Td,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import InteractiveRow from "../../components/InteractiveRow/InteractiveRow";
import { StockTakeEvent, StockTakeEventType, StorageArea } from "../../types";
import { formatDateTimeString } from "../../utils/dateUtils.ts";
import StockTakeDrawer from "./StockTakeDrawer.tsx";
import { useDeleteStockTakeEventMutation } from "../../redux/apiSlice.ts";
import { MdDeleteForever } from "react-icons/md";
import * as Sentry from "@sentry/react";

interface StockTakeEventViewEntryProps {
  index: number;
  stockTakeEvent: StockTakeEvent;
  storageAreas: StorageArea[];
  isChecked?: boolean;
  setChecked?: (value: boolean) => void;
}

export default function StockTakeEventViewEntry({
  index,
  stockTakeEvent,
  storageAreas,
  isChecked,
  setChecked,
}: StockTakeEventViewEntryProps): React.ReactElement {
  const toast = useToast();
  const [deleteStockTakeEvent] = useDeleteStockTakeEventMutation();

  const formatStockTakeEventStatus = (stockTakeEvent: StockTakeEvent) => {
    if (stockTakeEvent.status === "COMPLETED") {
      return <Badge colorScheme="green">Completed</Badge>;
    } else if (stockTakeEvent.status === "IN_PROGRESS") {
      return <Badge colorScheme="yellow">In Progress</Badge>;
    } else {
      return "Unknown";
    }
  };

  const formatStockTakeType = (stockTakeEvent: StockTakeEvent) => {
    if (stockTakeEvent.event_type === StockTakeEventType.FULL) {
      return <Badge colorScheme="blue">Full</Badge>;
    } else if (stockTakeEvent.event_type === StockTakeEventType.PARTIAL) {
      return <Badge colorScheme="green">Partial</Badge>;
    } else {
      return "Unknown";
    }
  };

  const [storageAreaNames, setStorageAreaNames] = useState<Set<string>>(
    new Set(),
  );

  useEffect(() => {
    const storageAreaIds = new Set();
    stockTakeEvent.stock_takes.forEach((stockTake) => {
      storageAreaIds.add(stockTake.storage_area_id);
    });
    const newStorageAreaNames = new Set<string>();
    storageAreas.forEach((storageArea) => {
      if (storageAreaIds.has(storageArea.id)) {
        newStorageAreaNames.add(storageArea.name);
      }
    });
    setStorageAreaNames(newStorageAreaNames);
  }, [stockTakeEvent, storageAreas]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteDialogOpen,
    onOpen: onDeleteDialogOpen,
    onClose: onDeleteDialogClose,
  } = useDisclosure();

  const handleDeleteStockTakeEvent = () => {
    deleteStockTakeEvent(stockTakeEvent)
      .unwrap()
      .then(() => {
        onDeleteDialogClose();
      })
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
        toast({
          title: "Error - failed to delete stock take event",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      {isOpen && (
        <StockTakeDrawer
          isOpen={isOpen}
          onClose={onClose}
          stockTakeEvent={stockTakeEvent}
        />
      )}
      {isDeleteDialogOpen && (
        <AlertDialog isOpen={isDeleteDialogOpen} onClose={onDeleteDialogClose}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Stock Take Event
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button onClick={onDeleteDialogClose}>Cancel</Button>
                <Button
                  colorScheme="red"
                  onClick={handleDeleteStockTakeEvent}
                  ml={3}
                >
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
      <InteractiveRow key={index} onClick={onOpen}>
        <Td fontSize="sm">
          <Checkbox
            isChecked={isChecked}
            colorScheme="teal"
            onChange={(e) => {
              e.stopPropagation();
              if (setChecked) setChecked(!isChecked);
              onClose();
            }}
          />
        </Td>
        <Td fontSize="sm">{formatDateTimeString(stockTakeEvent.start_time)}</Td>
        <Td fontSize="sm">{formatStockTakeType(stockTakeEvent)}</Td>
        <Td fontSize="sm">{formatStockTakeEventStatus(stockTakeEvent)}</Td>
        <Td fontSize="sm">
          {Array.from(storageAreaNames).map((name) => (
            <Badge>{name}</Badge>
          ))}
        </Td>
        <Td fontSize="sm">
          <IconButton
            size="sm"
            colorScheme="red"
            aria-label="Delete stock take event"
            icon={<MdDeleteForever />}
            onClick={(e) => {
              onDeleteDialogOpen();
              e.stopPropagation();
            }}
          />
        </Td>
      </InteractiveRow>
    </>
  );
}
