import { Button } from "@chakra-ui/react";
import { useEffect } from "react";
import {
  usePostQuickbooksOauthRedirectMutation,
  usePostQuickbooksRevokeAuthMutation,
} from "../../redux/apiSlice";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { userSlice } from "../../redux/userSlice";
import { QuickbooksCompanyInfo } from "../../types";

export function useQBOauthPopupListener() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const listener = (
      event: CustomEvent<{
        company: QuickbooksCompanyInfo;
      }>,
    ) => {
      const {
        detail: { company },
      } = event;
      dispatch(userSlice.actions.setConnectedToQuickbooks(true));
      dispatch(userSlice.actions.setQuickbooksUserCompany(company));
    };

    window.addEventListener("quickbooks_connected", listener as EventListener);

    return () => {
      window.removeEventListener(
        "quickbooks_connected",
        listener as EventListener,
      );
    };
  }, []);
}

export default function LoginButton() {
  const [redirectQuickbooksOauth] = usePostQuickbooksOauthRedirectMutation();
  const [revokeQuickbooksOauth] = usePostQuickbooksRevokeAuthMutation();
  const { getConnectedToQuickbooks } = userSlice.selectors;
  const connectedToQuickbooks = useAppSelector(getConnectedToQuickbooks);
  const dispatch = useAppDispatch();

  const connectToQuickbooksButton = new URL(
    "../../assets/images/connect-to-quickbooks.png",
    import.meta.url,
  ).href;

  return (
    <>
      {!connectedToQuickbooks ? (
        <Button
          style={{ padding: 0 }}
          onClick={async () => {
            redirectQuickbooksOauth({
              requesting_url: window.location.href,
            })
              .unwrap()
              .then(({ url }) => {
                window.open(
                  url,
                  "qbOauthPopup",
                  "menubar=0,resizable=1,width=420,height=690",
                );
              })
              .catch((error) => {
                console.error(error);
              });
          }}
        >
          <img
            className="w-64"
            src={connectToQuickbooksButton}
            alt={"Connect to QuickBooks"}
          />
        </Button>
      ) : (
        <>
          <Button
            colorScheme="red"
            onClick={() => {
              dispatch(userSlice.actions.setConnectedToQuickbooks(false));
              revokeQuickbooksOauth();
            }}
          >
            Disconnect From QuickBooks
          </Button>
        </>
      )}
    </>
  );
}
