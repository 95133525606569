import { Barcode, ProductUnit, ProductUnitBase } from "../../types.ts";
import { getBarcodeType } from "../../utils/barcodeUtils.ts";

export type ProductUnitSettings = ProductUnit & {
  is_new_unit: boolean;
  in_inventory: boolean;
  is_barcode_added: boolean;
  is_barcode_updated: boolean;
};

const getUnitSettings = (
  productUnit: ProductUnit,
  inventoryUnitIds: Set<number>,
): ProductUnitSettings => {
  return {
    ...productUnit,
    is_new_unit: Boolean(!productUnit.id),
    in_inventory: productUnit.id ? inventoryUnitIds.has(productUnit.id) : true,
  } as ProductUnitSettings;
};

export class ProductUnitMgr {
  public newProductUnits: ProductUnitBase[] = [];
  public productUnits: ProductUnitSettings[] = [];
  public inventoryUnitIds: Set<number>;
  public productUnitIndex: Map<string, number> = new Map(); // Map of product unit hash to index in productUnits

  constructor(productUnits: ProductUnit[], inventoryUnitIds: Set<number>) {
    this.productUnits = productUnits.map((productUnit) =>
      getUnitSettings(productUnit, inventoryUnitIds),
    );
    this.inventoryUnitIds = inventoryUnitIds;
    this.updateProductUnitIndex();
  }

  private getProductUnitHash(
    productUnit: ProductUnit | ProductUnitBase,
  ): string {
    return `${productUnit.unit_label} (${productUnit.unit_quantity}x${productUnit.unit_measure})`;
  }

  private updateProductUnitIndex(): void {
    this.productUnitIndex = new Map(
      this.productUnits.map((productUnit, index) => [
        this.getProductUnitHash(productUnit),
        index,
      ]),
    );
  }

  public addNewProductUnit(productUnit: ProductUnitBase): void {
    if (this.productUnitIndex.has(this.getProductUnitHash(productUnit))) {
      throw new Error("Duplicate product unit");
    }
    this.newProductUnits.push(productUnit);
    this.productUnits.push(
      getUnitSettings(productUnit as ProductUnit, new Set()),
    );
    this.updateProductUnitIndex();
  }

  public removeProductUnit(productUnit: ProductUnitSettings): void {
    if (productUnit.is_new_unit) {
      const index = this.newProductUnits.indexOf(productUnit);
      if (index > -1) {
        this.newProductUnits.splice(index, 1);
      }
    }
    this.updateProductUnitIndex();
  }

  public addProductUnitBarcode(
    productUnit: ProductUnitSettings,
    barcode: string,
  ): void {
    const index = this.productUnits.indexOf(productUnit);
    if (index > -1) {
      this.productUnits[index].barcode = {
        code: barcode,
        code_type: "", // only set when making request/when user stops typing
        product_id: productUnit.product_id,
        product_unit_id: productUnit.id,
      } as Barcode;
      if (!productUnit.is_new_unit) {
        this.productUnits[index].is_barcode_added = true;
      }
    }
  }

  public updateProductUnitBarcode(
    productUnit: ProductUnitSettings,
    barcode: string,
  ): void {
    const index = this.productUnits.indexOf(productUnit);
    if (index > -1) {
      this.productUnits[index].barcode = {
        id: productUnit.barcode?.id,
        code: barcode,
        code_type: "", // only set when making request/when user stops typing
        product_id: productUnit.product_id,
        product_unit_id: productUnit.id,
      } as Barcode;
      if (!productUnit.is_new_unit) {
        this.productUnits[index].is_barcode_updated = true;
      }
    }
  }

  public getNewBarcodes(): Barcode[] {
    return this.productUnits
      .filter((productUnit) => productUnit.is_barcode_added)
      .map((productUnit) => ({
        ...productUnit.barcode,
        code_type:
          productUnit.barcode?.code_type ||
          getBarcodeType(productUnit.barcode?.code || ""),
      }))
      .filter(Boolean) as Barcode[];
  }

  public getUpdatedBarcodes(): Barcode[] {
    return this.productUnits
      .filter((productUnit) => productUnit.is_barcode_updated)
      .map((productUnit) => productUnit.barcode)
      .filter(Boolean) as Barcode[];
  }

  public toggleProductUnitInventory(
    productUnit: ProductUnitSettings,
    value: boolean,
  ): void {
    const index = this.productUnits.indexOf(productUnit);
    if (index > -1) {
      this.productUnits[index].in_inventory = value;
    }
  }

  public getNewInventoryUnitIds(): number[] {
    const newInventoryUnitIds: number[] = [];
    this.productUnits.forEach((productUnit) => {
      if (
        !productUnit.is_new_unit &&
        productUnit.in_inventory &&
        !this.inventoryUnitIds.has(productUnit.id)
      ) {
        newInventoryUnitIds.push(productUnit.id);
      }
    });
    return newInventoryUnitIds;
  }

  public getRemovedInventoryUnitIds(): number[] {
    const removedInventoryUnitIds: number[] = [];
    this.productUnits.forEach((productUnit) => {
      if (
        !productUnit.is_new_unit &&
        !productUnit.in_inventory &&
        this.inventoryUnitIds.has(productUnit.id)
      ) {
        removedInventoryUnitIds.push(productUnit.id);
      }
    });
    return removedInventoryUnitIds;
  }
}
